import React, { useState } from "react";
import './Item.css'; // Stil für die Box
import { useLanguage } from '../LanguageContext';
import Attributes from './Attributes'; // Importieren Sie die Box-Komponente
import AttributesV from './AttributesV'; // Importieren Sie die Box-Komponente
import Properties from './Properties'; // Importieren Sie die Box-Komponente
import Corps from './Corps'; // Importieren Sie die Box-Komponente
import CorpsV from './CorpsV'; // Importieren Sie die Box-Komponente
import EntityCommonV from './EntityCommonV'; // Importieren Sie die Box-Komponente
import Range from './Range'; // Importieren Sie die Box-Komponente

function Item({ entity, browserLanguage }) {
  // console.log("language: "+browserLanguage);
  // console.log("loading item",entity)
  const { translate } = useLanguage();

  // Deklaration der Variablen außerhalb der JSX-Klammern
  let entityName = "Unbekannt";

  if (entity) {
    // Berechnung von entityName, wenn entity vorhanden ist
    entityName = entity.texts.find((text) => text.type === "NAME" && text.language === browserLanguage)?.text || entityName;
  }

  return (
      <div className="item">
        {entity && (
              <div className='item-body'>
                <div className='item-title'>{entityName}</div>
                <Attributes attributes={entity.attributes} browserLanguage={browserLanguage} styleToSet=""/>
                <Properties properties={entity.properties} browserLanguage={browserLanguage} styleToSet=""/>
                {entity.range && (
                    <Range range={entity.range} browserLanguage={browserLanguage}/>
                )}
              </div>
        )}
      </div>
    );
}

export default Item;
