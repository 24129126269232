import React, { useState } from "react";
import '../App.css'; // Stil für die Box
import './Mission.css'; // Stil für die Box
import { useLanguage } from '../LanguageContext';
import Textfield from './Textfield'; // Importieren Sie die Box-Komponente
import Image from './Image';

function Mission({ mission, browserLanguage, style, showFluff }) {
  console.log("language: "+browserLanguage);
  const { translate } = useLanguage();

  // Deklaration der Variablen außerhalb der JSX-Klammern
  let missionName = "Unbekannt";
  let styleToSet = "";

  if (mission) {
    // Berechnung von missionName, wenn mission vorhanden ist
    missionName = mission.texts.find((text) => text.type === "NAME" && text.language === browserLanguage)?.text  || missionName;
    // Weitere Stilanpassungen können hier vorgenommen werden
    styleToSet = style ? style : "mission-style"; // Beispiel für einen Stil
  }

  return (
      <div>
        {mission && (
          <div className={style}>
            <div>
              <div className='mission-title'>
                {missionName}
              </div>
              { showFluff && (
                  <div className="fluff-container">
                      <Image
                          classNameIn={"fluff-image"}
                          type={"MISSION"}
                          id={mission.uuid}
                          size={400}
                          fallbackBildUrl={"/transparent.png"}
                          title={""}/>
                      <Textfield objectData={mission} type="INTRO" classNameToSet="details-fluff"/>
                  </div>
              )}
              <div className='mission-description'>
              <p>{mission.texts.find((text) => text.type === "DESCRIPTION" && text.language === browserLanguage)?.text}</p>
              <h4>{translate('mission.objective.title')}</h4>
              <ul>
                {mission.missionsObjectives.map((objective, index) => (
                  <li key={index}>
                    <h5>{objective.texts.find((text) => text.type === "NAME" && text.language === browserLanguage)?.text} ({objective.victoryPoints})</h5>
                    <div>{objective.texts.find((text) => text.type === "RULES" && text.language === browserLanguage)?.text}</div>
                     { showFluff && (
                        <div className='fluff'>{objective.texts.find((text) => text.type === "DESCRIPTION" && text.language === browserLanguage)?.text}</div>
                     )}
                  </li>
                ))}
              </ul>
              <h4>{translate('mission.objective.title.optional')}</h4>
              <ul>
                {mission.optionalMissionsObjectives.map((objective, index) => (
                  <li key={index}>
                    <h5>{objective.texts.find((text) => text.type === "NAME" && text.language === browserLanguage)?.text} ({objective.victoryPoints})</h5>
                    <div>{objective.texts.find((text) => text.type === "RULES" && text.language === browserLanguage)?.text}</div>
                     { showFluff && (
                        <div className='fluff'>{objective.texts.find((text) => text.type === "DESCRIPTION" && text.language === browserLanguage)?.text}</div>
                     )}
                  </li>
                ))}
              </ul>
          </div>
            </div>
          </div>
        )}
      </div>
    );
}

export default Mission;
