import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import './Fraction.css'; // Stil für die Box
import { useLanguage } from '../LanguageContext';
import configModule from '../config';
import Attributes from './Attributes'; // Importieren Sie die Box-Komponente
import AttributesV from './AttributesV'; // Importieren Sie die Box-Komponente
import Properties from './Properties'; // Importieren Sie die Box-Komponente
import Corps from './Corps'; // Importieren Sie die Box-Komponente
import CorpsV from './CorpsV'; // Importieren Sie die Box-Komponente
import EntityCommonV from './EntityCommonV'; // Importieren Sie die Box-Komponente
import EntityCommon from './EntityCommon'; // Importieren Sie die Box-Komponente
import { Link } from 'react-router-dom'; // Importiere die Link-Komponente von react-router-dom
import Image from './Image';
import Wait from './Wait'; // Importieren Sie die Box-Komponente
import Range from './Range'; // Importieren Sie die Box-Komponente
import UnitFlat from './UnitFlat'; // Importieren Sie die Box-Komponente
import Actionbar from './Actionbar'; // Importieren Sie die Box-Komponente
import Filterbar from './Filterbar'; // Importieren Sie die Box-Komponente
import UnitList from './UnitList'; // Importieren Sie die Box-Komponente

function Fraction({ entity, browserLanguage, style }) {
  const navigate = useNavigate();
  const { translate } = useLanguage();
  const [isLoading, setIsLoading] = useState(true);
  const [ units, setUnits ] = useState(null);
  const [ count, setCount ] = useState("0");
  const url = configModule.url()+"/UNIT/fraction/"+entity.uuid+""
  const [displayedUnits, setDisplayedUnits] = useState([]);

  const [alignmentFilter, setAlignmentFilter] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [showNPCs, setShowNPCs] = useState('');
  const [selectedFaction, setSelectedFaction] = useState('');
  const [filter, setFilter] = useState({ alignment: alignmentFilter, type: selectedType });

  // Deklaration der Variablen außerhalb der JSX-Klammern
  let entityName = "Unbekannt";
  let styleToSet = "";

  if (entity) {
    // Berechnung von entityName, wenn entity vorhanden ist
    entityName = entity.texts.find((text) => text.type === "NAME" && text.language === browserLanguage)?.text || entityName;
    // Weitere Stilanpassungen können hier vorgenommen werden
    styleToSet = style ? style : "entity-style"; // Beispiel für einen Stil
  }

  // infos für web calls
  const headers = new Headers({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'lang': browserLanguage,
      'Authorization': configModule.getAuthHeader(),
  });

  useEffect(() => {
     // Hier sollte der REST-Call stattfinden und die Daten in dataList gespeichert werden
      const countUnits = async () => {
           try {
               setIsLoading(true);

               const response = await fetch(url+"/count", { headers });
               // console.log(response)
               if (!response.ok) {
                 if (response.status===401) {
                       configModule.clearUserAuth()
                       window.location.href = process.env.PUBLIC_URL+"/overview"
                       console.error("Authentication not ok or lost.")
                 }
                 throw new Error('Fehler beim Laden der Daten: '+response.status);
               }
               const countIn = await response.text();
               setCount(countIn); // Daten im Zustand speichern
               setIsLoading(false);
             } catch (error) {
               console.error('Error fetching data:', error);
               setIsLoading(false);
             }
           };

           countUnits();
  }, [entity]);

  useEffect(() => {
      const filteredUnits = units && units.filter(unit => {
        // Filtere basierend auf dem Alignment-Filter
        const alignmentMatch = alignmentFilter === 'good' ? unit.modelCommon.alignment >= 0 :
                               alignmentFilter === 'evil' ? unit.modelCommon.alignment <= 0 : true;

        // Überprüfe, ob die Einheit als NPC markiert ist
        var npcMatch = true
        if (unit.npc === true)
        {
          if (!showNPCs) npcMatch = false
        }

        var typeMatch = true
        if (selectedType != "ALL") typeMatch = (unit.type === selectedType ? true : false)

        return alignmentMatch && npcMatch && typeMatch;
      });
      setDisplayedUnits(filteredUnits);
  }, [alignmentFilter, units, showNPCs, selectedType ]);

     const handleDelete = () => {
             let url = `${configModule.url()}/${entity.entityCommon.type}/${entity.uuid}`;

             const exists = async () => {
                   const response = await fetch(url,
                      {
                        method: 'HEAD', // Verwende POST oder PUT, je nach API-Anforderungen
                        headers: headers
                      }
                   );
                  if (!response.ok) {
                   if (response.status === 404) {
                      return true
                   }
                   else if (response.status === 401) {
                           configModule.clearUserAuth();
                           window.location.href = process.env.PUBLIC_URL + "/overview";
                           console.error("Authentication not ok or lost.");
                   }
                   throw new Error('Fehler beim Laden der Daten: ' + response.status);
                  }
                  else{
                      return true;
                  }
             };

             Promise.all([exists()]).then(([exists]) => {
                  if (exists)
                  {
                      console.log("entity existing, deleting: ",entity.uuid);

                      const deleteEntity = async () => {
                           const response = await fetch(url,
                               {
                                 method: 'DELETE', // Verwende POST oder PUT, je nach API-Anforderungen
                                 headers: headers
                               }
                            );
                           if (!response.ok) {
                            if (response.status === 404) {
                               return true
                            }
                            else if (response.status === 401) {
                                    configModule.clearUserAuth();
                                    window.location.href = process.env.PUBLIC_URL + "/overview";
                                    console.error("Authentication not ok or lost.");
                            }
                            throw new Error('Fehler beim Laden der Daten: ' + response.status);
                           }
                           else return true
                      };

                      Promise.all([deleteEntity()]).then(([deleteEntity]) => {
                         console.log("entity deleted: ",entity.uuid);
                         navigate("/fu");
                    })
                    .catch(error => {
                         console.error('Fehler beim Löschen:', error);
                    });

                  }
             })
             .catch(error => {
                  console.error('Fehler beim Löschen:', error);
             });
      }

  const handleEdit = () => {
        if (entity.guild)
            navigate(`/editor/edit/GUILD/${entity.uuid}`);
        else navigate(`/editor/edit/FRACTION/${entity.uuid}`);
  }

  return (
      <div>
        {entity && (
          <div className={style}>
              <div className='entity-title'>
                {entityName}
              </div>
              <div className='fluff'>
                <p>{entity.texts.find((text) => text.type === "FLUFF" && text.language === browserLanguage)?.text}</p>
              </div>
              <div className='entity-body'>
                <AttributesV attributes={entity.attributes} browserLanguage={browserLanguage} styleToSet=""/>
                 <div className='entity-body-sub'>
                    <Image
                         classNameIn={"miniature-image"}
                         type={"FRACTION"}
                         id={entity.uuid}
                         size={400}
                         title={""}/>
                    <Corps corps={entity.corps} browserLanguage={browserLanguage} styleToSet=""/>
                </div>
                 <EntityCommonV
                    modelCommon={entity.modelCommon}
                    browserLanguage={browserLanguage}
                    points={entity.points}
                    hp={entity.attributes.hitpoints}styleToSet=""/>
              </div>
              <div className='entity-body'>
                <Properties properties={entity.properties} browserLanguage={browserLanguage} styleToSet=""/>
              </div>

              <UnitList unitCount={count} fractionUUID={entity.uuid} browserLanguage={browserLanguage}/>
              {count>0 ? (
                <Actionbar style="savebar" handleEdit={handleEdit} visibility={entity.metadata.visibility} owner={entity.metadata.owner}/>):
                (
                <Actionbar style="savebar" handleDelete={handleDelete} handleEdit={handleEdit} visibility={entity.metadata.visibility} owner={entity.metadata.owner}/>
                )
              }
          </div>
        )}
      </div>
    );
}

export default Fraction;
