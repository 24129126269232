// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ec-image {
 z-index:-1;
  width:50px;
  height:50px;
}

.ec-value {
   z-index:0;
   width: 50px;
   vertical-align:middle;
   font-size: 25px;
   position: relative;
   top: -50px;
   left: 50px;
   text-align: center;
   height:0px;
}

.ec-edit {
  background-color: black;
  height: 50px;
  width:50px;
  z-index:0;
  vertical-align:middle;
  font-size: 25px;
  position: relative;
  top: -17px;
  text-align: center;
}

.ec-table {
  width:100%;
  text-align: left;
}

.attribute-color-hp {
  color:#E8AEAF;
}

.attribute-color-size {
  color:#FEFFBE;
}

.attribute-color-gold {
  color:#FEFFBE;
}

.attribute-color-items {
  color:#FEFFBE;
}

.attribute-color-alignment {
  color:#E8AEAF;
}`, "",{"version":3,"sources":["webpack://./src/components/EntityCommon.css"],"names":[],"mappings":"AAAA;CACC,UAAU;EACT,UAAU;EACV,WAAW;AACb;;AAEA;GACG,SAAS;GACT,WAAW;GACX,qBAAqB;GACrB,eAAe;GACf,kBAAkB;GAClB,UAAU;GACV,UAAU;GACV,kBAAkB;GAClB,UAAU;AACb;;AAEA;EACE,uBAAuB;EACvB,YAAY;EACZ,UAAU;EACV,SAAS;EACT,qBAAqB;EACrB,eAAe;EACf,kBAAkB;EAClB,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf","sourcesContent":[".ec-image {\n z-index:-1;\n  width:50px;\n  height:50px;\n}\n\n.ec-value {\n   z-index:0;\n   width: 50px;\n   vertical-align:middle;\n   font-size: 25px;\n   position: relative;\n   top: -50px;\n   left: 50px;\n   text-align: center;\n   height:0px;\n}\n\n.ec-edit {\n  background-color: black;\n  height: 50px;\n  width:50px;\n  z-index:0;\n  vertical-align:middle;\n  font-size: 25px;\n  position: relative;\n  top: -17px;\n  text-align: center;\n}\n\n.ec-table {\n  width:100%;\n  text-align: left;\n}\n\n.attribute-color-hp {\n  color:#E8AEAF;\n}\n\n.attribute-color-size {\n  color:#FEFFBE;\n}\n\n.attribute-color-gold {\n  color:#FEFFBE;\n}\n\n.attribute-color-items {\n  color:#FEFFBE;\n}\n\n.attribute-color-alignment {\n  color:#E8AEAF;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
