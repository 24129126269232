// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select-fraction-container {
  display: flex;
  width:100%;
  max-width: 45em;
  align-items: center;
  justify-content: center;
  flex-flow: column wrap;
}

.select-fraction-title {
  font-size: 30px; /* Ändern Sie die Schriftgröße nach Bedarf */
  text-align:center;
  padding:10px;
}

.select-fraction-search {
  font-size: 30px; /* Ändern Sie die Schriftgröße nach Bedarf */
  text-align:center;
  padding:10px;
  margin-bottom:20px;
}

.select-fraction-item {
  font-size: 24px; /* Ändern Sie die Schriftgröße nach Bedarf */
  text-align:center;
  padding:10px;
}

.select-fraction-label {
  font-size: 18px; /* Ändern Sie die Schriftgröße nach Bedarf */
  line-height: 1.2; /* Ändern Sie den Zeilenabstand nach Bedarf */
  text-align:left;
  padding:20px;
  font-style: italic;
}`, "",{"version":3,"sources":["webpack://./src/components/SelectFraction.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,UAAU;EACV,eAAe;EACf,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;AACxB;;AAEA;EACE,eAAe,EAAE,4CAA4C;EAC7D,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,eAAe,EAAE,4CAA4C;EAC7D,iBAAiB;EACjB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,eAAe,EAAE,4CAA4C;EAC7D,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,eAAe,EAAE,4CAA4C;EAC7D,gBAAgB,EAAE,6CAA6C;EAC/D,eAAe;EACf,YAAY;EACZ,kBAAkB;AACpB","sourcesContent":[".select-fraction-container {\n  display: flex;\n  width:100%;\n  max-width: 45em;\n  align-items: center;\n  justify-content: center;\n  flex-flow: column wrap;\n}\n\n.select-fraction-title {\n  font-size: 30px; /* Ändern Sie die Schriftgröße nach Bedarf */\n  text-align:center;\n  padding:10px;\n}\n\n.select-fraction-search {\n  font-size: 30px; /* Ändern Sie die Schriftgröße nach Bedarf */\n  text-align:center;\n  padding:10px;\n  margin-bottom:20px;\n}\n\n.select-fraction-item {\n  font-size: 24px; /* Ändern Sie die Schriftgröße nach Bedarf */\n  text-align:center;\n  padding:10px;\n}\n\n.select-fraction-label {\n  font-size: 18px; /* Ändern Sie die Schriftgröße nach Bedarf */\n  line-height: 1.2; /* Ändern Sie den Zeilenabstand nach Bedarf */\n  text-align:left;\n  padding:20px;\n  font-style: italic;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
