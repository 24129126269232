import React, { useState, useEffect } from "react";
import './Unit.css'; // Stil für die Box
import { useLanguage } from '../LanguageContext';
import config from '../config'; // Passe den Pfad zur Konfigurationsdatei an



function UnitHeader({ withCnt, browserLanguage }) {

  const { translate } = useLanguage();

  // Bedingung auswerten und JSX-Element erstellen
    const headerThWithCnt = withCnt ? (
      <th className='units-th units-name units-header'>{translate('units.cnt')}</th>
    ) : null;

  return (
     <thead>
       <tr className='units-header'>
          {headerThWithCnt}
          <th className='units-th units-name units-header'>{translate('fu.fraction.units')}</th>
          <th className='units-header'><img className="attribute-image-th" src={`${process.env.PUBLIC_URL}/icons/attributes/movement.png`} alt="Attribute movement" /></th>
          <th className='units-header'><img className="attribute-image-th" src={`${process.env.PUBLIC_URL}/icons/attributes/armor.png`} alt="Attribute armor" /></th>
          <th className='units-header'><img className="attribute-image-th" src={`${process.env.PUBLIC_URL}/icons/attributes/fighting.png`} alt="Attribute fighting" /></th>
          <th className='units-header'><img className="attribute-image-th" src={`${process.env.PUBLIC_URL}/icons/attributes/shooting.png`} alt="Attribute shooting" /></th>
          <th className='units-header'><img className="attribute-image-th" src={`${process.env.PUBLIC_URL}/icons/attributes/magic.png`} alt="Attribute magic" /></th>
          <th className='units-header'><img className="attribute-image-th" src={`${process.env.PUBLIC_URL}/icons/attributes/moral.png`} alt="Attribute moral" /></th>
          <th className='units-header'><img className="attribute-image-th" src={`${process.env.PUBLIC_URL}/icons/attributes/HP.png`} alt="Attribute hitpoints" /></th>
          <th className='units-header'><img className="attribute-image-th" src={`${process.env.PUBLIC_URL}/icons/attributes/gold_cost.png`} alt="Attribute points" /></th>
       </tr>
     </thead>
    );
}

export default UnitHeader;
