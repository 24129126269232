import './App.css';
import React, { useState, useEffect } from "react";
import { useLanguage } from './LanguageContext';
import Box from './components/Box'; // Importieren Sie die Box-Komponente
import Login from './components/Login'; // Importieren Sie die Box-Komponente
import configModule from './config';
import AdminLoginModal from './components/AdminLoginModal'; // Import der modalen Komponente


// Overview.js
function Overview() {
  const { translate } = useLanguage();
  const [isModalVisible, setModalVisible] = useState(false);

  function showAdminLogin() {
      // Logik, um das Admin-Login-Modal oder die Seite zu zeigen
      console.log("ADMIN")
      setModalVisible(true); // Modal sichtbar machen
  }

  function closeAdminLogin() {
        setModalVisible(false); // Modal schließen
  }

   useEffect(() => {
     const handleKeyUp = (event) => {
             if (event.ctrlKey && event.altKey && event.key === 'A') {
                 showAdminLogin();
         }
     };

     // Event Listener hinzufügen
     document.addEventListener('keyup', handleKeyUp);

     // Cleanup-Funktion, um den Event Listener zu entfernen
     return () => {
         document.removeEventListener('keyup', handleKeyUp);
     };
   }, []); // Leeres Array sorgt dafür, dass der Effekt nur einmal ausgeführt wird

  return (
    <header className="Overview-Body">
        <div className="box-container">

           {!configModule.config.loggedIn && (
               <Box icon={<img src={`${process.env.PUBLIC_URL}/icons/action_icons/login.png`} alt="Login Icon" />} title="Login" content={<Login />} />
           )}

           {configModule.config.loggedIn && (
               <Box icon={<img src={`${process.env.PUBLIC_URL}/icons/action_icons/character.png`} alt="Character Icon" />}
                    title={translate('story.title')}
                    content={translate('story.description')}
                    linkto="/story"/>
           )}

           <Box icon={<img src={`${process.env.PUBLIC_URL}/icons/action_icons/gamelist.png`} alt="Game list Icon" />}
               title={translate('gamelist.title')}
               content={translate('gamelist.description')}
               linkto="/listeditor"/>

           <Box icon={<img src={`${process.env.PUBLIC_URL}/icons/action_icons/rules.png`} alt="Rules Icon" />}
                title={translate('rules.title')} content={translate('rules.description')} linkto="/rules"/>

           <Box icon={<img src={`${process.env.PUBLIC_URL}/icons/action_icons/scenario.png`} alt="Scenario Icon" />}
                title={translate('scenario.title')} content={translate('scenario.description')} linkto="/csm"/>

           <Box icon={<img src={`${process.env.PUBLIC_URL}/icons/action_icons/units.png`} alt="Units Icon" />}
                title={translate('fu.title')} content={translate('fu.description')} linkto="/fu" />
        </div>

        {/* Modale Komponente einbinden */}
        <AdminLoginModal isVisible={isModalVisible} onClose={closeAdminLogin} />
    </header>
  );
}

export default Overview;