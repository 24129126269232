import React, { useRef, useState, useEffect } from 'react';
import './ImageUploader.css'; // Stil für die Box
import { useLanguage } from '../LanguageContext';
import Image from './Image'; // Stelle sicher, dass dieser Import korrekt ist

function ImageUploader({ handleEditImage, type, id, size }) {
  const { translate } = useLanguage();
  const [imagePreviewUrl, setImagePreviewUrl] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleImageChange = (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      setImagePreviewUrl(reader.result);
      setImageFile(file); // Setzt das File im State
    };

    reader.readAsDataURL(file);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    if (imageFile) {
      handleEditImage(imageFile); // Ruft handleEditImage nur auf, wenn imageFile nicht null ist
    }
  }, [imageFile, handleEditImage]); // Füge handleEditImage zu den Abhängigkeiten hinzu, um sicherzustellen, dass die Funktion aktualisiert wird

  return (
    <div className="uploader-body">
      {imagePreviewUrl ? (
        <img className="miniature-image" src={imagePreviewUrl} alt="Bildvorschau" />
      ) : (
        <Image
          classNameIn={"miniature-image"}
          type={type}
          id={id}
          size={size}
          title={""}
        />
      )}
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleImageChange}
        style={{ display: 'none' }} // Versteckt das tatsächliche Input-Element
      />
      <button className="endSelectFile" type="button" onClick={handleButtonClick}>
        {translate('imageuploader.select.image')}
      </button>
    </div>
  );
}

export default ImageUploader;
