import React, { useState } from "react";
import './EntityCommon.css'; // Stil für die Box
import { useLanguage } from '../LanguageContext';
import { Link } from 'react-router-dom'; // Importiere die Link-Komponente von react-router-dom

function EntityCommonV({ modelCommon, points, hp, browserLanguage, styleToSet }) {
  const { translate } = useLanguage();

  return (
      <div className={styleToSet}>
        {modelCommon && (
          <table className='ec-table'>
            <tbody>
                <tr><td>
                    <Link to={`/help`}><img className="ec-image" src={`${process.env.PUBLIC_URL}/icons/generic_icons/info.png`} alt="info" /></Link>
                </td></tr>
                <tr><td>
                    <img className="ec-image" src={`${process.env.PUBLIC_URL}/icons/attributes/size.png`} alt="size" />
                    <div className="ec-value attribute-color-size">{modelCommon.size}</div>
                </td></tr>
                <tr><td>
                    <img className="ec-image" src={`${process.env.PUBLIC_URL}/icons/attributes/gold_cost.png`} alt="gold cost" />
                    <div className="ec-value attribute-color-gold">{points}</div>
                </td></tr>
                <tr><td>
                    <img className="ec-image" src={`${process.env.PUBLIC_URL}/icons/attributes/HP.png`} alt="hit points" />
                    <div className="ec-value attribute-color-hp">{hp}</div>
                </td></tr>
                <tr>
                    <td><img className="ec-image" src={`${process.env.PUBLIC_URL}/icons/attributes/alignment.png`} alt="alignment" />
                    <div className="ec-value attribute-color-alignment">{modelCommon.alignment}</div>
                </td></tr>
                <tr><td>
                    <img className="ec-image" src={`${process.env.PUBLIC_URL}/icons/action_icons/items.png`} alt="max. equipment" />
                    <div className="ec-value attribute-color-items">{modelCommon.maxEquipment}</div>
                </td></tr>
             </tbody>
          </table>
        )}
        {!modelCommon && (
            <div> ??? </div>
        )}
      </div>
    );
}

export default EntityCommonV;
