// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `li {
  max-width: 100%; /* Bild passt zur Breite der Box */
  margin: 5px;
  text-align: left;
}

.event-title {
  font-size: 16px; /* Ändern Sie die Schriftgröße nach Bedarf */
  font-style:bold;
  padding:5px;
  text-align:left;
}


.event-type {
  font-size: 12px; /* Ändern Sie die Schriftgröße nach Bedarf */
  padding:5px;
  text-align:left;
}

.event-trigger {
  font-size: 12px; /* Ändern Sie die Schriftgröße nach Bedarf */
  padding:5px;
  text-align:left;
}

.event-value {
  font-size: 12px; /* Ändern Sie die Schriftgröße nach Bedarf */
  padding:5px;
  text-align:center;
}

.event-description {
  font-size: 14px; /* Ändern Sie die Schriftgröße nach Bedarf */
  padding:5px;
  text-align:left;
}

.event-rule {
  font-size: 14px; /* Ändern Sie die Schriftgröße nach Bedarf */
  padding:5px;
  text-align:left;
}

.event {
}
`, "",{"version":3,"sources":["webpack://./src/components/EventList.css"],"names":[],"mappings":"AAAA;EACE,eAAe,EAAE,kCAAkC;EACnD,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,eAAe,EAAE,4CAA4C;EAC7D,eAAe;EACf,WAAW;EACX,eAAe;AACjB;;;AAGA;EACE,eAAe,EAAE,4CAA4C;EAC7D,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,eAAe,EAAE,4CAA4C;EAC7D,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,eAAe,EAAE,4CAA4C;EAC7D,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,eAAe,EAAE,4CAA4C;EAC7D,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,eAAe,EAAE,4CAA4C;EAC7D,WAAW;EACX,eAAe;AACjB;;AAEA;AACA","sourcesContent":["li {\n  max-width: 100%; /* Bild passt zur Breite der Box */\n  margin: 5px;\n  text-align: left;\n}\n\n.event-title {\n  font-size: 16px; /* Ändern Sie die Schriftgröße nach Bedarf */\n  font-style:bold;\n  padding:5px;\n  text-align:left;\n}\n\n\n.event-type {\n  font-size: 12px; /* Ändern Sie die Schriftgröße nach Bedarf */\n  padding:5px;\n  text-align:left;\n}\n\n.event-trigger {\n  font-size: 12px; /* Ändern Sie die Schriftgröße nach Bedarf */\n  padding:5px;\n  text-align:left;\n}\n\n.event-value {\n  font-size: 12px; /* Ändern Sie die Schriftgröße nach Bedarf */\n  padding:5px;\n  text-align:center;\n}\n\n.event-description {\n  font-size: 14px; /* Ändern Sie die Schriftgröße nach Bedarf */\n  padding:5px;\n  text-align:left;\n}\n\n.event-rule {\n  font-size: 14px; /* Ändern Sie die Schriftgröße nach Bedarf */\n  padding:5px;\n  text-align:left;\n}\n\n.event {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
