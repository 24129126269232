import React, { useState, useEffect } from "react";
import './Unit.css'; // Stil für die Box
import { useLanguage } from '../LanguageContext';
import { Link } from 'react-router-dom'; // Importiere die Link-Komponente von react-router-dom
import { UnitHelper } from './UnitHelper'; // Importiere die Link-Komponente von react-router-dom

function CharacterFlat({ unit, browserLanguage }) {
  //console.log("language: " + browserLanguage);
  const [selectedSetIndex, setSelectedSetIndex] = useState(0);
  const [selectedSet, setSelectedSet] = useState(0);
  const { translate } = useLanguage();

  let fractionName = "Unbekannt";
  let entityName = "Unbekannt";
  let entityType = "";
  let styleToSet = "";
  let attributes = null;
  let properties = null;
  let unitProperties = null;
  let unitItems = null;
  let propertyNames = null;
  let colSpan = 1;

   if (
     unit
   ) {
     //console.log(unit);
     properties = UnitHelper.extractUniqueProperties(unit);

     // Hier die Berechnung der attributes verschieben
     attributes = UnitHelper.sumAttributes(
       null, // Verwenden Sie selectedSetIndex, um das ausgewählte Set zu erhalten
       unit.armor,
       unit.attributes,
       properties
     );

     //console.log(attributes);
     fractionName =
       unit.fraction.texts.find(
         (text) => text.type === "NAME" && text.language === browserLanguage
       )?.text || fractionName;
     entityName =
       unit.texts.find(
         (text) => text.type === "NAME" && text.language === browserLanguage
       )?.text || entityName;
     entityType = UnitHelper.mapType(unit.type, translate);

     unitItems = UnitHelper.mergeItems({
       armor: unit.armor,
       items: unit.items,
       browserLanguage: browserLanguage,
     });
     unitProperties = UnitHelper.mergeProperties(properties, browserLanguage);
   }
  else return;

  return (
        <tbody>
          <tr className='units-td'>
            <td className='units-name'>
              <Link to={`/character/${unit.uuid}`}>
                {entityName}
              </Link>
            </td>
           <td className='units-td-attributes'>{attributes?.movement || '0'}</td>
           <td className='units-td-attributes'>{attributes?.armor || '0'}</td>
           <td className='units-td-attributes'>{attributes?.fighting || '0'}</td>
           <td className='units-td-attributes'>{attributes?.shooting || '0'}</td>
           <td className='units-td-attributes'>{attributes?.magic || '0'}</td>
           <td className='units-td-attributes'>{attributes?.moral || '0'}</td>
           <td className='units-td-attributes'>{attributes?.hitpoints || '0'}</td>
           <td className='units-td-attributes'>{unit.points}</td>
          </tr>
          <tr className='units-td'>
            <td className='units-items' colSpan="10">{unitItems}</td>
          </tr>
          <tr className='units-td'>
            <td className='units-properties' colSpan="10">{unitProperties}</td>
          </tr>
          {unit.sets &&
            unit.sets.length > 0 &&
            unit.sets.map((set) => {
              const attributesSet = UnitHelper.sumAttributes(set, null, null, null);
              const propertiesSet = UnitHelper.extractUniqueProperties(set);
              const propertyNamesSet = UnitHelper.mergeProperties(
                propertiesSet,
                browserLanguage
              );

              const weapons = UnitHelper.listWeapons(set.items, browserLanguage);

              return (
                <React.Fragment key={set.uuid}>
                  <tr className='units-td'>
                    <td className='units-sets'>{set.texts.find((text) => text.type === "NAME" && text.language === browserLanguage)?.text}</td>
                    <td className='units-td-attributes'>{attributesSet.movement > 0 ? attributesSet.movement : ""}</td>
                    <td className='units-td-attributes'>{attributesSet.armor > 0 ? attributesSet.armor : ""}</td>
                    <td className='units-td-attributes'>{attributesSet.fighting > 0 ? attributesSet.fighting : ""}</td>
                    <td className='units-td-attributes'>{attributesSet.shooting > 0 ? attributesSet.shooting : ""}</td>
                    <td className='units-td-attributes'>{attributesSet.magic > 0 ? attributesSet.magic : ""}</td>
                    <td className='units-td-attributes'>{attributesSet.moral > 0 ? attributesSet.moral : ""}</td>
                    <td className='units-td-attributes'>{attributesSet.hitpoints > 0 ? attributesSet.hitpoints : ""}</td>
                    <td className='units-td-attributes'></td>
                  </tr>
                  <tr className='units-td'>
                    <td className='units-weapons' colSpan="10">{weapons}</td>
                  </tr>
                  <tr className='units-td'>
                    <td className='units-items-properties' colSpan="10">{propertyNamesSet}</td>
                  </tr>
                </React.Fragment>
              );
            })}
        </tbody>
  );
}

export default CharacterFlat;
