import './App.css';
import React, { useState } from "react";
import { useLanguage } from './LanguageContext';
import BoxList from './components/BoxList'; // Importieren Sie die Box-Komponente
import { useNavigate, Link } from 'react-router-dom'; // Importiere die Link-Komponente von react-router-dom

// Overview.js
function FU() {
  const { translate } = useLanguage();
  const navigate = useNavigate();

  const handleAddUnit = (event) => {
     navigate("/editor/add/UNIT/base/FRACTION", {
      state: { returnUrl: "/fu" }
     });
  };

  const handleAddFraction = (event) => {
       navigate("/editor/add/FRACTION/base/FRACTION", {
         state: { returnUrl: "/fu" }
       });
  };

   const handleAddGuild = (event) => {
         navigate("/editor/add/GUILD/base/FRACTION", {
           state: { returnUrl: "/fu" }
         });
    };

  return (
    <header className="Overview-Body">
       <div className="box-container">
           <BoxList
               icon={<img src={`${process.env.PUBLIC_URL}/icons/action_icons/fractions.png`} alt="Fraction Image" />}
               title={translate('fu.fraction.title') }
               typeToLoad="FRACTIONONLY"
               linkType="FRACTION"
               imageType="FRACTION"
               />
           <BoxList
                  icon={<img src={`${process.env.PUBLIC_URL}/icons/action_icons/fractions.png`} alt="Subfraction Image" />}
                  title={translate('fu.subfraction.title') }
                  typeToLoad="SUBFRACTION"
                  linkType="FRACTION"
                  imageType="FRACTION"
              />
           <BoxList
               icon={<img src={`${process.env.PUBLIC_URL}/icons/action_icons/fractions.png`} alt="Guild Image" />}
               title={translate('fu.guild.title') }
               typeToLoad="GUILDS"
               linkType="FRACTION"
               imageType="FRACTION"
               handleAdd={handleAddGuild}
           />
           <BoxList
               icon={<img src={`${process.env.PUBLIC_URL}/icons/action_icons/units.png`} alt="Unit Image" />}
               title={translate('fu.unit.title') }
               typeToLoad="UNIT"
               imageType="UNIT"
               linkType="UNIT"
               handleAdd={handleAddUnit}
               />
         </div>
    </header>
  );
}

export default FU;