// ErrorPage.js
import React, { useState, useEffect } from 'react';
import './App.css';
import { useLanguage } from './LanguageContext';
import useInterval from './useInterval';
import configModule from './config'; // Passe den Pfad zur Konfigurationsdatei an

function ErrorPage() {
  const { translate } = useLanguage();
  const appVersion = require('../package.json').version;
  const url = configModule.baseUrl()+"/base/info"
  const [connectionStatus, setConnectionStatus] = useState('loading');

    const checkConnection = () => {
      fetch(url)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Backend is unreachable.');
          }
          setConnectionStatus('success');
        })
        .catch(() => {
          setConnectionStatus('error');
        });
    };

    // Überprüfe die Verbindung alle 5 Sekunden (5000 ms)
    useInterval(checkConnection, 5000);

    useEffect(() => {
    // Wenn die Verbindung erfolgreich wiederhergestellt ist, lade die App.js neu
        if (connectionStatus === 'success') {
        window.location.reload();
        }
    }, [connectionStatus]);

  return (
    <header className="Welcome-Body">
        <img
           src={`${process.env.PUBLIC_URL}/end_logo_trans_medium.png`}
           className="App-logo"
           alt="logo"
        />
        <div>
          <h1>{translate('error.noconnection')}</h1>
          <p>{translate('error.noconnection.retry')}</p>
          <p>{ appVersion }</p>
        </div>
    </header>
  );
}

export default ErrorPage;