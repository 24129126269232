// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filterbar {
   max-width: 700px;
   display: flex;
   position: relative;
   justify-content: center;
   align-items: center;
   flex-flow: row wrap;
   color: black;
   background-color: #4890a6;
   border: none;
   text-align: center;
   text-decoration: none;
   display: inline-block;
   font-size: 20px;
   margin:10px;
   cursor: pointer;
   border-radius: 4px; /* Abgerundete Ecken */
   transition-duration: 0.4s; /* Animation für den Hover-Effekt */
   font-size: 16px; /* Ändern Sie die Schriftgröße nach Bedarf */
}

.filterbar-title {
    font-size: 16px; /* Ändern Sie die Schriftgröße nach Bedarf */
    line-height: 1.2; /* Ändern Sie den Zeilenabstand nach Bedarf */
    text-decoration: underline;
    text-align:center;
    padding:0px;
    margin:5px;
}

.filterbar-body {
   display: flex;
   justify-content: center;
   align-items: center;
   flex-flow: row wrap;
}

.filterbar-alignment {
    padding:5px;
    margin:5px;
}

.filterbar-checkbox {
    padding:5px;
    margin:5px;
}

.filterbar-type {
    padding:5px;
    margin:5px;
}

.filterbar-button {
    width:40px;
    height:40px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Filterbar.css"],"names":[],"mappings":"AAAA;GACG,gBAAgB;GAChB,aAAa;GACb,kBAAkB;GAClB,uBAAuB;GACvB,mBAAmB;GACnB,mBAAmB;GACnB,YAAY;GACZ,yBAAyB;GACzB,YAAY;GACZ,kBAAkB;GAClB,qBAAqB;GACrB,qBAAqB;GACrB,eAAe;GACf,WAAW;GACX,eAAe;GACf,kBAAkB,EAAE,sBAAsB;GAC1C,yBAAyB,EAAE,mCAAmC;GAC9D,eAAe,EAAE,4CAA4C;AAChE;;AAEA;IACI,eAAe,EAAE,4CAA4C;IAC7D,gBAAgB,EAAE,6CAA6C;IAC/D,0BAA0B;IAC1B,iBAAiB;IACjB,WAAW;IACX,UAAU;AACd;;AAEA;GACG,aAAa;GACb,uBAAuB;GACvB,mBAAmB;GACnB,mBAAmB;AACtB;;AAEA;IACI,WAAW;IACX,UAAU;AACd;;AAEA;IACI,WAAW;IACX,UAAU;AACd;;AAEA;IACI,WAAW;IACX,UAAU;AACd;;AAEA;IACI,UAAU;IACV,WAAW;AACf","sourcesContent":[".filterbar {\n   max-width: 700px;\n   display: flex;\n   position: relative;\n   justify-content: center;\n   align-items: center;\n   flex-flow: row wrap;\n   color: black;\n   background-color: #4890a6;\n   border: none;\n   text-align: center;\n   text-decoration: none;\n   display: inline-block;\n   font-size: 20px;\n   margin:10px;\n   cursor: pointer;\n   border-radius: 4px; /* Abgerundete Ecken */\n   transition-duration: 0.4s; /* Animation für den Hover-Effekt */\n   font-size: 16px; /* Ändern Sie die Schriftgröße nach Bedarf */\n}\n\n.filterbar-title {\n    font-size: 16px; /* Ändern Sie die Schriftgröße nach Bedarf */\n    line-height: 1.2; /* Ändern Sie den Zeilenabstand nach Bedarf */\n    text-decoration: underline;\n    text-align:center;\n    padding:0px;\n    margin:5px;\n}\n\n.filterbar-body {\n   display: flex;\n   justify-content: center;\n   align-items: center;\n   flex-flow: row wrap;\n}\n\n.filterbar-alignment {\n    padding:5px;\n    margin:5px;\n}\n\n.filterbar-checkbox {\n    padding:5px;\n    margin:5px;\n}\n\n.filterbar-type {\n    padding:5px;\n    margin:5px;\n}\n\n.filterbar-button {\n    width:40px;\n    height:40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
