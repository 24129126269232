// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.setup-title {
  font-size: 16px; /* Ändern Sie die Schriftgröße nach Bedarf */
  font-style:bold;
  padding:5px;
   text-align:left;
}

.setup-type {
  font-size: 12px; /* Ändern Sie die Schriftgröße nach Bedarf */
  padding:5px;
   text-align:left;
}

.setup-description {
  font-size: 14px; /* Ändern Sie die Schriftgröße nach Bedarf */
  padding:5px;
   text-align:left;

}

.setup-rules {
  font-size: 14px; /* Ändern Sie die Schriftgröße nach Bedarf */
  padding:5px;
  text-align:left;
}

.setup {
  padding:5px;
  margin:20px;
}

.setup-marker {
   font-size: 14px; /* Ändern Sie die Schriftgröße nach Bedarf */
   padding:5px;
   text-align:left;
   font-style:italic;
 }`, "",{"version":3,"sources":["webpack://./src/components/GameSetup.css"],"names":[],"mappings":"AAAA;EACE,eAAe,EAAE,4CAA4C;EAC7D,eAAe;EACf,WAAW;GACV,eAAe;AAClB;;AAEA;EACE,eAAe,EAAE,4CAA4C;EAC7D,WAAW;GACV,eAAe;AAClB;;AAEA;EACE,eAAe,EAAE,4CAA4C;EAC7D,WAAW;GACV,eAAe;;AAElB;;AAEA;EACE,eAAe,EAAE,4CAA4C;EAC7D,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,WAAW;AACb;;AAEA;GACG,eAAe,EAAE,4CAA4C;GAC7D,WAAW;GACX,eAAe;GACf,iBAAiB;CACnB","sourcesContent":[".setup-title {\n  font-size: 16px; /* Ändern Sie die Schriftgröße nach Bedarf */\n  font-style:bold;\n  padding:5px;\n   text-align:left;\n}\n\n.setup-type {\n  font-size: 12px; /* Ändern Sie die Schriftgröße nach Bedarf */\n  padding:5px;\n   text-align:left;\n}\n\n.setup-description {\n  font-size: 14px; /* Ändern Sie die Schriftgröße nach Bedarf */\n  padding:5px;\n   text-align:left;\n\n}\n\n.setup-rules {\n  font-size: 14px; /* Ändern Sie die Schriftgröße nach Bedarf */\n  padding:5px;\n  text-align:left;\n}\n\n.setup {\n  padding:5px;\n  margin:20px;\n}\n\n.setup-marker {\n   font-size: 14px; /* Ändern Sie die Schriftgröße nach Bedarf */\n   padding:5px;\n   text-align:left;\n   font-style:italic;\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
