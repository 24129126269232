import './App.css';
import { useParams } from 'react-router-dom';
import React, { useState,useEffect } from "react";
import { useLanguage } from './LanguageContext';
import { Link } from 'react-router-dom'; // Importiere die Link-Komponente von react-router-dom
import configModule from './config';
import Fraction from './components/Fraction'; // Importieren Sie die Box-Komponente
import Wait from './components/Wait'; // Importieren Sie die Box-Komponente

function FractionDetail() {
  const { itemId } = useParams();
  const langCode = navigator.language.split('-')[0].toUpperCase();
  return (
    <div className="Overview-Body">
     <Fraction entityUUID={itemId} browserLanguage={langCode} />
    </div>
  );
}
export default FractionDetail;