import './App.css';
import { useParams } from 'react-router-dom';
import React, { useState,useEffect } from "react";
import { useLanguage } from './LanguageContext';
import { Link } from 'react-router-dom'; // Importiere die Link-Komponente von react-router-dom
import configModule from './config';
import Textfield from './components/Textfield'; // Importieren Sie die Box-Komponente
import ScenarioDetails from './components/ScenarioDetails'; // Importieren Sie die Box-Komponente
import Image from './components/Image';
import Wait from './components/Wait'; // Importieren Sie die Box-Komponente

function ScenarioDetail() {
  const { translate } = useLanguage();
  const { itemId } = useParams();
  const { type } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [ entity, setEntity ] = useState(null);
  const url = configModule.url()+"/CAMPAIGN/"+itemId+"/true"
  const langCode = navigator.language.split('-')[0].toUpperCase();

  useEffect(() => {
    // Hier sollte der REST-Call stattfinden und die Daten in dataList gespeichert werden
    const fetchData = async () => {
      try {
            const headers = new Headers({
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'lang': langCode, // Füge den Sprachcode als Header hinzu
              'Authorization': configModule.getAuthHeader()
            });

        const response = await fetch(url, { headers });
        // console.log(response)
        if (!response.ok) {
          if (response.status===401) {
                configModule.clearUserAuth()
                window.location.href = process.env.PUBLIC_URL+"/overview"
                console.error("Authentication not ok or lost.")
          }
          throw new Error('Fehler beim Laden der Daten: '+response.status);
        }
        const data = await response.json();
        console.log('Fetched data:', data); // Konsolenausgabe der empfangenen Daten
        setEntity(data); // Daten im Zustand speichern
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [url]);

  return (
    <div className="Overview-Body">
    {isLoading ? (
        <Wait />
        ) : (
      entity && (
       <div>
        <Textfield objectData={entity} type="NAME" classNameToSet="details-title"/>

        <div className="fluff-container">
            <Image
              classNameIn={"fluff-image"}
              type={"CAMPAIGN"}
              id={entity.uuid}
              size={400}
              title={""}/>
             <Textfield objectData={entity} type="INTRO" classNameToSet="details-fluff"/>
        </div>

        <div className="scenarios-container">
            <Link to={`/SCENARIO/${entity.startScenario.uuid}`}>
                <div className="scenarios-inner-container">
                    <Image
                            classNameIn={"miniature-preview-image"}
                            type={"SCENARIO"}
                            id={entity.startScenario.uuid}
                            size={400}
                            title={""}/>
                    <Textfield objectData={entity.startScenario} type="NAME" classNameToSet="details-title"/>
                </div>
            </Link>
            <ScenarioDetails objectData={entity.startScenario}/>
        </div>

        {entity.scenarios && entity.scenarios.filter(item => item.uuidTo.uuid != entity.startScenario.uuid).map ( item => (
                <div className="scenarios-container">
                    <Link to={`/SCENARIO/${item.uuidTo.uuid}`}>
                       <div className="scenarios-inner-container">
                           <Image
                                classNameIn={"miniature-preview-image"}
                                type={"SCENARIO"}
                                id={item.uuidTo.uuid}
                                size={100}
                                fallbackBildUrl={"/transparent.png"}
                                title={""}/>
                            <Textfield objectData={item.uuidTo} type="NAME" classNameToSet="details-title"/>
                       </div>
                    </Link>
                    <ScenarioDetails objectData={item.uuidTo}/>
                </div>
        ))}


       </div>
       )
    )}

        </div>
  );
}

export default ScenarioDetail;