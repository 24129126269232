import React, { useState } from "react";
import './Corps.css'; // Stil für die Box
import { useLanguage } from '../LanguageContext';

function CorpsV({ corps, browserLanguage, styleToSet }) {
  const { translate } = useLanguage();

  return (
      <div className={styleToSet}>
        {corps && (
          <table className='corps-table'>
            <tbody>
                <tr><td>
                    <img className="corps-image" src={`${process.env.PUBLIC_URL}/icons/corps/head.png`} alt="Attribute head" />
                    <div className="corps-value">{corps.heads}</div>
                 </td></tr>
                <tr><td>
                    <img className="corps-image" src={`${process.env.PUBLIC_URL}/icons/corps/torso.png`} alt="Attribute torso" />
                    <div className="corps-value">{corps.torso}</div>
                </td></tr>
                <tr><td>
                    <img className="corps-image" src={`${process.env.PUBLIC_URL}/icons/corps/hands.png`} alt="Attribute hands" />
                    <div className="corps-value">{corps.hands}</div>
                </td></tr>
                <tr><td>
                    <img className="corps-image" src={`${process.env.PUBLIC_URL}/icons/corps/foot.png`} alt="Attribute foot" />
                    <div className="corps-value">{corps.foot}</div>
                </td></tr>
                <tr><td>
                    <img className="corps-image" src={`${process.env.PUBLIC_URL}/icons/corps/tail.png`} alt="Attribute tail" />
                    <div className="corps-value">{corps.tail}</div>
                </td></tr>
            </tbody>
          </table>
        )}
        {!corps && (
            <div> ??? </div>
        )}
      </div>
    );
}

export default CorpsV;
