import React from "react";
import './NPCList.css'; // Stil für die Box
import { useLanguage } from '../LanguageContext';

function NPCActionList({ actions, browserLanguage }) {
  const { translate } = useLanguage();

  const getActionType = (action) => {
    if (action.type === "COMPLEX") {
      // Wenn action.type "COMPLEX" ist, den Spracheintrag für "action.type.complex" zurückgeben
      return translate("action.type.complex");
    } else if (action.type === "SIMPLE") {
      // Wenn action.type "SIMPLE" ist, den Spracheintrag für "action.type.simple" zurückgeben
      return translate("action.type.simple");
    }
    // Wenn keine Übereinstimmung gefunden wurde, leere Zeichenkette zurückgeben
    return "";
  };

  return (
    <>
    { actions && actions.length>0 && (
        <div className='ki'>
          <div className='ki-title'>{translate('ki.actions.title')}</div>
          <table>
            <tbody>
              {actions && actions.size>0 && actions.map((action) => {
                const titleText = action.texts.find(
                  (text) => text.type === "NAME" && text.language === browserLanguage
                );
                const titleTxt =
                  titleText?.text || action.texts.find((text) => text.type === "NAME")?.text || "Unbekannt";

                const descText = action.texts.find(
                  (text) => text.type === "DESCRIPTION" && text.language === browserLanguage
                );
                const descTxt =
                  descText?.text || action.texts.find((text) => text.type === "DESCRIPTION")?.text || "Unbekannt";

                return (
                  <tr key={action.uuid}>
                    <td className='npc-title'>{titleTxt}</td>
                    <td className='npc-type'>{getActionType(action)}</td>
                    <td className='npc-description'>{descTxt}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        )
      }
    </>
  );
}

export default NPCActionList;
