import './App.css';
import React, { useState, useEffect } from "react";
import { useLanguage } from './LanguageContext';
import configModule from './config';
import { useNavigate, Link } from 'react-router-dom';
import Wait from './components/Wait'; // Importieren Sie die Box-Komponente
import Credit from './components/Credit'; // Importieren Sie die Box-Komponente

function Credits() {
  const navigate = useNavigate(); // Hier verwenden wir useNavigate
  const { translate } = useLanguage();

  const [isLoading, setIsLoading] = useState(true);
  const [entity, setEntity] = useState(null);
  const url = configModule.url()+"/CREDIT";
  const langCode = navigator.language.split('-')[0].toUpperCase();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const headers = new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'lang': langCode,
          'Authorization': configModule.getAuthHeader()
        });

        const response = await fetch(url, { headers });
        if (!response.ok) {
          if (response.status === 401) {
            configModule.clearUserAuth();
            window.location.href = process.env.PUBLIC_URL+"/overview";
            console.error("Authentication not ok or lost.");
          }
          throw new Error('Fehler beim Laden der Daten: ' + response.status);
        }
        const data = await response.json();
        console.log('Fetched data:', data);
        setEntity(data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [url]);


  return (
    <div className="Welcome-Body">
      <h1>{translate('credits.title')}</h1>
      <p>{translate('credits.text.all')}</p>
      {isLoading ? (
              <Wait />
            ) : (
              <>
                { entity && entity.map((credit) => (
                   // Beachte die umschließenden Klammern um das Fragment
                   <React.Fragment key={credit.id}> {/* Stelle sicher, dass du ein 'key' Prop hinzufügst */}
                     <Credit credit={credit} browserLanguage={langCode}/>
                   </React.Fragment>
                 ))}
              </>
      )}

      <p>{translate('credits.text.patreons')}</p>
    </div>
  );
}

export default Credits;
