
import Range from './Range'; // Importieren Sie die Box-Komponente

export const UnitHelper = {
    mapType:( type, translate ) => {
      let translatedType = type; // Standard: Der Typ wird nicht übersetzt
      //INFANTRY,COMMANDER,SHOOTER,FIGHTER,CAVALRY,ARTILLERY,SHOOTING_CAVALRY
      if (type === "INFANTRY") {
        translatedType = translate('unit.type.infantry');
      } else if (type === "COMMANDER") {
        translatedType = translate('unit.type.commander');
      } else if (type === "SHOOTER") {
        translatedType = translate('unit.type.shooter');
      } else if (type === "FIGHTER") {
         translatedType = translate('unit.type.fighter');
      }
      else translatedType = ""
      // Fügen Sie hier weitere Übersetzungen für verschiedene Typen hinzu

      return translatedType;
    },

    mergeItems:({ armor, items, browserLanguage }) => {
      const mergedNames = items
        .map((item) =>
          item.texts.find((text) => text.type === "NAME" && text.language === browserLanguage)?.text || "-"
        );

      if (armor) {
        const armorName = armor.texts.find((text) => text.type === "NAME" && text.language === browserLanguage)?.text || "-";
        mergedNames.unshift(armorName); // Fügt das Rüstungsname am Anfang des Arrays hinzu
      }
      return mergedNames.join(", ");
    },

    mergeProperties:(propertiesIn, browserLanguage) => {
      if (!propertiesIn) return "";
      const mergedNames = propertiesIn
        .map((item) =>
          item.texts.find((text) => text.type === "NAME" && text.language === browserLanguage)?.text || ""
        );
      return mergedNames.join(", ");
    },


   listWeapons:(items, browserLanguage) => {
      const mergedWeapons = items.map((item, index) => {
          const itemName = item.texts.find((text) => text.type === "NAME" && text.language === browserLanguage)?.text || "-";

          return (
            <span key={item.id}>{itemName} {item.itemType === "MELEE_WEAPON" || item.itemType === "RANGE_WEAPON" ? <Range range={item.range} /> : null}
              {index < items.length - 1 ? ", " : ""}</span>
          );
        });

        return <>{mergedWeapons}</>;
    },

    sumAttributes:(itemSet, armor, attributes, properties) => {
      const totalAttributes = {
        movement: 0,
        fighting: 0,
        shooting: 0,
        armor: 0,
        magic: 0,
        moral: 0,
        hitpoints: 0,
      };

      if (attributes) {
        Object.keys(totalAttributes).forEach((attribute) => {
          totalAttributes[attribute] += attributes[attribute] || 0;
        });
      }

      if (armor) {
        Object.keys(totalAttributes).forEach((attribute) => {
          totalAttributes[attribute] += armor.attributes[attribute] || 0;
        });
      }

      if (itemSet) {
        Object.keys(totalAttributes).forEach((attribute) => {
          totalAttributes[attribute] += itemSet.attributes[attribute] || 0;
        });
      }

      properties && properties.forEach((property) => {
        if (property.attributes) {
          Object.keys(totalAttributes).forEach((attribute) => {
            totalAttributes[attribute] += property.attributes[attribute] || 0;
          });
        }
      });

      itemSet && itemSet.items && itemSet.items.forEach((item) => {
        if (item.attributes) {
          Object.keys(totalAttributes).forEach((attribute) => {
            totalAttributes[attribute] += item.attributes[attribute] || 0;
          });
        }
      });

      armor && armor.items && armor.items.forEach((item) => {
        if (item.attributes) {
          Object.keys(totalAttributes).forEach((attribute) => {
            totalAttributes[attribute] += item.attributes[attribute] || 0;
          });
        }
      });

      return totalAttributes;
    },

    extractUniqueProperties:(entity) => {
      const allProperties = new Set();

      if (entity && entity.properties) {
        entity.properties.forEach((property) => {
          allProperties.add(property); // Annahme: "name" ist der Name der Property
        });
      }

      if (entity && entity.sets) {
        entity.sets.forEach((set) => {
          if (set.properties) {
            set.properties.forEach((property) => {
              allProperties.add(property);
            });
          }
          if (set.items) {
            set.items.forEach((item) => {
              if (item.properties) {
                item.properties.forEach((property) => {
                  allProperties.add(property);
                });
              }
            });
          }
        });
      }

      if (entity && entity.items) {
        entity.items.forEach((item) => {
          if (item.properties) {
            item.properties.forEach((property) => {
              allProperties.add(property);
            });
          }
        });
      }

      if (entity && entity.armor && entity.armor.properties) {
        entity.armor.properties.forEach((property) => {
          allProperties.add(property);
        });
      }

      return Array.from(allProperties); // Konvertiere Set in ein Array
    }
}