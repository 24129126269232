// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.range-image {
  z-index:-1;
  width:30px;
}

.range-value {
  z-index:0;
  vertical-align:middle;
  position: relative;
  text-align: center;
}

.range-table {
}
`, "",{"version":3,"sources":["webpack://./src/components/Range.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,UAAU;AACZ;;AAEA;EACE,SAAS;EACT,qBAAqB;EACrB,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;AACA","sourcesContent":[".range-image {\n  z-index:-1;\n  width:30px;\n}\n\n.range-value {\n  z-index:0;\n  vertical-align:middle;\n  position: relative;\n  text-align: center;\n}\n\n.range-table {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
