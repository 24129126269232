import React, { useState } from "react";
import './Range.css'; // Stil für die Box
import { useLanguage } from '../LanguageContext';

function Range({ range, browserLanguage }) {
  //console.log("language: "+browserLanguage);
  const { translate } = useLanguage();
  let bonusShort = range?.bonusShort ?? 0;
  let bonusLong = range?.bonusLong ?? 0;
  let min = range?.min ?? 0;
  let max = range?.max ?? 0;
  let mid = Math.round((min + max) / 2) ;

  return (
    <>
      { range && range.max === 1 && (
        <>
            <span className="range_color1">0</span>-<span className="range_color3">1</span> | <span className="range_color4">{bonusShort}</span>
        </>
      )}
       { range && range.max > 1 && (
         <>
             [
             <span className="range_color1">{min}</span>
             -
             <span className="range_color2">{mid}</span>
             ]
             -
             <span className="range_color3">{max}</span> | <span className="range_color4">{bonusShort}</span> /  <span className="range_color5">{bonusLong}</span>
         </>
       )}
    </>
  );
}

export default Range;
