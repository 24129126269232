import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import configModule from './config';
import Unit from './components/Unit';
import Fraction from './components/Fraction';
import Wait from './components/Wait'; // Importieren Sie die Box-Komponente
import SelectFraction from './components/SelectFraction'; // Importieren Sie die Box-Komponente
import { useLanguage } from './LanguageContext';

function generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

function UnitEditor() {
  const { mode, bId, tId, base, target } = useParams(); // Vereinfachte Nutzung von useParams()
  const { translate } = useLanguage();
  const navigate = useNavigate();
  const location = useLocation();
  const returnUrl = location.state?.returnUrl ?? "/overview ";
  const [baseType, setBaseType] = useState(base);
  const [targetType, setTargetType] = useState(target);
  const [baseID, setBaseID] = useState(bId);
  const [targetID, setTargetID] = useState(tId);
  const [isLoading, setIsLoading] = useState(true);
  const [newUnit, setNewUnit] = useState(mode==="add");
  const [unit, setUnit] = useState(null); // unit als Zustand definieren
  const [unitUUID, setUnitUUID] = useState(null); // unit als Zustand definieren
  const [fractions, setFractions] = useState([]); // Zustand für die Liste der Fraktionen

  const langCode = navigator.language.split('-')[0].toUpperCase();
  const headers = new Headers({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'lang': langCode,
      'Authorization': configModule.getAuthHeader(),
  });

  // Komponenten-Map für bedingtes Rendering
  const componentMap = {
    "UNIT": Unit,
    "LEGEND": Unit,
    "HERO": Unit,
    "FRACTION": Fraction,
    "GUILD": Fraction,
  };

  const TargetComponent = componentMap[target] || Unit; // Fallback auf Unit

  function onAbort()
  {
    navigate(returnUrl);
  }

  const commonProps = {
      entityUUID: targetID,
      baseUUID: baseID,
      browserLanguage: translate,
      editMode: true,
      mode: mode,
      onAbort: onAbort,
      base: base,
      target:target,
      mode:mode
  };

  console.log(commonProps);

  useEffect(() => {
      // Funktion, um Fraktionen zu laden
      const fetchFractions = async () => {
       let url = `${configModule.url()}/FRACTION`;
       const response = await fetch(url, { headers });
        if (!response.ok) {
          if (response.status === 401) {
            configModule.clearUserAuth();
            window.location.href = process.env.PUBLIC_URL + "/overview";
            console.error("Authentication not ok or lost.");
          }
          throw new Error('Fehler beim Laden der Daten: ' + response.status);
        }
        const data = await response.json();
        console.log('Fetched data:', data);
        setFractions(data);
        setIsLoading(false); // Beendet den Ladezustand, sobald die Daten geladen sind
      };

      fetchFractions();
  }, []);

  if (isLoading) {
      return <Wait />;
  } else if (baseID || targetID) {
      // Zeige die Einheit an, wenn sie definiert ist
      return (
        <div className="Overview-Body">
          <TargetComponent {...commonProps} />
          <div className="spacer-100"/>
        </div>
      );
  } else {
      // Zeige die Fraktionsauswahl an, wenn die Einheit nicht definiert ist
      return (
        <div className="Overview-Body">
          <SelectFraction fractions={fractions} onFractionSelected={(selectedFraction) => {
            console.log(selectedFraction);
            setBaseID(selectedFraction.uuid);
          }}/>
        </div>
      );
  }
}

export default UnitEditor;
