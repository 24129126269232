// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.attribute-td {
  width:50px;
  height:50px;
}

.attribute-image {
  z-index:-1;
  width:50px;
  height:50px;
}

.attribute-value {
  z-index:0;
  vertical-align:middle;
  font-size: 25px;
  position: relative;
  top: -50px;
  left: -50px;
  text-align: center;
  width:50px;
  height:0px;
}

.attribute-value-embedded {
  z-index:0;
  vertical-align:middle;
  font-size: 25px;
  position: relative;
  top: -50px;
  text-align: center;
  width:50px;
  height:0px;
}

.attribute-edit {
  background-color: black;
  height: 50px;
  z-index:0;
  vertical-align:middle;
  font-size: 25px;
  position: relative;
  top: -17px;
  text-align: center;
  width:50px;
  left: 0px;
}

.attribute-color-movement {
  color:#FEFFBE;
}

.attribute-color-armor {
  color:#FEFFBE;
}

.attribute-color-fighting {
  color:#E8AEAF;
}

.attribute-color-shooting {
  color:#E8AEAF;
}

.attribute-color-magic {
  color:#E6FFFD;
}

.attribute-color-moral {
  color:#E6FFFD;
}

.attribute-table {
}`, "",{"version":3,"sources":["webpack://./src/components/Attributes.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,WAAW;AACb;;AAEA;EACE,UAAU;EACV,UAAU;EACV,WAAW;AACb;;AAEA;EACE,SAAS;EACT,qBAAqB;EACrB,eAAe;EACf,kBAAkB;EAClB,UAAU;EACV,WAAW;EACX,kBAAkB;EAClB,UAAU;EACV,UAAU;AACZ;;AAEA;EACE,SAAS;EACT,qBAAqB;EACrB,eAAe;EACf,kBAAkB;EAClB,UAAU;EACV,kBAAkB;EAClB,UAAU;EACV,UAAU;AACZ;;AAEA;EACE,uBAAuB;EACvB,YAAY;EACZ,SAAS;EACT,qBAAqB;EACrB,eAAe;EACf,kBAAkB;EAClB,UAAU;EACV,kBAAkB;EAClB,UAAU;EACV,SAAS;AACX;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;AACA","sourcesContent":[".attribute-td {\n  width:50px;\n  height:50px;\n}\n\n.attribute-image {\n  z-index:-1;\n  width:50px;\n  height:50px;\n}\n\n.attribute-value {\n  z-index:0;\n  vertical-align:middle;\n  font-size: 25px;\n  position: relative;\n  top: -50px;\n  left: -50px;\n  text-align: center;\n  width:50px;\n  height:0px;\n}\n\n.attribute-value-embedded {\n  z-index:0;\n  vertical-align:middle;\n  font-size: 25px;\n  position: relative;\n  top: -50px;\n  text-align: center;\n  width:50px;\n  height:0px;\n}\n\n.attribute-edit {\n  background-color: black;\n  height: 50px;\n  z-index:0;\n  vertical-align:middle;\n  font-size: 25px;\n  position: relative;\n  top: -17px;\n  text-align: center;\n  width:50px;\n  left: 0px;\n}\n\n.attribute-color-movement {\n  color:#FEFFBE;\n}\n\n.attribute-color-armor {\n  color:#FEFFBE;\n}\n\n.attribute-color-fighting {\n  color:#E8AEAF;\n}\n\n.attribute-color-shooting {\n  color:#E8AEAF;\n}\n\n.attribute-color-magic {\n  color:#E6FFFD;\n}\n\n.attribute-color-moral {\n  color:#E6FFFD;\n}\n\n.attribute-table {\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
