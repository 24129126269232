import React, { useState } from "react";
import './Corps.css'; // Stil für die Box
import { useLanguage } from '../LanguageContext';

function Corps({ corps, browserLanguage, styleToSet }) {
  const { translate } = useLanguage();

  return (
      <div className='corps-section'>
        {corps && (
          <table className='corps-table'>
            <thead>
                <tr>
                    <th><img className="corps-image" src={`${process.env.PUBLIC_URL}/icons/corps/head.png`} alt="Attribute head" /></th>
                    <th><img className="corps-image" src={`${process.env.PUBLIC_URL}/icons/corps/torso.png`} alt="Attribute torso" /></th>
                    <th><img className="corps-image" src={`${process.env.PUBLIC_URL}/icons/corps/hands.png`} alt="Attribute hands" /></th>
                    <th><img className="corps-image" src={`${process.env.PUBLIC_URL}/icons/corps/foot.png`} alt="Attribute foot" /></th>
                    <th><img className="corps-image" src={`${process.env.PUBLIC_URL}/icons/corps/tail.png`} alt="Attribute tail" /></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td className="corps-value">{corps.heads}</td>
                    <td className="corps-value">{corps.torso}</td>
                    <td className="corps-value">{corps.hands}</td>
                    <td className="corps-value">{corps.foot}</td>
                    <td className="corps-value">{corps.tail}</td>
                </tr>
            </tbody>
          </table>
        )}
        {!corps && (
            <div> ??? </div>
        )}
      </div>
    );
}

export default Corps;
