import './NPCList.css';
import { useParams } from 'react-router-dom';
import React, { useState,useEffect } from "react";
import { useLanguage } from '../LanguageContext';
import { Link } from 'react-router-dom'; // Importiere die Link-Komponente von react-router-dom
import configModule from '../config';
import Textfield from './Textfield'; // Importieren Sie die Box-Komponente
import ActionList from './ActionList'; // Importieren Sie die Box-Komponente
import UnitFlat from './UnitFlat'; // Importieren Sie die Box-Komponente
import UnitHeader from './UnitHeader'; // Importieren Sie die Box-Komponente


function NPCDetail( { npc, browserLanguage } ) {
  const { translate } = useLanguage();

 return (
   <React.Fragment>
     {
       npc && (
         <div>
            <table className="npc-values">
              <UnitHeader browserLanguage={browserLanguage} />
              <tbody>
                <tr><td className='line' colSpan="10"></td></tr>
                <UnitFlat unit={npc.unit} browserLanguage={browserLanguage} />
              </tbody>
            </table>
         </div>
       )
    }
   </React.Fragment>
 );
    }

export default NPCDetail;