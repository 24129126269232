import React, { useState } from "react";
import './Attributes.css'; // Stil für die Box
import { useLanguage } from '../LanguageContext';

function AttributesVEdit({ attributes, browserLanguage, styleToSet, onUpdate }) {
    const { translate } = useLanguage();
    const [attr, setAttr] = useState(attributes);

    // Erstellt ein Dropdown-Menü für Attribute-Werte zwischen 0 und 10
    const renderAttributeDropdown = (attributeKey, label) => (
        <tr>
            <td>
                <select
                   className={`attribute-edit attribute-color-${label}`}
                   value={attr[attributeKey]}
                   onChange={(e) => handleAttributeChange(attributeKey, e.target.value)}
                 >
                    {[...Array(11).keys()].map(value => (
                        <option key={value} value={value}>{value}</option>
                    ))}
                </select>
                <img className={`attribute-image`} src={`${process.env.PUBLIC_URL}/icons/attributes/${label}.png`} alt={`Attribute ${label}`} />
            </td>
        </tr>
    );

    // Behandelt die Änderung der Attributwerte und ruft onUpdate mit den aktualisierten Attributen auf
    const handleAttributeChange = (attributeKey, newValue) => {
        const updatedAttributes = { ...attributes, [attributeKey]: parseInt(newValue, 10) };
        setAttr(updatedAttributes)
        onUpdate(updatedAttributes);
    };

    return (
        <div className={styleToSet}>
            {attr && (
                <table className='attribute-table'>
                    <tbody>
                        {renderAttributeDropdown('movement', 'movement')}
                        {renderAttributeDropdown('armor', 'armor')}
                        {renderAttributeDropdown('fighting', 'fighting')}
                        {renderAttributeDropdown('shooting', 'shooting')}
                        {renderAttributeDropdown('magic', 'magic')}
                        {renderAttributeDropdown('moral', 'moral')}
                    </tbody>
                </table>
            )}
            {!attr && (
                <div> ??? </div>
            )}
        </div>
    );
}

export default AttributesVEdit;
