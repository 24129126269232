import React, { useState } from "react";
import './Attributes.css'; // Stil für die Box
import { useLanguage } from '../LanguageContext';

function AttributesV({ attributes, browserLanguage, styleToSet }) {
  const { translate } = useLanguage();

  return (
      <div className={styleToSet}>
        {attributes && (
          <table className='attribute-table'>
            <tbody>
                <tr><td>
                    <img className="attribute-image" src={`${process.env.PUBLIC_URL}/icons/attributes/movement.png`} alt="Attribute movement" />
                    <div className="attribute-value attribute-color-movement">{attributes.movement}</div>
                    </td></tr>
                <tr><td>
                    <img className="attribute-image" src={`${process.env.PUBLIC_URL}/icons/attributes/armor.png`} alt="Attribute armor" />
                    <div className="attribute-value attribute-color-armor">{attributes.armor}</div>
                    </td></tr>
                <tr><td>
                    <img className="attribute-image" src={`${process.env.PUBLIC_URL}/icons/attributes/fighting.png`} alt="Attribute fighting" />
                    <div className="attribute-value attribute-color-fighting">{attributes.fighting}</div>
                    </td></tr>
                <tr><td>
                    <img className="attribute-image" src={`${process.env.PUBLIC_URL}/icons/attributes/shooting.png`} alt="Attribute shooting" />
                    <div className="attribute-value attribute-color-shooting">{attributes.shooting}</div>
                    </td></tr>
                <tr><td>
                    <img className="attribute-image" src={`${process.env.PUBLIC_URL}/icons/attributes/magic.png`} alt="Attribute magic" />
                    <div className="attribute-value attribute-color-magic">{attributes.magic}</div>
                    </td></tr>
                <tr><td>
                    <img className="attribute-image" src={`${process.env.PUBLIC_URL}/icons/attributes/moral.png`} alt="Attribute moral" />
                    <div className="attribute-value attribute-color-moral">{attributes.moral}</div>
                    </td></tr>
            </tbody>
          </table>
        )}
        {!attributes && (
            <div> ??? </div>
        )}
      </div>
    );
}

export default AttributesV;
