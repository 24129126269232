// Imprint.js
import React, { useState, useEffect } from 'react';
import { useLanguage } from './LanguageContext';
import { Link } from 'react-router-dom'; // Importiere die Link-Komponente von react-router-dom

function PrivacyPolicy() {
  const { translate } = useLanguage();
  const [privacyContent, setPrivacyContent] = useState('');

  useEffect(() => {
    const lang = navigator.language.split('-')[0];
    console.log('LANGUAGE (PRIVACY)',lang);
    fetch(`./locales/privacy_${lang}.txt`)
      .then((response) => response.text())
      .then((data) => setPrivacyContent(data))
      .catch((error) => console.error('Error loading privacy policy content:', error));
  }, []);

  return (
    <div className="privacy">
     <h1>{translate('privacy.title')}</h1>
      <div dangerouslySetInnerHTML={{ __html: privacyContent }} />
    </div>
  );
}

export default PrivacyPolicy;