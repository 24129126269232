// ErrorPage.js
import './App.css';
import { useLanguage } from './LanguageContext';

function About() {
  const { translate } = useLanguage();

  return (
      <div className='about'>
         <div>{translate('about.teaser.0')}</div>
         <p>&#10003; {translate('about.teaser.1')}</p>
         <p>&#10003; {translate('about.teaser.2')}</p>
         <p>&#10003; {translate('about.teaser.3')}</p>
         <p>&#10003; {translate('about.teaser.4')}</p>
      </div>
  );
}

export default About;