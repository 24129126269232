import React, { useEffect, useState } from 'react';
import './Textfield.css'; // Stil für die Box

function Textfield({ objectData, type, classNameToSet }) {

  // Funktion zur Auswahl des Texts basierend auf der Browsersprache
   const getTextByTypeAndLanguage = (type) => {
   const lang = navigator.language.split('-')[0];
   console.log("Textfield language: "+lang)
   if (objectData && objectData.texts) {
        //console.log(objectData);
        const matchingText = objectData.texts.find(
          (text) => text.type === type && text.language === lang.toUpperCase()
        );
        // Falls kein übereinstimmender Text gefunden wurde, verwende den englischen Text als Standard
        return matchingText ? matchingText.text : '';
   }
   // Wenn objectData oder objectData.texts nicht definiert sind, gib einen Platzhaltertext zurück
    return 'not text available';
  };

  return (
      <div className={classNameToSet}>{getTextByTypeAndLanguage(type)}</div>
  );
}

export default Textfield;