// Imprint.js
import React, { useState, useEffect } from 'react';
import { useLanguage } from './LanguageContext';
import { Link } from 'react-router-dom'; // Importiere die Link-Komponente von react-router-dom

function Imprint() {
  const { translate } = useLanguage();
  const [imprintContent, setImprintContent] = useState('');

  useEffect(() => {
    const lang = navigator.language.split('-')[0];
    console.log('LANGUAGE (IMPRINT)',lang);
    fetch(`./locales/imprint_${lang}.txt`)
      .then((response) => response.text())
      .then((data) => setImprintContent(data))
      .catch((error) => console.error('Error loading imprint content:', error));
  }, []);

  return (
    <div className="imprint">
      <h1>{translate('imprint.title')}</h1>
      <div dangerouslySetInnerHTML={{ __html: imprintContent }} />
    </div>
  );
}

export default Imprint;