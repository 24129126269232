import React from 'react';
import { Link } from 'react-router-dom';
import { useLanguage } from '../LanguageContext';

function Navigation() {
  const { translate } = useLanguage();

  return (
    <nav className="navigation">
      <ul>
        <li> <Link to="/">{translate('navigation.overview')}</Link> </li>
        <li> <Link to="/login">{translate('navigation.login')}</Link> </li>
        <li> <Link to="/rules">{translate('navigation.rules')}</Link> </li>
        <li> <Link to="/campaigns">{translate('navigation.campaigns')}</Link> </li>
        <li> <Link to="/scenarios">{translate('navigation.scenarios')}</Link> </li>
        <li> <Link to="/missions">{translate('navigation.missions')}</Link> </li>
        {/* Weitere Navigationslinks hier */}
      </ul>
    </nav>
  );
}

export default Navigation;