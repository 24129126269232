// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.action-title {
  font-size: 16px; /* Ändern Sie die Schriftgröße nach Bedarf */
  font-style:bold;
  padding:5px;
  text-align: start;
}

.action-type {
  font-size: 12px; /* Ändern Sie die Schriftgröße nach Bedarf */
  padding:5px;
}

.action-description {
  font-size: 14px; /* Ändern Sie die Schriftgröße nach Bedarf */
  padding:5px;
  text-align: start;
}

.action {
}
`, "",{"version":3,"sources":["webpack://./src/components/ActionList.css"],"names":[],"mappings":"AAAA;EACE,eAAe,EAAE,4CAA4C;EAC7D,eAAe;EACf,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,eAAe,EAAE,4CAA4C;EAC7D,WAAW;AACb;;AAEA;EACE,eAAe,EAAE,4CAA4C;EAC7D,WAAW;EACX,iBAAiB;AACnB;;AAEA;AACA","sourcesContent":[".action-title {\n  font-size: 16px; /* Ändern Sie die Schriftgröße nach Bedarf */\n  font-style:bold;\n  padding:5px;\n  text-align: start;\n}\n\n.action-type {\n  font-size: 12px; /* Ändern Sie die Schriftgröße nach Bedarf */\n  padding:5px;\n}\n\n.action-description {\n  font-size: 14px; /* Ändern Sie die Schriftgröße nach Bedarf */\n  padding:5px;\n  text-align: start;\n}\n\n.action {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
