import React from "react";
import './NPCList.css'; // Stil für die Box
import { useLanguage } from '../LanguageContext';
import NPCDetail from './NPCDetail'; // Importieren Sie die Box-Komponente
import NPCKI from './NPCKI'; // Importieren Sie die Box-Komponente
import NPCActionList from './NPCActionList'; // Importieren Sie die Box-Komponente
import Image from './Image';

function NPCList({ title, npcs, browserLanguage, showFluff }) {
  const { translate } = useLanguage();

  const getNPCType = (npc) => {
      console.log(npc.control)
      if (npc.control === "KI") {
        // Wenn event.trigger "MARKER" ist, den Spracheintrag für "event.marker" zurückgeben
        return translate("npc.control.ki")
      } else if (npc.control === "GOOD") {
        // Wenn event.trigger "ROUND" ist, den Spracheintrag für "event.round" zurückgeben
        return translate("npc.control.good")
      }
      else if (npc.control === "EVIL") {
          // Wenn event.trigger "ROUND" ist, den Spracheintrag für "event.round" zurückgeben
        return translate("npc.control.evil")
      }
      // Wenn keine Übereinstimmung gefunden wurde, leere Zeichenkette zurückgeben
      return "";
    };

  return (
    <div className='npc'>
      <h4>{title}</h4>
        {npcs && npcs.map((npc) => {
          const title = npc.texts.find( (text) => text.type === "NAME" && text.language === browserLanguage );
          const titleTxt = title?.text || npc.texts.find((text) => text.type === "NAME"  && text.language === browserLanguage)?.text || "";

          const desc = npc.texts.find( (text) => text.type === "DESCRIPTION" && text.language === browserLanguage );
          const descTxt = desc?.text || npc.texts.find((text) => text.type === "DESCRIPTION" && text.language === browserLanguage)?.text || "";

          const fluff = npc.texts.find( (text) => text.type === "FLUFF" && text.language === browserLanguage );
          const fluffTxt = desc?.text || npc.texts.find((text) => text.type === "FLUFF" && text.language === browserLanguage)?.text || "";

          return (
            <div key={npc.uuid} className='npc-entry'>
              <div className='npc-title'> {npc.name} </div>
              <div className='npc-type'> ({getNPCType(npc)}) </div>
              { showFluff && (
                     <div className='npc-header'>
                        <Image
                            classNameIn={"npc-image"}
                            type={"NPC"}
                            id={npc.uuid}
                            size={100}
                            title={""}/>
                        <div className='npc-fluff'> {fluffTxt} </div>
                    </div>
              )}
              <div className='npc-details'>
                  <NPCDetail npc={npc} browserLanguage={browserLanguage} />
                  <NPCKI ki={npc.ki} browserLanguage={browserLanguage} />
                  <NPCActionList actions={npc.actions} browserLanguage={browserLanguage} />
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default NPCList;
