import React, { useState,useEffect } from "react";
import './Unit.css'; // Stil für die Box
import { useLanguage } from '../LanguageContext';
import Attributes from './Attributes'; // Importieren Sie die Box-Komponente
import AttributesV from './AttributesV'; // Importieren Sie die Box-Komponente
import Properties from './Properties'; // Importieren Sie die Box-Komponente
import Corps from './Corps'; // Importieren Sie die Box-Komponente
import CorpsV from './CorpsV'; // Importieren Sie die Box-Komponente
import EntityCommonV from './EntityCommonV'; // Importieren Sie die Box-Komponente
import EntityCommon from './EntityCommon'; // Importieren Sie die Box-Komponente
import ItemSet from './ItemSet'; // Importieren Sie die Box-Komponente
import { Link,useNavigate } from 'react-router-dom'; // Importiere die Link-Komponente von react-router-dom
import Image from './Image';
import FractionRO from './FractionRO'; // Importieren Sie die Box-Komponente
import FractionRW from './FractionRW'; // Importieren Sie die Box-Komponente
import configModule from '../config';

function Fraction({ target, baseUUID, entityUUID, browserLanguage, style, mode, editMode, onAbort }) {
  //console.log("language: "+browserLanguage);
  const { translate } = useLanguage();
  const navigate = useNavigate();
  const [entity, setEntity] = useState(null); // Zustand für die Unit
  const [isLoading, setIsLoading] = useState(false); // Zustand für den Ladeindikator

  const langCode = navigator.language.split('-')[0].toUpperCase();
  const headers = new Headers({
       'Accept': 'application/json',
       'Content-Type': 'application/json',
       'lang': langCode,
       'Authorization': configModule.getAuthHeader(),
  });

  function generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
  }

  useEffect(() => {
      const loadUnitOrFractionData = async () => {
        if (entityUUID) {
          // Lade bestehende Unit
          const url = `${configModule.url()}/FRACTION/${entityUUID}/true`;
          console.log("Lade Unit mit URL: " + url);
          try {
            setIsLoading(true);
            const response = await fetch(url, { headers });
            if (!response.ok) {
              throw new Error('Fehler beim Laden der Unit: ' + response.status);
            }
            const data = await response.json();
            setEntity(data);
          } catch (error) {
            console.error('Fehler beim Laden der Fraktion:', error);
          } finally {
            setIsLoading(false);
          }
        } else if (baseUUID) {
          // Initialisiere neue Unit basierend auf Fraktion
          const url = `${configModule.url()}/FRACTION/${baseUUID}/true`;
          console.log("Lade Fraktion mit URL: " + url);
          try {
            setIsLoading(true);
            const response = await fetch(url, { headers } );
            if (!response.ok) {
              throw new Error('Fehler beim Laden der Basis-Fraktion: ' + response.status);
            }
            const fractionData = await response.json();
            const newData = {
              ...fractionData,
              fraction:fractionData,
              uuid: generateUUID(),
              attributes: fractionData.attributes || [],
              metadata: { ...fractionData.metadata, visibility: "PRIVATE", owner: configModule.getUserUUID() },
              entityCommon: { ...fractionData.entityCommon, parent: fractionData.uuid, type: "FRACTION" }, // Beispiel-Typ
              guild: target==="GUILD",
              texts: [],
              gameType: null,
              setting: null,
              points: fractionData.points,
              properties: fractionData.properties
            };
            setEntity(newData);
          } catch (error) {
            console.error('Fehler beim Laden der Fraktion:', error);
          } finally {
            setIsLoading(false);
          }
        } else {
          // Weder unitUUID noch fractionUUID angegeben -> Fehler ausgeben
          console.error('Fehler: Weder unitUUID noch fractionUUID angegeben.');
        }
      };

      loadUnitOrFractionData();
  }, [entityUUID, baseUUID]);

  return (
      <div>
        {isLoading && <p>Loading...</p>}
        {!isLoading && entity && !editMode && (
          <FractionRO entity={entity} browserLanguage={browserLanguage} style={style} />
        )}
        {!isLoading && entity && editMode && (
          <FractionRW entity={entity} browserLanguage={browserLanguage} style={style} mode={mode} onAbort={onAbort} />
        )}

        {!isLoading && !entity && navigate(`/fu`)}
      </div>
    );
}
export default Fraction;
