import React, { useState,useEffect } from "react";
import './UnitEditor.css'; // Stil für die Box
import { useLanguage } from '../LanguageContext';
import configModule from '../config';
import Wait from './Wait';
import AttributesVEdit from './AttributesVEdit'; // Importieren Sie die Box-Komponente
import Properties from './Properties'; // Importieren Sie die Box-Komponente
import Corps from './Corps'; // Importieren Sie die Box-Komponente
import CorpsV from './CorpsV'; // Importieren Sie die Box-Komponente
import EntityCommonVEdit from './EntityCommonVEdit'; // Importieren Sie die Box-Komponente
import ItemSet from './ItemSet'; // Importieren Sie die Box-Komponente
import ItemList from './ItemList'; // Importieren Sie die Box-Komponente
import PropertyList from './PropertyList'; // Importieren Sie die Box-Komponente
import { Link } from 'react-router-dom'; // Importiere die Link-Komponente von react-router-dom
import Image from './Image';
import EditorArmor from './EditorArmor'; // Importieren Sie die Box-Komponente
import EditorWeaponSet from './EditorWeaponSet'; // Importieren Sie die Box-Komponente
import EditorListEdit from './EditorListEdit'; // Importieren Sie die Box-Komponente
import Actionbar from './Actionbar'; // Importieren Sie die Box-Komponente
import ImageUploader from './ImageUploader';

function mapType( type, translate ) {
  console.log("type: "+type);
  let translatedType = type; // Standard: Der Typ wird nicht übersetzt
  //INFANTRY,COMMANDER,SHOOTER,FIGHTER,CAVALRY,ARTILLERY,SHOOTING_CAVALRY
  if (type === "INFANTRY") {
    translatedType = translate('unit.type.infantry');
  } else if (type === "COMMANDER") {
    translatedType = translate('unit.type.commander');
  } else if (type === "SHOOTER") {
    translatedType = translate('unit.type.shooter');
  } else if (type === "FIGHTER") {
     translatedType = translate('unit.type.fighter');
  }
  else translatedType = ""
  // Fügen Sie hier weitere Übersetzungen für verschiedene Typen hinzu

  return translatedType;
}

 function useDebounce(value, delay) {
   const [debouncedValue, setDebouncedValue] = useState(value);

   useEffect(() => {
     const handler = setTimeout(() => {
       setDebouncedValue(value);
     }, delay);

     return () => {
       clearTimeout(handler);
     };
   }, [value, delay]);

   return debouncedValue;
 }


function UnitRW({ entity, browserLanguage, style, onAbort, mode  }) {

  const { translate } = useLanguage();
  const langCode = navigator.language.split('-')[0].toUpperCase();

  console.log("loading entity: ",entity)
  const fractionName = entity.fraction.texts.find((text) => text.type === "NAME" && text.language === langCode)?.text || "";

  // Initialisiere den Zustand für die Texte basierend auf entity.texts oder einem leeren Array, falls nicht vorhanden
  const [isLoading, setIsLoading] = useState(true);
  const [texts, setTexts] = useState(entity.texts || []);
  const [type, setType] = useState(entity.type || []);
  const [currentEntity, setCurrentEntity] = useState(entity);
  const [points, setPoints] = useState(entity.points);
  const [settings, setSettings] = useState();
  const [sets, setSets] = useState();
  const [items, setItems] = useState();
  const [properties, setProperties] = useState();
  const [selectedArmor, setSelectedArmor] = useState('');
  const [selectedArmorObject, setSelectedArmorObject] = useState();
  const [selectedWeapons, setSelectedWeapons] = useState([]);
  const [imageFile, setImageFile] = useState(null);
  const [newEntity, setNewEntity] = useState(mode==="add");

  console.log("got new unit: ",newEntity)
  //console.log("current image file is: ",imageFile)

  // infos für web calls
  const headers = new Headers({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'lang': langCode,
      'Authorization': configModule.getAuthHeader(),
  });

  //console.log('Editing unit:', currentEntity);

   // Funktion, die bei jeder Änderung der Attribute aufgerufen wird
   const handleEntityCommonUpdate = (updatedEntityCommon) => {
     const updatedEntity = { ...currentEntity, modelCommon: updatedEntityCommon };
     setCurrentEntity(updatedEntity); // Aktualisiere den lokalen Zustand
   };

   const handleItemDeletion = (item) => {
        //console.log("deleting item: ",item)
        const currentItems = currentEntity.items
        const filtered = currentItems.filter(listItem => listItem.uuid !== item.uuid)
        const updatedEntity = { ...currentEntity, items: filtered };
        setCurrentEntity(updatedEntity);
   };

   const handleItemAdd = (item) => {
        const currentItems = currentEntity.items?currentEntity.items:[]
        if (!currentItems.some(listItem => listItem.uuid === item.uuid))
        {
            //console.log("adding item: ",item)
            currentItems.push(item)
            const updatedEntity = { ...currentEntity, items: currentItems };
            setCurrentEntity(updatedEntity);
        }
   };

   const handlePropertyDeletion = (item) => {
           //console.log("deleting property: ",item)
           const currentItems = currentEntity.properties
           const filtered = currentItems.filter(listItem => listItem.uuid !== item.uuid)
           const updatedEntity = { ...currentEntity, properties: filtered };
           setCurrentEntity(updatedEntity);
      };

   const handlePropertyAdd = (item) => {
       const currentItems = currentEntity.properties?currentEntity.properties:[]
       if (!currentItems.some(listItem => listItem.uuid === item.uuid))
       {
           //console.log("adding property: ",item)
           currentItems.push(item)
           const updatedEntity = { ...currentEntity, properties: currentItems };
           setCurrentEntity(updatedEntity);
       }
   };

    const handleSettingDeletion = (item) => {
              //console.log("deleting settings: ",item)
              const currentItems = currentEntity.settings
              const filtered = currentItems.filter(listItem => listItem.uuid !== item.uuid)
              const updatedEntity = { ...currentEntity, settings: filtered };
              setCurrentEntity(updatedEntity);
         };

     const handleSettingAdd = (item) => {
          const currentItems = currentEntity.settings?currentEntity.settings:[]
          if (!currentItems.some(listItem => listItem.uuid === item.uuid))
          {
              //console.log("adding settings: ",item)
              currentItems.push(item)
              const updatedEntity = { ...currentEntity, settings: currentItems };
              setCurrentEntity(updatedEntity);
          }
    };

    // Funktion, die bei jeder Änderung der Attribute aufgerufen wird
   const handleHPUpdate = (hp) => {
     const updatedEntity = { ...currentEntity, hitpoints: hp };
     setCurrentEntity(updatedEntity); // Aktualisiere den lokalen Zustand
   };

   // Funktion, die bei jeder Änderung der Attribute aufgerufen wird
   const handleAttributesUpdate = (updatedAttributes) => {
     const updatedEntity = { ...currentEntity, attributes: updatedAttributes };
     setCurrentEntity(updatedEntity); // Aktualisiere den lokalen Zustand
   };

  const handleArmorSelectionChange = (e) => {
          const selectedUuid = e.target.value;
          setSelectedArmor(selectedUuid);
          // Finden des ausgewählten Gegenstands anhand seiner UUID
          const selectedItemObject = fullSets.find(item => item.uuid === selectedUuid);
          if (selectedItemObject) {
              const updatedEntity = { ...currentEntity, armor: selectedItemObject };
              setCurrentEntity(updatedEntity); // Aktualisiere den lokalen Zustand
          }
  };

  const handleSetSelectionChange = (selectedItemObject, index) => {
        if (fullSets && selectedItemObject)
        {
            let updatedSets = currentEntity.sets ? [...currentEntity.sets] : [];
            while (updatedSets.length <= index) {
                updatedSets.push(null); // Füge Standardwerte hinzu, um das Array zu erweitern
            }

            // Finden des ausgewählten Gegenstands anhand seiner UUID
            updatedSets[index] = selectedItemObject
            const updatedEntity = { ...currentEntity, sets: updatedSets };
            setCurrentEntity(updatedEntity);
        }
    };

  const handleTextChange = (e, type) => {
     const newText = e.target.value;
     const existingIndex = texts.findIndex(t => t.type === type && t.language === langCode);

     let updatedTexts = [...texts];
     if (existingIndex >= 0) {
       updatedTexts[existingIndex] = { ...updatedTexts[existingIndex], text: newText };
     } else {
       updatedTexts.push({ type, language: langCode, text: newText });
     }

     setTexts(updatedTexts); // Aktualisiere den Zustand der Texte
     const updatedEntity = { ...currentEntity, texts: updatedTexts };
     setCurrentEntity(updatedEntity);
   };

   // Hilfsfunktion, um den Wert eines bestimmten Texttyps zu finden
   const findTextValue = (type) => {
     const textObject = texts.find(t => t.type === type && t.language === langCode);
     return textObject ? textObject.text : '';
   };

  const handleTypeChange = (event) => {
      const newType = event.target.value;
      setType(newType);
  };

  function getUUIDs(entityArray) {
    // Überprüft, ob entityArray definiert ist und nicht null
    if (!entityArray) {
      return [];
    }

    // Extrahiert UUIDs aus dem Array von Objekten
    const uuids = entityArray.filter(uuid => uuid !== undefined && uuid !== null).map(item => item.uuid);

    // Entfernt undefined oder null Einträge
    return uuids.filter(uuid => uuid !== undefined && uuid !== null);
  }

  const handleSave = () => {

      const objectToAdd = {
        armor: currentEntity.armor?currentEntity.armor.uuid:null,
        metadata: {visibility: 'PRIVATE'},
        attributes: currentEntity.attributes,
        corps: currentEntity.corps,
        fraction: currentEntity.fraction.uuid,
        gameType: getUUIDs(currentEntity.gameType),
        items: getUUIDs(currentEntity.items),
        modelCommon: currentEntity.modelCommon,
        properties: getUUIDs(currentEntity.properties),
        sets: getUUIDs(currentEntity.sets),
        settings: getUUIDs(currentEntity.settings),
        texts: currentEntity.texts,
        type: currentEntity.type
      };
      const body = JSON.stringify(objectToAdd);

      setIsLoading(true);

      const uploadImage = async (id) => {
          try {
              let url = `${configModule.imageUrl()}/upload/${currentEntity.entityCommon.type}/${id}`;
              console.log("sending image for ID: ", id);

              const formData = new FormData();
              formData.append('file', imageFile); // Stelle sicher, dass dein Backend 'file' als Schlüssel erwartet

              const response = await fetch(url, {
                  method: 'POST',
                  headers: {
                      // 'Content-Type': 'multipart/form-data' wird automatisch gesetzt, daher nicht nötig
                      'Authorization': configModule.getAuthHeader(),
                  },
                  body: formData,
              });

              if (!response.ok) {
                  if (response.status === 401) {
                      configModule.clearUserAuth();
                      window.location.href = process.env.PUBLIC_URL + "/overview";
                      console.error("Authentication not ok or lost.");
                  }
                  throw new Error('Fehler beim Hochladen des Bildes: ' + response.status);
              }

              const data = await response.json(); // Wenn die Antwort JSON ist, kann sie hier ausgelesen werden
              //console.log('Upload success:', data);
              setImageFile(null);
              return true;
          } catch (error) {
              console.error('Error saving image:', error);
              return false;
          }
      };


     const saveEntity = async () => {
         try {
             let url = `${configModule.url()}/${currentEntity.entityCommon.type}`;
             console.log("sending new: ", objectToAdd);
             const body = JSON.stringify(objectToAdd);
             //console.log("sending body: ", body);

             const headers = new Headers({
                 'Authorization': configModule.getAuthHeader(),
                 'Content-Type': 'application/json' // Stelle sicher, dass der Content-Type korrekt ist
             });

             const response = await fetch(url, {
                 method: 'PUT',
                 headers: headers,
                 body: body,
             });

             if (!response.ok) {
                 if (response.status === 401) {
                     configModule.clearUserAuth();
                     window.location.href = process.env.PUBLIC_URL + "/overview";
                     console.error("Authentication not ok or lost.");
                 }
                 throw new Error('Fehler beim Speichern der Daten: ' + response.status);
             }

             const data = await response.json();
             //console.log("got added entity: ", data);

             //console.log("image file to store is: ", imageFile);
             if (imageFile) {
                 await uploadImage(data.uuid); // Warte, bis das Bild hochgeladen ist, bevor du fortfährst
             }

             setCurrentEntity({...currentEntity, uuid: data.uuid});
             setNewEntity(false);
             setIsLoading(false);
             return true;

         } catch (error) {
             console.error('Error saving entity:', error);
             setIsLoading(false);
             return false; // Gib einen Status zurück, um zu signalisieren, dass ein Fehler aufgetreten ist
         }
     };


      const updateEntity = async () => {
              try {
                 let url = `${configModule.url()}/${currentEntity.entityCommon.type}/${currentEntity.uuid}`;
                 console.log("sending update: ",objectToAdd)
                 //console.log("sending body: ",body)
                 const response = await fetch(url,
                    {
                      method: 'POST',
                      headers: headers,
                      body: body, // Füge den Body hier ein
                    }
                 );
                 if (!response.ok) {
                     if (response.status === 401) {
                       configModule.clearUserAuth();
                       window.location.href = process.env.PUBLIC_URL + "/overview";
                       console.error("Authentication not ok or lost.");
                     }
                     throw new Error('Fehler beim Speichern der Daten: ' + response.status);
                 }

                 const data = await response.json();
                 //console.log("got changed entity: ",data);

                 if (imageFile!=null)
                 {
                    await uploadImage(currentEntity.uuid); // Warte, bis das Bild hochgeladen ist, bevor du fortfährst
                 }

                 setIsLoading(false);
                 return true;

              } catch (error) {
                  console.error('Error saving entity:', error);
                  setIsLoading(false);
              }
        };
      if (newEntity)
      {
        console.log("saving: ",currentEntity);
        saveEntity();
      }
      else
      {
        console.log("updating: ",currentEntity);
        updateEntity();
      }
  }

  const handleDelete = () => {
       setIsLoading(true);
       let url = `${configModule.url()}/${currentEntity.entityCommon.type}/${currentEntity.uuid}`;
       const exists = async () => {
             const response = await fetch(url,
                {
                  method: 'HEAD', // Verwende POST oder PUT, je nach API-Anforderungen
                  headers: headers
                }
             );
            if (!response.ok) {
             if (response.status === 404) {
                setIsLoading(false);
                return true
             }
             else if (response.status === 401) {
                     configModule.clearUserAuth();
                     window.location.href = process.env.PUBLIC_URL + "/overview";
                     console.error("Authentication not ok or lost.");
             }
             setIsLoading(false);
             throw new Error('Fehler beim Laden der Daten: ' + response.status);
            }
            else{
                setIsLoading(false);
                return true;
            }
       };

       Promise.all([exists()]).then(([exists]) => {
            if (exists)
            {
                //console.log("entity existing, deleting: ",currentEntity.uuid);

                const deleteEntity = async () => {
                     const response = await fetch(url,
                         {
                           method: 'DELETE', // Verwende POST oder PUT, je nach API-Anforderungen
                           headers: headers
                         }
                      );
                     if (!response.ok) {
                      if (response.status === 404) {
                         setIsLoading(false);
                         return true
                      }
                      else if (response.status === 401) {
                          configModule.clearUserAuth();
                          window.location.href = process.env.PUBLIC_URL + "/overview";
                          console.error("Authentication not ok or lost.");
                      }
                      setIsLoading(false);
                      throw new Error('Fehler beim Laden der Daten: ' + response.status);
                     }
                     setIsLoading(false);
                     return true
               };
               deleteEntity();
            }
       })
       .catch(error => {
            setIsLoading(false);
            console.error('Fehler beim Löschen:', error);
       });
  }

  const handleAbort = () => {
      //console.log("aborting: ",currentEntity);
      onAbort();
  }

  const handleReset = () => {
      //console.log("resetting: ",currentEntity);
  }

  const handleEditImage = (image) => {
      //console.log("changing image");
      setImageFile(image);
      //console.log("new image: ",imageFile);
  }

  useEffect(() => {
          if (!isLoading)
          {
           const fetchPoints = async () => {
            let url = `${configModule.url()}/editor/points/UNIT`;
            //console.log("loading points with url: " + url);
            //setIsLoading(true)
            try {

              // Bereite den Body der Anfrage vor
              const deepClonedObject = JSON.parse(JSON.stringify(currentEntity));
              deepClonedObject.sets = deepClonedObject.sets.filter(function( element ) {
               return element !== null && element !== undefined;
              });

              const body = JSON.stringify(deepClonedObject);
              //console.log("sending: ",deepClonedObject)

              const response = await fetch(url, {
                 method: 'POST', // Verwende POST oder PUT, je nach API-Anforderungen
                 headers: headers,
                 body: body, // Füge den Body hier ein
              });

              //console.log("got response: ",response)

              if (!response.ok) {
                if (response.status === 401) {
                  configModule.clearUserAuth();
                  window.location.href = process.env.PUBLIC_URL + "/overview";
                  console.error("Authentication not ok or lost.");
                }
                throw new Error('Fehler beim Laden der Daten: ' + response.status);
              }
              const data = await response.json();
              const points = Number(data);
              //console.log('Fetched points:', points);
              setPoints(points);
              //setIsLoading(false);
            } catch (error) {
              console.error('Error fetching points:', error);
              //setIsLoading(false);
            }
          };

          fetchPoints();
       }
    }, [currentEntity]); // Abhängigkeiten zu useEffect hinzugefügt


  useEffect(() => {
    setIsLoading(true);
    const fetchItemsUrl = `${configModule.url()}/ITEM`;
    const fetchSetsUrl = `${configModule.url()}/SET`;
    const fetchPropertiesUrl = `${configModule.url()}/PROPERTY`;
    const fetchSettingsUrl = `${configModule.url()}/SETTINGS`;

    const fetchSettings = async () => {
      const response = await fetch(fetchSettingsUrl, { headers });
      if (!response.ok){
        setIsLoading(false);
        throw new Error('Fehler beim Laden der Settings');
      }
      return response.json();
    };


    const fetchItems = async () => {
      const response = await fetch(fetchItemsUrl, { headers });
      if (!response.ok){
        setIsLoading(false);
        throw new Error('Fehler beim Laden der Items');
      }
      return response.json();
    };

    const fetchSets = async () => {
      const response = await fetch(fetchSetsUrl, { headers });
      if (!response.ok){
        setIsLoading(false);
        throw new Error('Fehler beim Laden der Sets');
      }
      return response.json();
    };

    const fetchProperties = async () => {
      const response = await fetch(fetchPropertiesUrl, { headers });
      if (!response.ok){
        setIsLoading(false);
        throw new Error('Fehler beim Laden der Properties');
      }
      return response.json();
    };

    Promise.all([fetchSettings(),fetchItems(), fetchSets(), fetchProperties()]).then(([settingsData, itemsData, setsData, propertyData]) => {
      //console.log('Properties geladen:', propertyData);
      //console.log('Items geladen:', itemsData);
      //console.log('Sets geladen:', setsData);
      setSettings(settingsData);
      setProperties(propertyData);
      setItems(itemsData);
      setSets(setsData);
      // Hier kannst du deine Aktion ausführen, nachdem beide geladen sind,
      // zum Beispiel die Daten verarbeiten und im State speichern
      setIsLoading(false);
    })
    .catch(error => {
      setIsLoading(false);
      console.error('Fehler beim Laden der Daten:', error);
    });

  }, []); // Leeres Array, damit es nur einmal beim Initialisieren der Komponente aufgerufen wird


  function embedPropertiesInItems() {
    return items && items.map(item => ({
      ...item,
      properties: item.properties.map(propertyUuid =>
        properties.find(prop => prop.uuid === propertyUuid) || { uuid: propertyUuid, name: "???" }
      )
    }));
  }

  // Stellen Sie sicher, dass Sie warten, bis beide Daten geladen sind
  if (isLoading) {
    return <Wait/>;
  }

  const fullItems = embedPropertiesInItems();
  //console.log('Full Items geladen:', fullItems);

  // Funktion zum Einbetten der items in armors
  function embedItemsInSets() {
      const itemUpdatedSet = sets && sets.map(set => ({
        ...set,
        items: set.items.map(itemUUID =>
          fullItems.find(item => item.uuid === itemUUID)
      )}));
      return itemUpdatedSet && itemUpdatedSet.map(set => ({
                     ...set,
                     properties: set.properties.map(propertyUuid =>
                properties.find(prop => prop.uuid === propertyUuid) || { uuid: propertyUuid, name: "???" }
      )
    }));
  }

  const fullSets = embedItemsInSets();
  //console.log('Full Sets geladen:', fullSets);
  const singleItems = fullItems && fullItems.filter(item => (item.itemType === "ITEM" ||item.itemType === "NONE") );

  return (
      <div>
        {currentEntity && (
          <div className={style}>
              <div className='edit-actions'></div>
              <div className='editor-body'>
                {entity.entityCommon.type === "CHARACTER" ? (
                    <div className="unit-subtitle">{translate('gamelist.select.type.hero')}</div>
                ) : (
                    <div>
                        <select className="endDropdown" id="type-select" value={type} onChange={handleTypeChange}>
                            <option value="COMMANDER">{translate('gamelist.select.type.commander')}</option>
                            <option value="CHAMPION">{translate('gamelist.select.type.champion')}</option>
                            <option value="INFANTRY">{translate('gamelist.select.type.infantry')}</option>
                            <option value="SHOOTER">{translate('gamelist.select.type.shooter')}</option>
                        </select>
                    </div>
                )}
                <input
                  className="endText"
                  type="text"
                  value={findTextValue('NAME')}
                  onChange={(e) => handleTextChange(e, 'NAME')}
                  placeholder="NAME?"
                />
                <div className='unitshort-subtitle'>{fractionName}</div>
              </div>

              <div className='editor-fluff'>
                <textarea
                  rows="5"
                  className="endText fluff editor-fluff-text"
                  type="text"
                  value={findTextValue('FLUFF')}
                  onChange={(e) => handleTextChange(e, 'FLUFF')}
                  placeholder="Fluff?"
                />
              </div>

              <div className='editor-body-stats'>
                <AttributesVEdit attributes={currentEntity.attributes} browserLanguage={browserLanguage} styleToSet="" onUpdate={handleAttributesUpdate}/>
                  <ImageUploader handleEditImage={handleEditImage}
                    type={currentEntity.entityCommon.type}
                    id={currentEntity.uuid}
                    size={400}
                  />
                 <EntityCommonVEdit
                    attributes={currentEntity.attributes}
                    modelCommon={currentEntity.modelCommon}
                    browserLanguage={browserLanguage}
                    points={points}
                    styleToSet=""
                    onUpdateAttributes={handleAttributesUpdate}
                    onUpdateModelCommon={handleEntityCommonUpdate}
                 />
              </div>
              {
                isLoading ? (
                  <Wait/>
                ) : (
                  <div>
                    <EditorArmor currentEntity={currentEntity} sets={fullSets} langCode={langCode} handleChange={handleArmorSelectionChange} styleToSet="editor-body-amor"/>
                    <div className='editor-body-weaponsets'>
                        <EditorWeaponSet title={translate('edit.ws1')} sets={fullSets} selectedSets={selectedWeapons} position={0} langCode={langCode} handleChange={handleSetSelectionChange}/>
                        <EditorWeaponSet title={translate('edit.ws2')} sets={fullSets} selectedSets={selectedWeapons} position={1} langCode={langCode} handleChange={handleSetSelectionChange}/>
                    </div>
                    <EditorListEdit title={translate(`edit.items`)} titleSelected={translate(`edit.items.selected`)} items={singleItems} selected={currentEntity.items} langCode={langCode} handleAdd={handleItemAdd} handleDeletion={handleItemDeletion}/>
                    <EditorListEdit title={translate(`edit.properties`)} titleSelected={translate(`edit.properties.selected`)} items={properties} selected={currentEntity.properties} langCode={langCode} handleAdd={handlePropertyAdd} handleDeletion={handlePropertyDeletion}/>
                    <EditorListEdit title={translate(`edit.settings`)} titleSelected={translate(`edit.settings.selected`)} items={settings} selected={currentEntity.settings} langCode={langCode} handleAdd={handleSettingAdd} handleDeletion={handleSettingDeletion}/>

                    <Actionbar style="savebar" handleSave={handleSave} handleBack={onAbort} visibility={currentEntity.metadata.visibility} owner={currentEntity.metadata.owner}/>
                  </div>
                )
              }
          </div>
        )
        }
      </div>
    );
}

export default UnitRW;