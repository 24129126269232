// CookieUsage.js
import React, { useState, useEffect } from 'react';
import { useLanguage } from './LanguageContext';
import { Link } from 'react-router-dom'; // Importiere die Link-Komponente von react-router-dom

function CookieUsage() {
  const { translate } = useLanguage();
  const [privacyContent, setPrivacyContent] = useState('');

  useEffect(() => {
    const lang = navigator.language.split('-')[0];
    console.log('LANGUAGE (COOKIES)',lang);
    fetch(`./locales/cookies_${lang}.txt`)
    //fetch(`${process.env.PUBLIC_URL}/$contentFilePath`)
      .then((response) => response.text())
      .then((data) => setPrivacyContent(data))
      .catch((error) => console.error('Error loading cookie policy content:', error));
  }, []);

  return (
    <div className="cookies">
      <h1>{translate('cookies.title')}</h1>
      <div dangerouslySetInnerHTML={{ __html: privacyContent }} />
    </div>
  );
}

export default CookieUsage;