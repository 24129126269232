import './App.css';
import React, { useState, useEffect } from "react";
import { useLanguage } from './LanguageContext';
import Overview from './Overview';
import { Link, useNavigate } from 'react-router-dom';
import About from './About';

// WelcomePage.js
function WelcomePage({ onTermsAccept }) { // Die Funktion onTermsAccept sollte hier aus den Props extrahiert werden
  const { translate } = useLanguage();
  const [termsAccepted, setTermAccepted] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (termsAccepted) {
      // Nutzungsbedingungen wurden akzeptiert, leite zur Overview-Seite weiter
      navigate('/overview');
    }
  }, [termsAccepted, navigate]);

  const handleCheckboxChange = () => {
      onTermsAccept(true);
      setTermAccepted(true);
  };

  return (
    <div className="Welcome-Body">
      <div>
        <h1>{translate('welcome.title')}</h1>
        <img
              src={`${process.env.PUBLIC_URL}/phase_1_faded.png`}
              className="App-logo-small"
              alt="logo"
          />
        <h2>{translate('rules.title.chapter.1')}</h2>
        <About />

        <label>
          <input
            type="checkbox"
            checked={false}
            onChange={handleCheckboxChange}
          />
          {translate('welcome.termsOfUse.start')}
          <Link to="/termsofuse">{translate('welcome.termsOfUseLink')}</Link>
          {translate('welcome.termsOfUse.end')}
          {translate('welcome.cookies.start')}
          <Link to="/cookies">{translate('welcome.cookiesLink')}</Link>
          {translate('welcome.cookies.end')}
        </label>
      </div>
    </div>
  );
}

export default WelcomePage;
