import React, { useState } from "react";
import './Properties.css'; // Stil für die Box
import { useLanguage } from '../LanguageContext';
import Property from './Property'; // Importieren Sie die Box-Komponente
import Attributes from './Attributes';

function Properties({ properties, browserLanguage, styleToSet }) {
  //console.log("language: "+browserLanguage);
  const { translate } = useLanguage();
  //console.log("properties",properties);

  return (
    <div className="property-area">
      {properties && properties.length > 0 && (
        <div className="title">{translate('properties.title')}</div>
      )}
      {properties && properties.length > 0 && properties.map(item => (
          <Property key={item.uuid} property={item} browserLanguage={browserLanguage}/>
        ))}
    </div>
  );
}

export default Properties;
