// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.box {
  max-width:250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden; /* Blendet überflüssigen Inhalt aus */
}

.box img {
  max-width: 100%; /* Bild passt zur Breite der Box */
  margin: 5px;
}

.details-description {
  font-size: 18px; /* Ändern Sie die Schriftgröße nach Bedarf */
  line-height: 1.2; /* Ändern Sie den Zeilenabstand nach Bedarf */
  text-align:left;
  padding:20px;
}

.details-title {
  font-size: 22px; /* Ändern Sie die Schriftgröße nach Bedarf */
  line-height: 1.2; /* Ändern Sie den Zeilenabstand nach Bedarf */
  text-align:center;
  padding:20px;
}

.details-fluff {
  font-size: 18px; /* Ändern Sie die Schriftgröße nach Bedarf */
  line-height: 1.2; /* Ändern Sie den Zeilenabstand nach Bedarf */
  text-align:left;
  padding:20px;
  font-style: italic;
}`, "",{"version":3,"sources":["webpack://./src/components/Textfield.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,aAAa;EACb,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB,EAAE,qCAAqC;AACzD;;AAEA;EACE,eAAe,EAAE,kCAAkC;EACnD,WAAW;AACb;;AAEA;EACE,eAAe,EAAE,4CAA4C;EAC7D,gBAAgB,EAAE,6CAA6C;EAC/D,eAAe;EACf,YAAY;AACd;;AAEA;EACE,eAAe,EAAE,4CAA4C;EAC7D,gBAAgB,EAAE,6CAA6C;EAC/D,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,eAAe,EAAE,4CAA4C;EAC7D,gBAAgB,EAAE,6CAA6C;EAC/D,eAAe;EACf,YAAY;EACZ,kBAAkB;AACpB","sourcesContent":[".box {\n  max-width:250px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 20px;\n  margin-left: auto;\n  margin-right: auto;\n  overflow: hidden; /* Blendet überflüssigen Inhalt aus */\n}\n\n.box img {\n  max-width: 100%; /* Bild passt zur Breite der Box */\n  margin: 5px;\n}\n\n.details-description {\n  font-size: 18px; /* Ändern Sie die Schriftgröße nach Bedarf */\n  line-height: 1.2; /* Ändern Sie den Zeilenabstand nach Bedarf */\n  text-align:left;\n  padding:20px;\n}\n\n.details-title {\n  font-size: 22px; /* Ändern Sie die Schriftgröße nach Bedarf */\n  line-height: 1.2; /* Ändern Sie den Zeilenabstand nach Bedarf */\n  text-align:center;\n  padding:20px;\n}\n\n.details-fluff {\n  font-size: 18px; /* Ändern Sie die Schriftgröße nach Bedarf */\n  line-height: 1.2; /* Ändern Sie den Zeilenabstand nach Bedarf */\n  text-align:left;\n  padding:20px;\n  font-style: italic;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
