// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.property-area {
  margin:10px;
}


.property {
  border-top: 1px solid gray;
  border-bottom: 3px solid gray;
  margin:10px;
}

.title {
  font-size: 20px; /* Ändern Sie die Schriftgröße nach Bedarf */
  line-height: 1.2; /* Ändern Sie den Zeilenabstand nach Bedarf */
  text-align:center;
  padding:00px;
}

.rules {
   font-size: 16px; /* Ändern Sie die Schriftgröße nach Bedarf */
   display: flex;
   justify-content: left;
   align-items: left;
   flex-flow: row wrap;
   align-self: center;
   text-align:left;
   padding:10px;
}

.fluff {
  font-size: 16px; /* Ändern Sie die Schriftgröße nach Bedarf */
  line-height: 1.2; /* Ändern Sie den Zeilenabstand nach Bedarf */
  text-align:left;
  padding:10px;
  font-style: italic;
}`, "",{"version":3,"sources":["webpack://./src/components/Properties.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;;AAGA;EACE,0BAA0B;EAC1B,6BAA6B;EAC7B,WAAW;AACb;;AAEA;EACE,eAAe,EAAE,4CAA4C;EAC7D,gBAAgB,EAAE,6CAA6C;EAC/D,iBAAiB;EACjB,YAAY;AACd;;AAEA;GACG,eAAe,EAAE,4CAA4C;GAC7D,aAAa;GACb,qBAAqB;GACrB,iBAAiB;GACjB,mBAAmB;GACnB,kBAAkB;GAClB,eAAe;GACf,YAAY;AACf;;AAEA;EACE,eAAe,EAAE,4CAA4C;EAC7D,gBAAgB,EAAE,6CAA6C;EAC/D,eAAe;EACf,YAAY;EACZ,kBAAkB;AACpB","sourcesContent":[".property-area {\n  margin:10px;\n}\n\n\n.property {\n  border-top: 1px solid gray;\n  border-bottom: 3px solid gray;\n  margin:10px;\n}\n\n.title {\n  font-size: 20px; /* Ändern Sie die Schriftgröße nach Bedarf */\n  line-height: 1.2; /* Ändern Sie den Zeilenabstand nach Bedarf */\n  text-align:center;\n  padding:00px;\n}\n\n.rules {\n   font-size: 16px; /* Ändern Sie die Schriftgröße nach Bedarf */\n   display: flex;\n   justify-content: left;\n   align-items: left;\n   flex-flow: row wrap;\n   align-self: center;\n   text-align:left;\n   padding:10px;\n}\n\n.fluff {\n  font-size: 16px; /* Ändern Sie die Schriftgröße nach Bedarf */\n  line-height: 1.2; /* Ändern Sie den Zeilenabstand nach Bedarf */\n  text-align:left;\n  padding:10px;\n  font-style: italic;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
