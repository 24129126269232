// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.box {
  max-width:250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden; /* Blendet überflüssigen Inhalt aus */
}

.box img {
  max-width: 100%; /* Bild passt zur Breite der Box */
  margin: 5px;
}

.box p {
  font-size: 14px; /* Ändern Sie die Schriftgröße nach Bedarf */
  line-height: 1.2; /* Ändern Sie den Zeilenabstand nach Bedarf */
  text-align:left;
}

.box-title {
  font-size: 1em; /* Ändern Sie die Schriftgröße nach Bedarf */
  line-height: 1.2; /* Ändern Sie den Zeilenabstand nach Bedarf */
  align-self: center;
  max-width:30%;
  transform: rotate(270deg); /* Hier wird die Rotation um 90 Grad festgelegt */
  transform-origin: right; /* Ändern Sie den Ursprungspunkt der Rotation, falls gewünscht */
  white-space: nowrap;

}

.box-header {
  display: flex;
  flex-direction: row;
  align-items: left;
  border: none;
  padding: 10px;
}

.box-content {
  width:100%;
  margin: 5px;
   font-size: 14px; /* Ändern Sie die Schriftgröße nach Bedarf */
   line-height: 1.2; /* Ändern Sie den Zeilenabstand nach Bedarf */
   text-align:center;
}`, "",{"version":3,"sources":["webpack://./src/components/Box.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,aAAa;EACb,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB,EAAE,qCAAqC;AACzD;;AAEA;EACE,eAAe,EAAE,kCAAkC;EACnD,WAAW;AACb;;AAEA;EACE,eAAe,EAAE,4CAA4C;EAC7D,gBAAgB,EAAE,6CAA6C;EAC/D,eAAe;AACjB;;AAEA;EACE,cAAc,EAAE,4CAA4C;EAC5D,gBAAgB,EAAE,6CAA6C;EAC/D,kBAAkB;EAClB,aAAa;EACb,yBAAyB,EAAE,iDAAiD;EAC5E,uBAAuB,EAAE,gEAAgE;EACzF,mBAAmB;;AAErB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,iBAAiB;EACjB,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,UAAU;EACV,WAAW;GACV,eAAe,EAAE,4CAA4C;GAC7D,gBAAgB,EAAE,6CAA6C;GAC/D,iBAAiB;AACpB","sourcesContent":[".box {\n  max-width:250px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 20px;\n  margin-left: auto;\n  margin-right: auto;\n  overflow: hidden; /* Blendet überflüssigen Inhalt aus */\n}\n\n.box img {\n  max-width: 100%; /* Bild passt zur Breite der Box */\n  margin: 5px;\n}\n\n.box p {\n  font-size: 14px; /* Ändern Sie die Schriftgröße nach Bedarf */\n  line-height: 1.2; /* Ändern Sie den Zeilenabstand nach Bedarf */\n  text-align:left;\n}\n\n.box-title {\n  font-size: 1em; /* Ändern Sie die Schriftgröße nach Bedarf */\n  line-height: 1.2; /* Ändern Sie den Zeilenabstand nach Bedarf */\n  align-self: center;\n  max-width:30%;\n  transform: rotate(270deg); /* Hier wird die Rotation um 90 Grad festgelegt */\n  transform-origin: right; /* Ändern Sie den Ursprungspunkt der Rotation, falls gewünscht */\n  white-space: nowrap;\n\n}\n\n.box-header {\n  display: flex;\n  flex-direction: row;\n  align-items: left;\n  border: none;\n  padding: 10px;\n}\n\n.box-content {\n  width:100%;\n  margin: 5px;\n   font-size: 14px; /* Ändern Sie die Schriftgröße nach Bedarf */\n   line-height: 1.2; /* Ändern Sie den Zeilenabstand nach Bedarf */\n   text-align:center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
