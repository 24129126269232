import React, { useState } from "react";
import '../App.css'; // Stil für die Box
import './FAQ.css'; // Stil für die Box
import { useLanguage } from '../LanguageContext';
import Textfield from './Textfield'; // Importieren Sie die Box-Komponente
import Image from './Image';

function FAQ({ faq, browserLanguage }) {
  const { translate } = useLanguage();


  // Deklaration der Variablen außerhalb der JSX-Klammern
  let title = "";
  let question = "";
  let answer = "";
  let styleToSet = "";

  if (faq) {
    // Berechnung von missionName, wenn mission vorhanden ist
    title = faq.texts.find((text) => text.type === "NAME" && text.language === browserLanguage)?.text  || "";
    question = faq.texts.find((text) => text.type === "QUESTION" && text.language === browserLanguage)?.text  || "";
    answer = faq.texts.find((text) => text.type === "ANSWER" && text.language === browserLanguage)?.text  || "";
  }

  return (
      <>
        {faq && (
          <div className="faqitem">
            <p className="faqtitle">{title}</p>
            <p className="faqquestion">{question}</p>
            <p className="faqanswer">{answer}</p>
          </div>
        )}
      </>
    );
}

export default FAQ;
