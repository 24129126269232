// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `li {
  max-width: 100%; /* Bild passt zur Breite der Box */
  margin: 5px;
  text-align: left;
}

.entity-description {
  font-size: 16px; /* Ändern Sie die Schriftgröße nach Bedarf */
  line-height: 1.2; /* Ändern Sie den Zeilenabstand nach Bedarf */
  text-align:left;
  padding:0px;
}

.set-title {
  font-size: 16px; /* Ändern Sie die Schriftgröße nach Bedarf */
  line-height: 1.2; /* Ändern Sie den Zeilenabstand nach Bedarf */
  text-align:center;
  padding:00px;
}

.miniature-image{
}

.set {
  border-style:solid;
  border-color:gray;
  margin:10px;
}

.set-body {
   display: flex;
   justify-content: center;
   align-items: center;
   flex-flow: row wrap;
   align-self: center;
}

.itemlist {
   display: flex;
   justify-content: center;
   width:100%;
   align-items: center;
   flex-flow: row wrap;
   align-self: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Set.css"],"names":[],"mappings":"AAAA;EACE,eAAe,EAAE,kCAAkC;EACnD,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,eAAe,EAAE,4CAA4C;EAC7D,gBAAgB,EAAE,6CAA6C;EAC/D,eAAe;EACf,WAAW;AACb;;AAEA;EACE,eAAe,EAAE,4CAA4C;EAC7D,gBAAgB,EAAE,6CAA6C;EAC/D,iBAAiB;EACjB,YAAY;AACd;;AAEA;AACA;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,WAAW;AACb;;AAEA;GACG,aAAa;GACb,uBAAuB;GACvB,mBAAmB;GACnB,mBAAmB;GACnB,kBAAkB;AACrB;;AAEA;GACG,aAAa;GACb,uBAAuB;GACvB,UAAU;GACV,mBAAmB;GACnB,mBAAmB;GACnB,kBAAkB;AACrB","sourcesContent":["li {\n  max-width: 100%; /* Bild passt zur Breite der Box */\n  margin: 5px;\n  text-align: left;\n}\n\n.entity-description {\n  font-size: 16px; /* Ändern Sie die Schriftgröße nach Bedarf */\n  line-height: 1.2; /* Ändern Sie den Zeilenabstand nach Bedarf */\n  text-align:left;\n  padding:0px;\n}\n\n.set-title {\n  font-size: 16px; /* Ändern Sie die Schriftgröße nach Bedarf */\n  line-height: 1.2; /* Ändern Sie den Zeilenabstand nach Bedarf */\n  text-align:center;\n  padding:00px;\n}\n\n.miniature-image{\n}\n\n.set {\n  border-style:solid;\n  border-color:gray;\n  margin:10px;\n}\n\n.set-body {\n   display: flex;\n   justify-content: center;\n   align-items: center;\n   flex-flow: row wrap;\n   align-self: center;\n}\n\n.itemlist {\n   display: flex;\n   justify-content: center;\n   width:100%;\n   align-items: center;\n   flex-flow: row wrap;\n   align-self: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
