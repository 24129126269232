import React, { useEffect, useState } from "react";
import './BoxList.css';
import { Link } from 'react-router-dom';
import configModule from '../config';
import Image from './Image';
import Actionbar from './Actionbar';
import { useLanguage } from '../LanguageContext';

function BoxList({ icon, title, typeToLoad, imageType, handleAdd, linkType }) {
  const { translate } = useLanguage();
  const [dataList, setDataList] = useState([]);
  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const langCode = navigator.language.split('-')[0].toUpperCase();

  useEffect(() => {
    const fetchData = async () => {
      const dataUrl = `${configModule.url()}/${typeToLoad}`;

      try {
        setIsLoading(true);
        setError(null);

        const headers = new Headers({
          'Accept': 'application/json',
          'Authorization': configModule.getAuthHeader()
        });

        // Fetch all data
        const dataResponse = await fetch(dataUrl, { headers });
        if (!dataResponse.ok) {
          if (dataResponse.status === 401) {
            configModule.clearUserAuth();
            window.location.href = process.env.PUBLIC_URL + "/overview";
            console.error("Authentication not ok or lost.");
          }
          throw new Error('Fehler beim Laden der Daten: ' + dataResponse.status);
        }
        let data = await dataResponse.json();

        // Sortiere die Daten nach metadata.created in absteigender Reihenfolge
        data = data.sort((a, b) => b.metadata.creation - a.metadata.creation);

        setDataList(data);

      } catch (error) {
        setError(error.message);
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [typeToLoad]);

  const handlePageSizeChange = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(0); // Zurück zur ersten Seite, wenn die Page-Size geändert wird
  };

  const handleNextPage = () => {
    if ((currentPage + 1) * pageSize < dataList.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const findTitleByLanguage = (titles) => {
    const titleObject = titles && titles.find(title => title.language === langCode && title.type === "NAME");
    return titleObject ? titleObject.text : "?";
  };

  // Berechne die Elemente für die aktuelle Seite
  const paginatedData = dataList.slice(currentPage * pageSize, (currentPage + 1) * pageSize);

  return (
    <div className="box-list">
      <div className="box-list-header">
        {icon && <div>{icon}</div>}
        <div className="box-list-title">{title}</div>
      </div>

      <div className="box-list-table-header">
         <div className="entry-count">{translate('pagination.count')} {dataList.length}</div>
         <Actionbar style="actionbar" handleAdd={handleAdd} />
      </div>

      {isLoading && <p>Lade Daten...</p>}
      {error && <p className="error-message">{error}</p>}
      <div className="box-list-table">
        {!isLoading && !error && paginatedData && paginatedData.map(item => (
          <div className="box-list-entry" key={item.uuid}>
            <Link to={`/${linkType}/${item.uuid}`}>
              <Image
                classNameIn={"icon-image"}
                type={imageType.toUpperCase()}
                id={item.uuid}
                size={50}
                title={""} />
              <div className="box-list-entry-text">{findTitleByLanguage(item.texts)}</div>
            </Link>
          </div>
        ))}
      </div>
      {dataList.length > 5 && (
        <div className="pagination-controls">
        <div>
            <label htmlFor="pageSize">{translate('pagination.entriesperpage')}</label>
            <select className="pagination-dropbox" id="pageSize" value={pageSize} onChange={handlePageSizeChange}>
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
            </select>
        </div>
        <div>
          <button className="pagination-button" onClick={handlePreviousPage} disabled={currentPage === 0}>
            {translate('pagination.back')}
          </button>
          <span>{translate('pagination.page')} {currentPage + 1} {translate('pagination.of')} {Math.ceil(dataList.length / pageSize)}</span>
          <button className="pagination-button" onClick={handleNextPage} disabled={(currentPage + 1) * pageSize >= dataList.length}>
            {translate('pagination.forward')}
          </button>
        </div>
        </div>
      )}
    </div>
  );
}

export default BoxList;
