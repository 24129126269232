import React, { useState } from "react";
import './Property.css'; // Stil für die Box
import { useLanguage } from '../LanguageContext';
import Attributes from './Attributes'; // Importieren Sie die Box-Komponente

function Property({ property, browserLanguage }) {
  //console.log("language: "+browserLanguage);
  //console.log("loading property",property)
  const { translate } = useLanguage();

  // Deklaration der Variablen außerhalb der JSX-Klammern
  let entityName = "Unbekannt";

  if (property) {
    // Berechnung von entityName, wenn entity vorhanden ist
    entityName = property.texts.find((text) => text.type === "NAME" && text.language === browserLanguage)?.text || entityName;
  }

  return (
      <div className="property">
        {property && (
           <div key={property.uuid} >
              <div className="property-title">
                {property.texts && property.texts.find((text) => text.type === "NAME" && text.language === browserLanguage)?.text}
              </div>
              <div className="fluff">
                {property.texts && property.texts.find((text) => text.type === "FLUFF" && text.language === browserLanguage)?.text}
              </div>
              <div className="property-rules">
                {property.texts && property.texts.find((text) => text.type === "RULES" && text.language === browserLanguage)?.text}
              </div>
              {
                  property.attributes && (
                      <Attributes attributes={property.attributes} browserLanguage={browserLanguage}/>
                  )
              }
            </div>
        )}
      </div>
    );
}

export default Property;
