import './NPCList.css';
import { useLanguage } from '../LanguageContext';
import React, { useState,useEffect } from "react";

 function mapType( type, translate ) {
      console.log("type: "+type);
      let translatedType = type; // Standard: Der Typ wird nicht übersetzt
      //INFANTRY,COMMANDER,SHOOTER,FIGHTER,CAVALRY,ARTILLERY,SHOOTING_CAVALRY
      if (type === "ON_HIT") {
        translatedType = translate('ki.type.onhit');
      } else if (type === "ON_ENEMY_NEAR") {
        translatedType = translate('ki.type.onenemynear');
      }
      else translatedType = ""
      return translatedType;
}

function NPCKI( { ki, browserLanguage } ) {
  const { translate } = useLanguage();

 return (
   <React.Fragment>
     {(
       ki && (
         <div className="ki">
            <div className="ki-title">{translate("ki.title")}</div>
            <table>
             <tbody>
               {ki.actions &&
                ki.actions.length > 0 &&
                ki.actions.map((action) => {
                  const trigger = mapType( action.trigger, translate )
                  return (
                    <React.Fragment key={action.uuid}>
                      <tr>
                        <td className='ki-trigger'>{trigger}</td>
                        <td className='ki-name'>{action.texts.find((text) => text.type === "NAME" && text.language === browserLanguage)?.text}</td>
                        <td className='ki-condition'>{action.texts.find((text) => text.type === "KICONDITION" && text.language === browserLanguage)?.text}</td>
                        <td className='ki-reaction'>{action.texts.find((text) => text.type === "KIREACTION" && text.language === browserLanguage)?.text}</td>
                      </tr>
                    </React.Fragment>
                  );
                })}
             </tbody>
            </table>
         </div>
       )
     )}
   </React.Fragment>
 );
}

export default NPCKI;