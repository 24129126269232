import React from "react";
import './EventList.css'; // Stil für die Box
import { useLanguage } from '../LanguageContext';



function EventList({ title, events, browserLanguage }) {
  const { translate } = useLanguage();

  const getEventText = (event) => {
      if (event.trigger === "MARKER") {
        // Wenn event.trigger "MARKER" ist, den Spracheintrag für "event.marker" zurückgeben
        return translate("event.marker")
      } else if (event.trigger === "ROUND") {
        // Wenn event.trigger "ROUND" ist, den Spracheintrag für "event.round" zurückgeben
        return translate("event.round")
      }
      // Wenn keine Übereinstimmung gefunden wurde, leere Zeichenkette zurückgeben
      return "";
    };

  return (
    <div className='event'>
      <h4>{title}</h4>
      <table>
       <tbody>
        {events.map((event) => {
          const title = event.texts.find( (text) => text.type === "NAME" && text.language === browserLanguage );
          const titleTxt =
                      title?.text || event.texts.find((text) => text.type === "NAME")?.text || "Unbekannt";

          const rule = event.texts.find( (text) => text.type === "RULES" && text.language === browserLanguage );
          const ruleTxt =
                      rule?.text || event.texts.find((text) => text.type === "RULES")?.text || "Unbekannt";

          const desc = event.texts.find( (text) => text.type === "DESCRIPTION" && text.language === browserLanguage );
          const descTxt =
                      desc?.text || event.texts.find((text) => text.type === "DESCRIPTION")?.text || "Unbekannt";

          return (
            <tr key={event.uuid}>
              <td className='event-title'> {titleTxt} </td>
              <td className='event-trigger'> {getEventText(event)} </td>
              <td className='event-value'> {event.triggerValue} </td>
              <td className='event-description'> {descTxt} </td>
              <td className='event-rule'> {ruleTxt} </td>
            </tr>
          );
        })}
       </tbody>
      </table>
    </div>
  );
}

export default EventList;
