// Imprint.js
import './Help.css';
import React, { useState, useEffect } from 'react';
import { useLanguage } from './LanguageContext';
import FAQ from './components/FAQ';
import { Link } from 'react-router-dom'; // Importiere die Link-Komponente von react-router-dom
import Wait from './components/Wait'; // Importieren Sie die Box-Komponente
import configModule from './config';

function Help() {
  const { translate } = useLanguage();

  const [isLoading, setIsLoading] = useState(true);
  const [ list, setList ] = useState(null);
  const url = configModule.url()+"/FAQS"
  const langCode = navigator.language.split('-')[0].toUpperCase();
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    // Hier sollte der REST-Call stattfinden und die Daten in dataList gespeichert werden
    const fetchData = async () => {
      try {
            const headers = new Headers({
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'lang': langCode, // Füge den Sprachcode als Header hinzu
              'Authorization': configModule.getAuthHeader()
            });

        const response = await fetch(url, { headers });
        // console.log(response)
        if (!response.ok) {
                  if (response.status===401) {
                        configModule.clearUserAuth()
                        window.location.href = process.env.PUBLIC_URL+"/overview"
                        console.error("Authentication not ok or lost.")
                  }
                  throw new Error('Fehler beim Laden der Daten: '+response.status);
                }
        const data = await response.json();
        console.log('Fetched data:', data); // Konsolenausgabe der empfangenen Daten
        setList(data); // Daten im Zustand speichern
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [url]);

    const filteredList = list && list.filter(faq =>
      faq.texts.some(faqText => faqText.text.toLowerCase().includes(searchTerm.toLowerCase()))
    );


  return (
    <div className="help">
      <h1>{translate('help.title')}</h1>
      <h2>{translate('help.icons.title')}</h2>
      <table className="help_icontable">
          <tr>
              <td className="help_tablecell"><img className="ec-image" src={`${process.env.PUBLIC_URL}/icons/attributes/movement.png`} alt="movement" /></td>
              <td className="help_tablecell">{translate('help.icon.movement')}</td>
              <td className="help_tablecell">{translate('help.icon.movement.desc')}</td>
          </tr>
          <tr>
              <td className="help_tablecell"><img className="ec-image" src={`${process.env.PUBLIC_URL}/icons/attributes/armor.png`} alt="armor" /></td>
              <td className="help_tablecell">{translate('help.icon.armor')}</td>
              <td className="help_tablecell">{translate('help.icon.armor.desc')}</td>
          </tr>
          <tr>
              <td className="help_tablecell"><img className="ec-image" src={`${process.env.PUBLIC_URL}/icons/attributes/fighting.png`} alt="fighting" /></td>
              <td className="help_tablecell">{translate('help.icon.fighting')}</td>
              <td className="help_tablecell">{translate('help.icon.fighting.desc')}</td>
          </tr>
          <tr>
              <td className="help_tablecell"><img className="ec-image" src={`${process.env.PUBLIC_URL}/icons/attributes/shooting.png`} alt="shooting" /></td>
              <td className="help_tablecell">{translate('help.icon.shooting')}</td>
              <td className="help_tablecell">{translate('help.icon.shooting.desc')}</td>
          </tr>
          <tr>
              <td className="help_tablecell"><img className="ec-image" src={`${process.env.PUBLIC_URL}/icons/attributes/magic.png`} alt="magic" /></td>
              <td className="help_tablecell">{translate('help.icon.magic')}</td>
              <td className="help_tablecell">{translate('help.icon.magic.desc')}</td>
          </tr>
          <tr>
              <td className="help_tablecell"><img className="ec-image" src={`${process.env.PUBLIC_URL}/icons/attributes/moral.png`} alt="moral" /></td>
              <td className="help_tablecell">{translate('help.icon.moral')}</td>
              <td className="help_tablecell">{translate('help.icon.moral.desc')}</td>
          </tr>
      </table>
      <table className="help_icontable">
        <tr>
            <td className="help_tablecell"><img className="ec-image" src={`${process.env.PUBLIC_URL}/icons/generic_icons/info.png`} alt="info" /></td>
            <td className="help_tablecell">{translate('help.icon.info')}</td>
            <td className="help_tablecell">{translate('help.icon.info.desc')}</td>
        </tr>
        <tr>
            <td className="help_tablecell"><img className="ec-image" src={`${process.env.PUBLIC_URL}/icons/attributes/size.png`} alt="size" /></td>
            <td className="help_tablecell">{translate('help.icon.size')}</td>
            <td className="help_tablecell">{translate('help.icon.size.desc')}</td>
        </tr>
        <tr>
            <td className="help_tablecell"><img className="ec-image" src={`${process.env.PUBLIC_URL}/icons/attributes/gold_cost.png`} alt="gold cost" /></td>
            <td className="help_tablecell">{translate('help.icon.cost')}</td>
            <td className="help_tablecell">{translate('help.icon.cost.desc')}</td>
        </tr>
        <tr>
            <td className="help_tablecell"><img className="ec-image" src={`${process.env.PUBLIC_URL}/icons/attributes/HP.png`} alt="hit points" /></td>
            <td className="help_tablecell">{translate('help.icon.hp')}</td>
            <td className="help_tablecell">{translate('help.icon.hp.desc')}</td>
        </tr>
        <tr>
            <td className="help_tablecell"><img className="ec-image" src={`${process.env.PUBLIC_URL}/icons/attributes/alignment.png`} alt="alignment" /></td>
            <td className="help_tablecell">{translate('help.icon.alignment')}</td>
            <td className="help_tablecell">{translate('help.icon.alignment.desc')}</td>
        </tr>
        <tr>
            <td className="help_tablecell"><img className="ec-image" src={`${process.env.PUBLIC_URL}/icons/action_icons/items.png`} alt="items" /></td>
            <td className="help_tablecell">{translate('help.icon.items')}</td>
            <td className="help_tablecell">{translate('help.icon.items.desc')}</td>
        </tr>
      </table>
       <table className="help_icontable">
              <tr>
                  <td className="help_tablecell"><img className="ec-image" src={`${process.env.PUBLIC_URL}/icons/corps/head.png`} alt="head" /></td>
                  <td className="help_tablecell">{translate('help.icon.head')}</td>
                  <td className="help_tablecell">{translate('help.icon.head.desc')}</td>
              </tr>
              <tr>
                  <td className="help_tablecell"><img className="ec-image" src={`${process.env.PUBLIC_URL}/icons/corps/torso.png`} alt="torso" /></td>
                  <td className="help_tablecell">{translate('help.icon.torso')}</td>
                  <td className="help_tablecell">{translate('help.icon.torso.desc')}</td>
              </tr>
              <tr>
                  <td className="help_tablecell"><img className="ec-image" src={`${process.env.PUBLIC_URL}/icons/corps/hands.png`} alt="hands" /></td>
                  <td className="help_tablecell">{translate('help.icon.hands')}</td>
                  <td className="help_tablecell">{translate('help.icon.hands.desc')}</td>
              </tr>
              <tr>
                  <td className="help_tablecell"><img className="ec-image" src={`${process.env.PUBLIC_URL}/icons/corps/foot.png`} alt="foot" /></td>
                  <td className="help_tablecell">{translate('help.icon.foot')}</td>
                  <td className="help_tablecell">{translate('help.icon.foot.desc')}</td>
              </tr>
              <tr>
                  <td className="help_tablecell"><img className="ec-image" src={`${process.env.PUBLIC_URL}/icons/corps/tail.png`} alt="tail" /></td>
                  <td className="help_tablecell">{translate('help.icon.tail')}</td>
                  <td className="help_tablecell">{translate('help.icon.tail.desc')}</td>
              </tr>
       </table>
       <h2>{translate('help.range.title')}</h2>
       <div className="help_range_desc">{translate('help.range.desc.1')}</div>
       <table className="help_icontable">
            <tr>
                <td className="">[</td>
                <td className="range_color1">0</td>
                <td className="">-</td>
                <td className="range_color2">15</td>
                <td className="">]</td>
                <td className="">-</td>
                <td className="range_color3">30</td>
                <td className="">|</td>
                <td className="range_color4">1</td>
                <td className="">/</td>
                <td className="range_color5">2</td>
            </tr>
       </table>
       <div className="help_range_desc">{translate('help.range.desc.2')}</div>
       <table className="help_icontable">
         <tr>
             <td >[</td>
             <td className="help_tablecell range_color1">{translate('help.range.min')}</td>
             <td className="help_tablecell">-</td>
             <td className="help_tablecell range_color2">{translate('help.range.mid')}</td>
             <td className="help_tablecell">]</td>
             <td className="help_tablecell">-</td>
             <td className="help_tablecell range_color3">{translate('help.range.max')}</td>
             <td className="help_tablecell">|</td>
             <td className="help_tablecell range_color4">{translate('help.range.bonus.short')}</td>
             <td className="help_tablecell">/</td>
             <td className="help_tablecell range_color5">{translate('help.range.bonus.long')}</td>
         </tr>
       </table>
       <div className="help_range_desc">{translate('help.range.desc.3')}</div>

       <div className="help_range_desc">{translate('help.range.desc.4')}</div>
          <table className="help_icontable">
               <tr>
                   <td className="range_color1">0</td>
                   <td className="">-</td>
                   <td className="range_color3">1</td>
                   <td className="">|</td>
                   <td className="range_color4">3</td>
               </tr>
          </table>
          <div className="help_range_desc">{translate('help.range.desc.5')}</div>
          <table className="help_icontable">
            <tr>
                <td className="help_tablecell range_color1">{translate('help.range.min')}</td>
                <td className="help_tablecell">-</td>
                <td className="help_tablecell range_color3">{translate('help.range.max')}</td>
                <td className="help_tablecell">|</td>
                <td className="help_tablecell range_color4">{translate('help.range.bonus.shortlong')}</td>
            </tr>
          </table>
          <div className="help_range_desc">{translate('help.range.desc.6')}</div>

       <h2>{translate('help.faq.title')}</h2>
       {
         isLoading ? (
           <Wait />
         ) : (
           <>
             <input
               className="searchFieldFAQ"
               type="text"
               placeholder={translate('search.field')}
               value={searchTerm}
               onChange={(e) => setSearchTerm(e.target.value)}
               style={{ display: isLoading ? 'none' : 'block' }} // Suchfeld basierend auf isLoading anzeigen oder verbergen
             />
             {filteredList.map((faq) => (
               <FAQ key={faq.id} faq={faq} browserLanguage={langCode} />
             ))}
           </>
         )
       }

    </div>
  );
}

export default Help;