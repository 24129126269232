// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.faqitem {
  font-size: 18px; /* Ändern Sie die Schriftgröße nach Bedarf */
  line-height: 1.2; /* Ändern Sie den Zeilenabstand nach Bedarf */
  text-align:left;
  margin: 5px;
  padding:00px;
  border-style:solid;
  border-color:#333333;
}

.faqtitle {
  font-size: 18px; /* Ändern Sie die Schriftgröße nach Bedarf */
  line-height: 1.2; /* Ändern Sie den Zeilenabstand nach Bedarf */
  text-align:left;
  padding:00px;
  margin:5px;
}

.faqquestion {
  font-size: 16px; /* Ändern Sie die Schriftgröße nach Bedarf */
  line-height: 1.2; /* Ändern Sie den Zeilenabstand nach Bedarf */
  text-align:left;
  padding:00px;
  margin:5px;
}

.faqanswer {
  font-size: 15px; /* Ändern Sie die Schriftgröße nach Bedarf */
  line-height: 1.2; /* Ändern Sie den Zeilenabstand nach Bedarf */
  text-align:left;
  padding-left: 20px;
  font-style:italic;
  margin:5px;
}`, "",{"version":3,"sources":["webpack://./src/components/FAQ.css"],"names":[],"mappings":";AACA;EACE,eAAe,EAAE,4CAA4C;EAC7D,gBAAgB,EAAE,6CAA6C;EAC/D,eAAe;EACf,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,oBAAoB;AACtB;;AAEA;EACE,eAAe,EAAE,4CAA4C;EAC7D,gBAAgB,EAAE,6CAA6C;EAC/D,eAAe;EACf,YAAY;EACZ,UAAU;AACZ;;AAEA;EACE,eAAe,EAAE,4CAA4C;EAC7D,gBAAgB,EAAE,6CAA6C;EAC/D,eAAe;EACf,YAAY;EACZ,UAAU;AACZ;;AAEA;EACE,eAAe,EAAE,4CAA4C;EAC7D,gBAAgB,EAAE,6CAA6C;EAC/D,eAAe;EACf,kBAAkB;EAClB,iBAAiB;EACjB,UAAU;AACZ","sourcesContent":["\n.faqitem {\n  font-size: 18px; /* Ändern Sie die Schriftgröße nach Bedarf */\n  line-height: 1.2; /* Ändern Sie den Zeilenabstand nach Bedarf */\n  text-align:left;\n  margin: 5px;\n  padding:00px;\n  border-style:solid;\n  border-color:#333333;\n}\n\n.faqtitle {\n  font-size: 18px; /* Ändern Sie die Schriftgröße nach Bedarf */\n  line-height: 1.2; /* Ändern Sie den Zeilenabstand nach Bedarf */\n  text-align:left;\n  padding:00px;\n  margin:5px;\n}\n\n.faqquestion {\n  font-size: 16px; /* Ändern Sie die Schriftgröße nach Bedarf */\n  line-height: 1.2; /* Ändern Sie den Zeilenabstand nach Bedarf */\n  text-align:left;\n  padding:00px;\n  margin:5px;\n}\n\n.faqanswer {\n  font-size: 15px; /* Ändern Sie die Schriftgröße nach Bedarf */\n  line-height: 1.2; /* Ändern Sie den Zeilenabstand nach Bedarf */\n  text-align:left;\n  padding-left: 20px;\n  font-style:italic;\n  margin:5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
