import React from 'react';
import './ScenarioDetails.css'; // Stil für den Inhalt
import { useLanguage } from '../LanguageContext';

function ScenarioDetails({ objectData }) {
  const { translate } = useLanguage();

  return (
    <div className="scenario-container">
      {objectData && (
        <>
          <div className="scenario-value">
            {objectData.type === "SEMICOOPERATIVE"
              ? translate('sd.semicoop')
              : objectData.type === "COOPERATIVE"
              ? translate('sd.coop')
              : objectData.type === "COMPETETIVE"
              ? translate('sd.comp')
              : ""}
          </div>
          <div className="scenario-label">{translate('sd.player')}</div>
          <div className="scenario-value">
            {objectData.minimumPlayers ? objectData.minimumPlayers : 0} -{' '}
            {objectData.maximumPlayers ? objectData.maximumPlayers : 2}
          </div>
          <div className="scenario-label">{translate('sd.points')}</div>
          <div className="scenario-value">{objectData.points ? objectData.points : "-"}</div>
          <div className="scenario-value">{objectData.twisted ? translate('sd.twisted') : ""}</div>
        </>
      )}
    </div>
  );
}

export default ScenarioDetails;
