// App.js

import React, { useState, useEffect  } from "react";
import './App.css';
import { LanguageProvider } from './LanguageContext';

import ErrorBoundary from './ErrorBoundary';
import configModule from './config';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import ErrorPage from './ErrorPage'; // Importiere die Fehlerseite
import WelcomePageLoading from './WelcomePageLoading';
import ComingSoonPage from './ComingSoonPage';
import NewsPage from './NewsPage';
import WelcomePage from './WelcomePage';
import Overview from './Overview';
import RulesPage from './RulesPage';
import CSM from './CSM';
import FU from './FU';
import CampaignDetail from './CampaignDetail';
import GameListDetail from './GameListDetail';
import ScenarioDetail from './ScenarioDetail';
import ScenarioDetailPrintMission from './ScenarioDetailPrintMission';
import MissionDetail from './MissionDetail';
import UnitDetail from './UnitDetail';
import FractionDetail from './FractionDetail';
import CharacterDetail from './CharacterDetail';
import Story from './Story';
import Footer from './Footer';
import Help from './Help';
import Header from './Header';
import Navigation from './components/Navigation';
import CreditsImprint from './CreditsImprint';
import Imprint from './Imprint';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfUse from './TermsOfUse';
import CookieUsage from './CookieUsage';
import Credits from './Credits';
import ListEditor from './ListEditor';
import UnitEditor from './UnitEditor';

// Check if terms have been accepted
function areTermsAccepted() {
  const termsAccepted = configModule.config.termsAccepted
  console.log("check terms accepted: "+termsAccepted);
  return termsAccepted === true;
}

function App() {

  console.log("BFF from ENV " + process.env.REACT_APP_BFF_URL);
  console.log("using bff from config " + configModule.url());
  console.log("PUBLIC URL set to " + process.env.PUBLIC_URL);
  console.log("using bff from config try 2" + configModule.url());
  const url = configModule.baseUrl()+"/base/info"
  console.log("checkung url: " + url);

  if (!configModule.isSettingStored()) {
      configModule.storeSettings()
  }
  else configModule.loadSettings()

  console.log("BFF from ENV " + process.env.REACT_APP_BFF_URL);
  console.log("using bff from config " + configModule.url());
  console.log("PUBLIC URL set to " + process.env.PUBLIC_URL);

  if (configModule.config.lang==="") {
      console.info('LANGUAGE',navigator.language);
      configModule.config.lang = navigator.language.split('-')[0]
      configModule.storeSettings()
  }

  const [connectionStatus, setConnectionStatus] = useState('loading');
  let [termsAccepted, setTermAccepted] = useState(false);

  // Callback-Funktion, um termsAccepted zu aktualisieren
  const handleTermsAcceptance = (accepted) => {
     configModule.config.termsAccepted = accepted
     configModule.storeSettings()
     console.log("callback terms accepted: "+termsAccepted);
     window.location.reload();
  };

  useEffect(() => {
      if (configModule.config.live) {

        // Führe die Versionsabfrage beim Laden der Komponente aus
        fetch(url) // Passe den Endpunkt an, um die Version vom BFF abzurufen
          .then((response) => {
            if (!response.ok) {
              setConnectionStatus('error'); // Setze den Status auf "error", wenn die Verbindung nicht erfolgreich ist
              throw new Error('Das Backend ist vorübergehend nicht erreichbar.');
            }
            setConnectionStatus('success'); // Setze den Status auf "success", wenn die Verbindung erfolgreich ist
          })
          .catch((error) => {
            console.error('Error checking connection:', error);
            setConnectionStatus('error'); // Setze den Status auf "error", wenn ein Fehler auftritt
          });
      }
  }, []);


  // Zeige die Fehlerseite, wenn die Verbindung nicht erfolgreich ist
  if (connectionStatus === 'error') {
    return <LanguageProvider> <ErrorPage /> </LanguageProvider>;
  }

  console.log("BFF from ENV " + process.env.REACT_APP_BFF_URL);
  console.log("using bff from config " + configModule.url());
  console.log("PUBLIC URL set to " + process.env.PUBLIC_URL);

  if (configModule.config.live) { console.log("live ist wahr."); } else { console.log("live ist falsch."); }
  if (configModule.config.loggedIn) { console.log("logged in ist wahr."); } else { console.log("logged in ist falsch."); }

  return (
    <LanguageProvider>
      <ErrorBoundary>
        {!configModule.config.live ? (
            <NewsPage />
        ) : (
          connectionStatus === 'loading' ? (
            <WelcomePageLoading />
          ) : (
            <Router basename={process.env.PUBLIC_URL}>
              <Header />
              <div className="main-content">
                <Routes>
                  {areTermsAccepted() ? (
                    <>
                      <Route path="/" element={<NewsPage />} />
                      <Route path="/news" element={<NewsPage />} />
                      <Route path="/overview" element={<Overview />} />
                      <Route path="/story" element={<Story />} />
                      <Route path="/listeditor" element={<ListEditor />} />
                      <Route path="/listeditor/:id" element={<ListEditor />} />
                      <Route path="/listeditor/leader/:leaderid" element={<ListEditor />} />
                      <Route path="/editor/:mode/:target" element={<UnitEditor />} />
                      <Route path="/editor/:mode/:target/:tId" element={<UnitEditor />} />
                      <Route path="/editor/:mode/:target/base/:base" element={<UnitEditor />} />
                      <Route path="/editor/:mode/:target/base/:base/:bId" element={<UnitEditor />} />
                      <Route path="/GAMELIST/:itemId" element={<GameListDetail />} />
                      <Route path="/CHARACTER/:itemId" element={<CharacterDetail />} />
                      <Route path="/rules" element={<RulesPage />} />
                      <Route path="/csm" element={<CSM />} />
                      <Route path="/CAMPAIGN/:itemId" element={<CampaignDetail />} />
                      <Route path="/SCENARIO/:itemId" element={<ScenarioDetail />} />
                      <Route path="/SCENARIO/:itemId/MISSION/:missionId" element={<ScenarioDetailPrintMission />} />
                      <Route path="/MISSION/:itemId" element={<MissionDetail />} />
                      <Route path="/fu" element={<FU />} />
                      <Route path="/UNIT/:itemId" element={<UnitDetail />} />
                      <Route path="/FRACTION/:itemId" element={<FractionDetail />} />
                    </>
                  ) : (
                    <>
                      <Route path="/" element={<WelcomePage onTermsAccept={handleTermsAcceptance} />} />
                      <Route path="/*" element={<WelcomePage onTermsAccept={handleTermsAcceptance} />} />
                    </>
                  )}
                  <Route path="/help" element={<Help />} />
                  <Route path="/credit-imprint-privacy" element={<CreditsImprint />} />
                  <Route path="/termsofuse" element={<TermsOfUse />} />
                  <Route path="/imprint" element={<Imprint />} />
                  <Route path="/cookies" element={<CookieUsage />} />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="/credits" element={<Credits />} />
                </Routes>
              </div>
              <Footer />
            </Router>
          )
        )}
      </ErrorBoundary>
    </LanguageProvider>
  );

}

export default App;