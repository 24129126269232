// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uploader-body {
   display: flex;
   justify-content: center;
   align-items: center;
   flex-flow: column wrap;
   align-self: center;
}`, "",{"version":3,"sources":["webpack://./src/components/ImageUploader.css"],"names":[],"mappings":"AAAA;GACG,aAAa;GACb,uBAAuB;GACvB,mBAAmB;GACnB,sBAAsB;GACtB,kBAAkB;AACrB","sourcesContent":[".uploader-body {\n   display: flex;\n   justify-content: center;\n   align-items: center;\n   flex-flow: column wrap;\n   align-self: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
