import React from "react"; // useState ist nicht verwendet, also nicht notwendig zu importieren
import './Actionbar.css'; // Stil für die Box
import configModule from '../config';
import { useLanguage } from '../LanguageContext';

function Actionbar({ style, handleAdd, handleEdit, handleBack, handleSave, handleAbort, handleDelete, handleOK, handleClear, visibility, owner }) {
  const { translate } = useLanguage();

  //console.log("user: ", configModule.getUserUUID())
  //console.log("owner: ", owner)
  //console.log("vis: ", visibility)

  // Prüft, ob die Bedingung für die Anzeige der Savebar erfüllt ist
  if (configModule.isAuthor() && ((visibility==="PRIVATE" && owner===configModule.getUserUUID() ) || handleAdd)) {
    return (
      <>
          <div className={style}>
            { handleAbort && (
                  <div className="actionbar-button"
                      onClick={() => handleAbort()}
                  >
                      <img
                          className="actionbar-button-image"
                          src={`${process.env.PUBLIC_URL}/icons/generic_icons/cancel.png`}
                          alt="abort change" />
                      <div className='actionbar-title'>{translate('action.abort.title')}</div>
                  </div>
            )}
            { handleBack && (
                  <div className="actionbar-button"
                      onClick={() => handleBack()}
                  >
                      <img
                          className="actionbar-button-image"
                          src={`${process.env.PUBLIC_URL}/icons/generic_icons/back.png`}
                          alt="go back" />
                      <div className='actionbar-title'>{translate('action.back.title')}</div>
                  </div>
            )}
            { handleAdd && (
                <div className="actionbar-button"
                                onClick={() => handleAdd()}
                >
                    <img
                        className="actionbar-button-image"
                        src={`${process.env.PUBLIC_URL}/icons/generic_icons/add.png`}
                        alt="add new" />
                    <div className='actionbar-title'>{translate('action.add.title')}</div>
                </div>
            )}
            { handleDelete && (
                <div className="actionbar-button"
                                onClick={() => handleDelete()}
                >
                    <img
                        className="actionbar-button-image"
                        src={`${process.env.PUBLIC_URL}/icons/generic_icons/delete.png`}
                        alt="delete" />
                    <div className='actionbar-title'>{translate('action.delete.title')}</div>
                </div>
            )}
            { handleEdit && (
                <div className="actionbar-button"
                                onClick={() => handleEdit()}
                >
                    <img
                        className="actionbar-button-image"
                        src={`${process.env.PUBLIC_URL}/icons/generic_icons/edit.png`}
                        alt="edit" />
                    <div className='actionbar-title'>{translate('action.edit.title')}</div>
                </div>
            )}
            { handleClear && (
                <div className="actionbar-button"
                                onClick={() => handleClear()}
                >
                    <img
                        className="actionbar-button-image"
                        src={`${process.env.PUBLIC_URL}/icons/generic_icons/clear.png`}
                        alt="edit" />
                    <div className='actionbar-title'>{translate('action.clear.title')}</div>
                </div>
            )}
            { handleSave && (
                <div className="actionbar-button"
                    onClick={() => handleSave()}
                >
                    <img
                        className="actionbar-button-image"
                        src={`${process.env.PUBLIC_URL}/icons/generic_icons/okay.png`}
                        alt="save change" />
                    <div className='actionbar-title'>{translate('action.save.title')}</div>
                </div>
            )}
            { handleOK && (
                <div className="actionbar-button"
                    onClick={() => handleOK()}
                >
                    <img
                        className="actionbar-button-image"
                        src={`${process.env.PUBLIC_URL}/icons/generic_icons/okay.png`}
                        alt="okay" />
                    <div className='actionbar-title'>{translate('action.ok.title')}</div>
                </div>
            )}
          </div>
      </>
    );
  } else {
    // Gibt null zurück oder ein anderes Element, wenn nichts gerendert werden soll
    return null;
  }
}

export default Actionbar;
