// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.box-chapter {
  max-width:250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden; /* Blendet überflüssigen Inhalt aus */
}

.box-chapter img {
  max-width: 100%; /* Bild passt zur Breite der Box */
  margin: 5px;
}

.box-chapter p {
  font-size: 14px; /* Ändern Sie die Schriftgröße nach Bedarf */
  line-height: 1.2; /* Ändern Sie den Zeilenabstand nach Bedarf */
  text-align:left;
}

.box-chapter-title {
  font-size: 16px; /* Ändern Sie die Schriftgröße nach Bedarf */
  line-height: 1.2; /* Ändern Sie den Zeilenabstand nach Bedarf */
  text-align:center;
}

.box-chapter-content {
  width:100%;
  margin: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/BoxChapter.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB,EAAE,qCAAqC;AACzD;;AAEA;EACE,eAAe,EAAE,kCAAkC;EACnD,WAAW;AACb;;AAEA;EACE,eAAe,EAAE,4CAA4C;EAC7D,gBAAgB,EAAE,6CAA6C;EAC/D,eAAe;AACjB;;AAEA;EACE,eAAe,EAAE,4CAA4C;EAC7D,gBAAgB,EAAE,6CAA6C;EAC/D,iBAAiB;AACnB;;AAEA;EACE,UAAU;EACV,WAAW;AACb","sourcesContent":[".box-chapter {\n  max-width:250px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 5px;\n  margin-left: auto;\n  margin-right: auto;\n  overflow: hidden; /* Blendet überflüssigen Inhalt aus */\n}\n\n.box-chapter img {\n  max-width: 100%; /* Bild passt zur Breite der Box */\n  margin: 5px;\n}\n\n.box-chapter p {\n  font-size: 14px; /* Ändern Sie die Schriftgröße nach Bedarf */\n  line-height: 1.2; /* Ändern Sie den Zeilenabstand nach Bedarf */\n  text-align:left;\n}\n\n.box-chapter-title {\n  font-size: 16px; /* Ändern Sie die Schriftgröße nach Bedarf */\n  line-height: 1.2; /* Ändern Sie den Zeilenabstand nach Bedarf */\n  text-align:center;\n}\n\n.box-chapter-content {\n  width:100%;\n  margin: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
