// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.corps-image {
  z-index:-1;
  width:30px;
}

.corps-table {
  width:100%;
}

.corps-value {
   z-index:0;
    vertical-align:middle;
    font-size: 25px;
    position: relative;
    top: -0px;
    left: 0px;
  text-align: center;
  padding:10px;
}

.corps-table {
}
`, "",{"version":3,"sources":["webpack://./src/components/Corps.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ;;AAEA;GACG,SAAS;IACR,qBAAqB;IACrB,eAAe;IACf,kBAAkB;IAClB,SAAS;IACT,SAAS;EACX,kBAAkB;EAClB,YAAY;AACd;;AAEA;AACA","sourcesContent":[".corps-image {\n  z-index:-1;\n  width:30px;\n}\n\n.corps-table {\n  width:100%;\n}\n\n.corps-value {\n   z-index:0;\n    vertical-align:middle;\n    font-size: 25px;\n    position: relative;\n    top: -0px;\n    left: 0px;\n  text-align: center;\n  padding:10px;\n}\n\n.corps-table {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
