import React, { useState } from "react";
import './UnitEditor.css'; // Stil für die Box
import { useLanguage } from '../LanguageContext';
import List from './List'; // Importieren Sie die Box-Komponente

function EditorListEdit({ title, titleSelected, items, selected, langCode, handleAdd, handleDeletion }) {

  return (
       <div className="editor-itemlist">
          <div className="editor-itemlist-toselect">
             <h4 className="editor-itemlist-title">{title}</h4>
             <div className="editor-itemlist-items">
                 {items && items.map(item => (
                  <div key={item.uuid} className="editor-item-listitem">
                      <div >
                         <img
                         className="button-add"
                         src={`${process.env.PUBLIC_URL}/icons/generic_icons/add.png`}
                         alt="add"
                         onClick={() => handleAdd(item)}
                         />
                      </div>
                      <div className="editor-item-text">
                         { item.texts.find((text) => text.type === "NAME" && text.language === langCode)?.text || "" }
                      </div>
                 </div>
                 ))}
             </div>
          </div>
          <List title={titleSelected} entityIn={selected} style="editor-itemlist-selection" browserLanguage={langCode} edit={true} handleDeletion={handleDeletion}/>
       </div>
    );
}

export default EditorListEdit;
