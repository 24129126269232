import React from "react";
import './ActionList.css'; // Stil für die Box
import { useLanguage } from '../LanguageContext';

function ActionList({ title, actions, browserLanguage }) {
  const { translate } = useLanguage();

  const getActionType = (action) => {
    if (action.type === "COMPLEX") {
      // Wenn action.type "COMPLEX" ist, den Spracheintrag für "action.type.complex" zurückgeben
      return translate("action.type.complex");
    } else if (action.type === "SIMPLE") {
      // Wenn action.type "SIMPLE" ist, den Spracheintrag für "action.type.simple" zurückgeben
      return translate("action.type.simple");
    }
    // Wenn keine Übereinstimmung gefunden wurde, leere Zeichenkette zurückgeben
    return "";
  };

  return (
    <div className='action'>
      <h4>{title}</h4>
      <table>
        <tbody>
          {actions.map((action) => {

           const titleText = action.texts.find(
             (text) => text.type === "NAME" && text.language === browserLanguage
           );
           const title = titleText ? titleText.text : "";

           const descText = action.texts.find(
             (text) => text.type === "DESCRIPTION" && text.language === browserLanguage
           );
           const desc = descText ? descText.text : "";

           const rulesText = action.texts.find(
                   (text) => text.type === "RULES" && text.language === browserLanguage
           );
           const rules = rulesText ? rulesText.text : "";

            return (
              <tr key={action.uuid}>
                <td className='action-title'>{title}</td>
                <td className='action-type'>{getActionType(action)}</td>
                <td className='action-description'>{desc}</td>
                <td className='action-description'>{rules}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default ActionList;
