import React, { useState } from "react";
import './Item.css'; // Stil für die Box
import { useLanguage } from '../LanguageContext';
import Attributes from './Attributes'; // Importieren Sie die Box-Komponente
import AttributesV from './AttributesV'; // Importieren Sie die Box-Komponente
import Properties from './Properties'; // Importieren Sie die Box-Komponente
import Corps from './Corps'; // Importieren Sie die Box-Komponente
import CorpsV from './CorpsV'; // Importieren Sie die Box-Komponente
import EntityCommonV from './EntityCommonV'; // Importieren Sie die Box-Komponente
import Range from './Range'; // Importieren Sie die Box-Komponente

function ListItem({ entity, browserLanguage, edit, handleDeletion }) {
  // console.log("language: "+browserLanguage);
  // console.log("loading item",entity)
  const { translate } = useLanguage();

  // Deklaration der Variablen außerhalb der JSX-Klammern
  let entityName = "???";
  let entityDescription = null;
  let entityRules = null;

  if (entity && entity.texts) {
    // Berechnung von entityName, wenn entity vorhanden ist
    entityName = entity.texts.find((text) => text.type === "NAME" && text.language === browserLanguage)?.text || entityName;
    entityDescription = entity.texts.find((text) => text.type === "DESCRIPTION" && text.language === browserLanguage)?.text || entityDescription;
    entityRules = entity.texts.find((text) => text.type === "RULES" && text.language === browserLanguage)?.text || entityRules;
  }

  return (
      <div className="item">
        {entity && (
              <div className='item-body'>
                <div className='item-header'>
                    { handleDeletion && (
                       <div>
                          <img
                          className="button-delete"
                          src={`${process.env.PUBLIC_URL}/icons/generic_icons/delete.png`}
                          alt="delete"
                          onClick={() => handleDeletion(entity)}
                          />
                       </div>
                     )}
                    <div className='item-title'>{entityName}</div>
                </div>
                <div className='item-body'>
                    {entityDescription &&  (<div className='item-description'>{entityDescription}</div>)}
                    {entityRules &&  (<div className='item-rules'>{entityRules}</div>)}
                    {entity.attributes && (<Attributes attributes={entity.attributes} browserLanguage={browserLanguage} styleToSet=""/> )}
                    {entity.properties && (<Properties properties={entity.properties} browserLanguage={browserLanguage} styleToSet=""/> )}
                    {entity.range && ( <Range range={entity.range} browserLanguage={browserLanguage}/> )}
                </div>
              </div>
        )}
      </div>
    );
}

export default ListItem;
