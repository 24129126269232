import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import './UnitList.css'; // Stil für die Box
import { useLanguage } from '../LanguageContext';
import configModule from '../config';
import { Link } from 'react-router-dom'; // Importiere die Link-Komponente von react-router-dom
import Wait from './Wait'; // Importieren Sie die Box-Komponente
import UnitFlat from './UnitFlat'; // Importieren Sie die Box-Komponente
import Actionbar from './Actionbar'; // Importieren Sie die Box-Komponente
import Filterbar from './Filterbar'; // Importieren Sie die Box-Komponente

function UnitList ({ unitCount, fractionUUID, browserLanguage, style }) {
  const navigate = useNavigate();
  const { translate } = useLanguage();
  const urlByFraction = configModule.url()+"/UNIT/fraction/"+fractionUUID+""
  const urlAll = configModule.url()+"/UNIT"

  const [ filter, setFilter ] = useState(null);
  const [ isLoading, setIsLoading ] = useState(true);
  const [ units, setUnits ] = useState(null);
  const [ displayedUnits, setDisplayedUnits ] = useState([]);

  const [ url, setUrl ] = useState(fractionUUID?urlByFraction:urlAll);

  useEffect(() => {
    // Hier sollte der REST-Call stattfinden und die Daten in dataList gespeichert werden
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const headers = new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        'Authorization': configModule.getAuthHeader()
        });

        const response = await fetch(url, { headers });
        // console.log(response)
        if (!response.ok) {
          if (response.status===401) {
                configModule.clearUserAuth()
                window.location.href = process.env.PUBLIC_URL+"/overview"
                console.error("Authentication not ok or lost.")
          }
          throw new Error('Fehler beim Laden der Daten: '+response.status);
        }
        const data = await response.json();
        setUnits(data); // Daten im Zustand speichern
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [url]);

  useEffect(() => {
      if (filter)
      {
            const filteredUnits = units && units.filter(unit => {
            // Filtere basierend auf dem Alignment-Filter
            const alignmentMatch = filter.alignment === 'good' ? unit.modelCommon.alignment >= 0 :
                                   filter.alignment === 'evil' ? unit.modelCommon.alignment <= 0 : true;

            // Überprüfe, ob die Einheit als NPC markiert ist
            const npcMatch = filter.showNPCs? unit.npc===true : true

            var typeMatch = true
            if (filter.type != "ALL") typeMatch = (unit.type === filter.type ? true : false)

            var ownMatch = true
            if (filter.showOwn===true)
            {
                console.log("current id: ",configModule.getUserUUID())
                ownMatch = unit.metadata.owner === configModule.getUserUUID()? true:false
            }

            return alignmentMatch && npcMatch && typeMatch && ownMatch;
          });

          filteredUnits && filteredUnits.sort((a, b) => {
            const dateA = new Date(a.metadata.changed);
            const dateB = new Date(b.metadata.changed);

            return dateB - dateA;
          });

          setDisplayedUnits(filteredUnits);
      }
      else
      {
        setDisplayedUnits(units);
      }
  }, [units, filter]);

  const handleFilterChange = (filterData) => {
     setFilter(filterData)
  };

  const handleAdd = (event) => {
        navigate("/editor/add/UNIT/base/FRACTION/"+fractionUUID, {
            state: { returnUrl: `/FRACTION/${fractionUUID}` }
        });
  };

  return (
      <div className='fraction-units-body'>
          <div className='fraction-units-title'>{unitCount?unitCount:0} {translate('fu.unit.title')}</div>
          <div className='bars'>
                <Filterbar handleFilterChange={handleFilterChange}/>
                <Actionbar style="actionbar" handleAdd={handleAdd}/>
          </div>
          { isLoading ? (
            <Wait />
            ) : (
               displayedUnits && displayedUnits.length>0 && (
                 <>
                  <div className='units-body'>
                    <table className="units-table">
                       <thead>
                         <tr className='units-header'>
                           <th className='units-th units-name units-header'>{translate('fu.fraction.units.name')}</th>
                           <th className='units-header'><img className="attribute-image-th" src={`${process.env.PUBLIC_URL}/icons/attributes/movement.png`} alt="Attribute movement" /></th>
                           <th className='units-header'><img className="attribute-image-th" src={`${process.env.PUBLIC_URL}/icons/attributes/armor.png`} alt="Attribute armor" /></th>
                           <th className='units-header'><img className="attribute-image-th" src={`${process.env.PUBLIC_URL}/icons/attributes/fighting.png`} alt="Attribute fighting" /></th>
                           <th className='units-header'><img className="attribute-image-th" src={`${process.env.PUBLIC_URL}/icons/attributes/shooting.png`} alt="Attribute shooting" /></th>
                           <th className='units-header'><img className="attribute-image-th" src={`${process.env.PUBLIC_URL}/icons/attributes/magic.png`} alt="Attribute magic" /></th>
                           <th className='units-header'><img className="attribute-image-th" src={`${process.env.PUBLIC_URL}/icons/attributes/moral.png`} alt="Attribute moral" /></th>
                           <th className='units-header'><img className="attribute-image-th" src={`${process.env.PUBLIC_URL}/icons/attributes/HP.png`} alt="Attribute hitpoints" /></th>
                           <th className='units-header'><img className="attribute-image-th" src={`${process.env.PUBLIC_URL}/icons/attributes/gold_cost.png`} alt="Attribute points" /></th>
                         </tr>
                         <tr><td className='line' colSpan="10"></td></tr>
                       </thead>
                       <tbody>
                           {displayedUnits.map((unit) => (
                               // Beachte die umschließenden Klammern um das Fragment
                               <React.Fragment key={unit.id}> {/* Stelle sicher, dass du ein 'key' Prop hinzufügst */}
                                 <tr><td className='line' colSpan="10" ></td></tr>
                                 <UnitFlat unit={unit} browserLanguage={browserLanguage} edit={false}/>
                               </React.Fragment>
                             ))}
                       </tbody>
                    </table>
                  </div>
                 </>
                 )
              )
          }
      </div>
    );
}

export default UnitList;
