import React, { useState } from "react";
import './Set.css'; // Stil für die Box
import { useLanguage } from '../LanguageContext';
import Attributes from './Attributes'; // Importieren Sie die Box-Komponente
import AttributesV from './AttributesV'; // Importieren Sie die Box-Komponente
import Properties from './Properties'; // Importieren Sie die Box-Komponente
import Corps from './Corps'; // Importieren Sie die Box-Komponente
import CorpsV from './CorpsV'; // Importieren Sie die Box-Komponente
import EntityCommonV from './EntityCommonV'; // Importieren Sie die Box-Komponente
import Item from './Item'; // Importieren Sie die Box-Komponente

function ItemSet({ entity, browserLanguage, style }) {
  //console.log("language: "+browserLanguage);
  //console.log("loaded itemset", entity)
  const { translate } = useLanguage();

  // Deklaration der Variablen außerhalb der JSX-Klammern
  let entityName = "Unbekannt";
  let styleToSet = "";

  if (entity && entity.texts) {
    // Berechnung von entityName, wenn entity vorhanden ist
    entityName = entity.texts.find((text) => text.type === "NAME" && text.language === browserLanguage)?.text || entityName;
    // Weitere Stilanpassungen können hier vorgenommen werden
    styleToSet = style ? style : "entity-style"; // Beispiel für einen Stil
  }

  return (
      <div className={style}>
        {entity && (
          <div className="set">
              <div className='set-body'>
                <div className='set-title'>{entityName}</div>
                <Attributes attributes={entity.attributes} browserLanguage={browserLanguage} styleToSet=""/>
                <Properties properties={entity.properties} browserLanguage={browserLanguage} styleToSet=""/>

                <div className='itemlist'>
                    { entity.items && entity.items.length > 0 && entity.items.map( item => (
                        <Item key={item.uuid} entity={item} browserLanguage={browserLanguage} />
                    ))}
                 </div>
              </div>
          </div>
        )}
      </div>
    );
}

export default ItemSet;
