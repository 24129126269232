import './SelectFraction.css'; // Stil für die Box
import React, { useState, useEffect } from 'react';
import { useLanguage } from '../LanguageContext';

function SelectFraction({ fractions, onFractionSelected }) {
  const [filter, setFilter] = useState('');
  const [filteredFractions, setFilteredFractions] = useState(fractions);
  const { translate } = useLanguage();

  // Diese Funktion prüft nun alle Namen der Fraktionen, unabhängig von der Sprache
  const fractionMatchesFilter = (fraction) => {
    return fraction.texts.some(text =>
      text.type === "NAME" && text.text.toLowerCase().includes(filter.toLowerCase())
    );
  };

  const handleFilterChange = (event) => {
    const newFilter = event.target.value.toLowerCase();
    setFilter(newFilter);

    // Aktualisiere die gefilterten Fraktionen basierend auf dem neuen Filter
    const newFilteredFractions = fractions.filter(fractionMatchesFilter);
    setFilteredFractions(newFilteredFractions);
  };

  const handleFractionSelect = (fraction) => {
    onFractionSelected(fraction);
  };

  return (
    <div>
      <div className="select-fraction-title">{translate('fraction.selection.title')}</div>
      <input
        type="text"
        placeholder={translate('fraction.selection.search')}
        value={filter}
        onChange={handleFilterChange}
        className="endText select-fraction-search"
      />
      <div className="select-fraction-container">
        {filteredFractions.map(fraction => (
          <div
            key={fraction.uuid}
            className="endText select-fraction-item"
            onClick={() => handleFractionSelect(fraction)}
          >
            {/* Hier nehmen wir einfach den ersten Namen, da die genaue Sprache hier weniger wichtig ist */}
            {fraction.texts.find(text => text.type === "NAME")?.text || "Unbekannt"}
          </div>
        ))}
      </div>
    </div>
  );
}

export default SelectFraction;
