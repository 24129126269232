// WelcomePageLoading.js

import './App.css';
import React from "react";
import { useLanguage } from './LanguageContext';
import Wait from './components/Wait';

function WelcomePageLoading() {
  const { translate } = useLanguage();

  return (
    <div className="Welcome-Body">
            <img src="end_logo_trans_medium.png" className="App-logo" alt="logo" />
            <div>
              <h1>{translate('welcome.loading')}</h1>
              <Wait />
            </div>
    </div>
  );
}

export default WelcomePageLoading;