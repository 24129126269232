import './App.css';
import { useParams } from 'react-router-dom';
import React, { useState,useEffect } from "react";
import { useLanguage } from './LanguageContext';
import { Link } from 'react-router-dom'; // Importiere die Link-Komponente von react-router-dom
import configModule from './config';
import Textfield from './components/Textfield'; // Importieren Sie die Box-Komponente
import ScenarioDetails from './components/ScenarioDetails'; // Importieren Sie die Box-Komponente
import Mission from './components/Mission'; // Importieren Sie die Box-Komponente
import EventList from './components/EventList'; // Importieren Sie die Box-Komponente
import ActionList from './components/ActionList'; // Importieren Sie die Box-Komponente
import NPCList from './components/NPCList'; // Importieren Sie die Box-Komponente
import GameSetup from './components/GameSetup'; // Importieren Sie die Box-Komponente
import Wait from './components/Wait'; // Importieren Sie die Box-Komponente
import Image from './components/Image';

function ScenarioDetailPrintMission() {
  const { translate } = useLanguage();
  const { itemId } = useParams();
  const { missionId } = useParams();
  const { type } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [ entity, setEntity ] = useState(null);
  const [ mission, setMission ] = useState(null);
  const url = configModule.url()+"/SCENARIO/"+itemId+"/true"
  const langCode = navigator.language.split('-')[0].toUpperCase();

  useEffect(() => {
    // Hier sollte der REST-Call stattfinden und die Daten in dataList gespeichert werden
    const fetchData = async () => {
      try {
            const headers = new Headers({
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'lang': langCode, // Füge den Sprachcode als Header hinzu
              'Authorization': configModule.getAuthHeader()
            });

        const response = await fetch(url, { headers });
        // console.log(response)
        if (!response.ok) {
          if (response.status===401) {
                configModule.clearUserAuth()
                window.location.href = process.env.PUBLIC_URL+"/overview"
                console.error("Authentication not ok or lost.")
          }
          throw new Error('Fehler beim Laden der Daten: '+response.status);
        }
        const data = await response.json();
        console.log('Fetched data:', data); // Konsolenausgabe der empfangenen Daten
        setEntity(data); // Daten im Zustand speichern

        var mGood = data.missionsGood.find(m => m.uuid === missionId);
        var mBad = data.missionsEvil.find(m => m.uuid === missionId);
        console.log(mGood)
        console.log(mBad)
        setMission(mGood?mGood:mBad)

        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [url]);


  function sortEvents(events) {
    return [...events].sort((a, b) => {
      if (a.triggerValue == null) return 1;
      if (b.triggerValue == null) return -1;
      return a.triggerValue - b.triggerValue;
    });
  }

  return (
    <div className="Overview-Body">
{isLoading ? (
        <Wait/>
    ) : (
      entity && (
       <div>
        <Textfield objectData={entity} type="NAME" classNameToSet="details-title"/>
        <Mission mission={mission} browserLanguage={langCode} style="mission-container" />

        {entity.actions && entity.actions.length > 0 && (
             <div className="action-containers">
               <ActionList title={translate("action.title")} actions={entity.actions} browserLanguage={langCode} />
             </div>
        )}

        {/* Prüfen Sie, ob entity.events vorhanden und nicht leer ist */}
        {entity.events && entity.events.length > 0 && (
          <div className="event-containers">
            <EventList title={translate("event.title")} events={sortEvents(entity.events)} browserLanguage={langCode} />
          </div>
        )}

        {entity.npcs && entity.npcs.length > 0 && (
             <div className="npc-containers">
               <NPCList title={translate("npc.title")} npcs={entity.npcs} browserLanguage={langCode} showFluff={false}/>
             </div>
        )}
       </div>
       )
    )}

        </div>
  );
}

export default ScenarioDetailPrintMission;