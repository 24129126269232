// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `li {
  max-width: 100%; /* Bild passt zur Breite der Box */
  margin: 5px;
  text-align: left;
}

.item-description {
  font-size: 16px; /* Ändern Sie die Schriftgröße nach Bedarf */
  line-height: 1.2; /* Ändern Sie den Zeilenabstand nach Bedarf */
  text-align:left;
  padding:5px;
}

.item-rules {
  font-size: 16px; /* Ändern Sie die Schriftgröße nach Bedarf */
  line-height: 1.2; /* Ändern Sie den Zeilenabstand nach Bedarf */
  text-align:left;
  padding:5px;
}

.item-header {
  width:95%;
  display: flex;
  justify-content: stretch;
  align-items: center;
  flex-flow: row wrap;
}


.item-title {
  font-size: 20px; /* Ändern Sie die Schriftgröße nach Bedarf */
  line-height: 1.2; /* Ändern Sie den Zeilenabstand nach Bedarf */
  text-align:center;
  padding:00px;
}

.miniature-image{
}

.item {
  border-style:solid;
  border-color:gray;
}

.item-body {
  width:100%;
  display: flex;
  justify-content: stretch;
  align-items: center;
  flex-flow: row wrap;
}

.item-body-sub {
   display: flex;
   justify-content: center;
   max-width: 250px;
   align-items: center;
   flex-flow: column wrap;
   align-self: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Item.css"],"names":[],"mappings":"AAAA;EACE,eAAe,EAAE,kCAAkC;EACnD,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,eAAe,EAAE,4CAA4C;EAC7D,gBAAgB,EAAE,6CAA6C;EAC/D,eAAe;EACf,WAAW;AACb;;AAEA;EACE,eAAe,EAAE,4CAA4C;EAC7D,gBAAgB,EAAE,6CAA6C;EAC/D,eAAe;EACf,WAAW;AACb;;AAEA;EACE,SAAS;EACT,aAAa;EACb,wBAAwB;EACxB,mBAAmB;EACnB,mBAAmB;AACrB;;;AAGA;EACE,eAAe,EAAE,4CAA4C;EAC7D,gBAAgB,EAAE,6CAA6C;EAC/D,iBAAiB;EACjB,YAAY;AACd;;AAEA;AACA;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,UAAU;EACV,aAAa;EACb,wBAAwB;EACxB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;GACG,aAAa;GACb,uBAAuB;GACvB,gBAAgB;GAChB,mBAAmB;GACnB,sBAAsB;GACtB,kBAAkB;AACrB","sourcesContent":["li {\n  max-width: 100%; /* Bild passt zur Breite der Box */\n  margin: 5px;\n  text-align: left;\n}\n\n.item-description {\n  font-size: 16px; /* Ändern Sie die Schriftgröße nach Bedarf */\n  line-height: 1.2; /* Ändern Sie den Zeilenabstand nach Bedarf */\n  text-align:left;\n  padding:5px;\n}\n\n.item-rules {\n  font-size: 16px; /* Ändern Sie die Schriftgröße nach Bedarf */\n  line-height: 1.2; /* Ändern Sie den Zeilenabstand nach Bedarf */\n  text-align:left;\n  padding:5px;\n}\n\n.item-header {\n  width:95%;\n  display: flex;\n  justify-content: stretch;\n  align-items: center;\n  flex-flow: row wrap;\n}\n\n\n.item-title {\n  font-size: 20px; /* Ändern Sie die Schriftgröße nach Bedarf */\n  line-height: 1.2; /* Ändern Sie den Zeilenabstand nach Bedarf */\n  text-align:center;\n  padding:00px;\n}\n\n.miniature-image{\n}\n\n.item {\n  border-style:solid;\n  border-color:gray;\n}\n\n.item-body {\n  width:100%;\n  display: flex;\n  justify-content: stretch;\n  align-items: center;\n  flex-flow: row wrap;\n}\n\n.item-body-sub {\n   display: flex;\n   justify-content: center;\n   max-width: 250px;\n   align-items: center;\n   flex-flow: column wrap;\n   align-self: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
