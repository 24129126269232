// LanguageContext.js

import React, { useState, createContext, useEffect, useContext } from 'react';
import configModule from './config';

const LanguageContext = createContext();

function useLanguageContext() {
  const [langCode, setLangCode] = useState(configModule.config.lang); // Standardmäßig Englisch als Standardsprache
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    console.log('LANGUAGE',navigator.language);
    fetch(`${process.env.PUBLIC_URL}/locales/${langCode}.json`)
      .then((response) => response.json())
      .then((data) => setTranslations(data))
      .catch((error) => console.error('Error loading translations:', error));
  }, [langCode]);

  const translate = (key) => {
    // Gib die Übersetzung für den gegebenen Schlüssel zurück
    return translations[key] || key;
  };

  return { langCode, setLangCode, translate, translations };
}

function LanguageProvider({ children }) {
  const languageContextValue = useLanguageContext();
  return (
    <LanguageContext.Provider value={languageContextValue}>
      {children}
    </LanguageContext.Provider>
  );
}

function useLanguage() {
  return useContext(LanguageContext);
}

export { LanguageProvider, useLanguage };
