import React, { useState } from "react";
import './Mission.css'; // Stil für die Box
import { useLanguage } from '../LanguageContext';
import Mission from './Mission'; // Importieren Sie die Box-Komponente
import { Link } from 'react-router-dom'; // Importiere die Link-Komponente von react-router-dom

function MissionList({ scenarioUUID, title, missions, browserLanguage, style }) {
  const [selectedMission, setSelectedMission] = useState(null);
  const { translate } = useLanguage();

  const handleMissionClick = (uuid) => {
    if (uuid===selectedMission) setSelectedMission(null);
    else setSelectedMission(uuid);
  };

  return (
    <div className={style}>
      <h4>{title}</h4>
        {missions && missions.map((mission) => {

          let missionNameText = mission.texts.find((text) => text.type === "NAME" && text.language === browserLanguage);
          let missionName = missionNameText?.text || mission.texts.find((text) => text.type === "NAME")?.text || "Unbekannt";

          return (
            <div key={mission.uuid}>
               { selectedMission !== mission.uuid && (
                 <div>
                     <div className='mission-title' onClick={() => handleMissionClick(mission.uuid)}>
                       {missionName}
                     </div>
                 </div>
               )}
              {selectedMission === mission.uuid && (
                 <div>
                    <Link to={`/SCENARIO/${scenarioUUID}/MISSION/${mission.uuid}`}>
                        <img className="attribute-image" src={`${process.env.PUBLIC_URL}/icons/generic_icons/info.png`} alt="print" />
                    </Link>
                    <div onClick={() => handleMissionClick(mission.uuid)}>
                        <Mission mission={mission} browserLanguage={browserLanguage} showFluff={true}/>
                    </div>
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
}

export default MissionList;
