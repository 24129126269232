import React, { useState, useEffect } from "react";
import './EntityList.css'; // Stil für die Box
import { useLanguage } from '../LanguageContext';
import ListItem from './ListItem'; // Importieren Sie die Box-Komponente
import { Link } from 'react-router-dom'; // Importiere die Link-Komponente von react-router-dom

function List({ title, entityIn, browserLanguage, style, edit, handleDeletion }) {
  const { translate } = useLanguage();
  const [entity, setEntity] = useState(entityIn);

  useEffect(() => {
    setEntity(entityIn);
  }, [entityIn]);

  return (
    <div className={style}>
      <h4>{title}</h4>
      <div className='entity-body'>
        {
          entity && entity.length > 0 ? (
            <table className="entity-table">
              <tbody>
                {entity.map((itemEntry) => (
                  <React.Fragment key={itemEntry.uuid}>
                    <tr><td className='line' colSpan="10"></td></tr>
                    <tr>
                      <td>
                        <div className="listItemEntry">
                          <ListItem entity={itemEntry} browserLanguage={browserLanguage} handleDeletion={handleDeletion} />
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="entity-noitem-text">{translate(`itemlist.noitem`)}</div>
          )
        }
      </div>
    </div>
  );
}

export default List;
