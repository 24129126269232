const defaultBffUrl = 'http://192.168.180.223:10344'; // Fallback-URL
const defaultEnv = 'int'; // Umgebung
const defaultPath = '/v1/spa';
const imagesPath = '/v1/images';
const defaultAnonymousAuth = 'anonymous 000ac314813fa0fd9c81cb216034fe0a78a61857e233ba936b593dd6ffcacd57';
const bffUrl = process.env.REACT_APP_BFF_URL || defaultBffUrl;
const envMarker = process.env.REACT_APP_ENV || defaultEnv;
const liveMarker = process.env.REACT_APP_LIVE === 'true' || true;
const anonymousAuth = process.env.REACT_APP_ANONYMOUS_AUTH || defaultAnonymousAuth;

const config = {
  userAuth: '',
  userIntrospect: {},
  live: liveMarker,
  env: envMarker,
  loggedIn: false,
  lang: ""
};

function isSettingStored() {
  const item = localStorage.getItem('END_SETTINGS');
  return item !== null;
}

function loadSettings() {
  const storedConfig = localStorage.getItem('END_SETTINGS');
  if (storedConfig) {
    const newConfig = JSON.parse(storedConfig);
    Object.assign(config, newConfig); // Aktualisiere die Werte in config
  }
}

function storeSettings() {
  localStorage.setItem('END_SETTINGS', JSON.stringify(config));
}

function clearUserAuth() {
    config.userAuth='';
    config.userIntrospect={};
    config.loggedIn=false
    storeSettings()
}

function getAuthHeader() {
  return config.userAuth || anonymousAuth;
}

function getAnonymousAuthHeader() {
  return anonymousAuth;
}

function getUserUUID() {
  return config.userIntrospect.ident;
}

function isAuthor() {
  //console.log("current roles: ",config.userIntrospect.roles)
  return config.userIntrospect && config.userIntrospect.roles && config.userIntrospect.roles.some( role => role.ident="END_USER" );
}

function url() {
  return bffUrl + defaultPath;
}

function imageUrl() {
  return bffUrl + imagesPath;
}

function baseUrl() {
  return bffUrl;
}

const configModule = {
  config,
  isSettingStored,
  loadSettings,
  storeSettings,
  getAuthHeader,
  getAnonymousAuthHeader,
  clearUserAuth,
  url,
  baseUrl,
  imageUrl,
  isAuthor,
  getUserUUID
};

export default configModule;
