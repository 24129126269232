import React, { useState, useEffect } from "react";
import { useParams, useNavigate,Link } from 'react-router-dom';
import './Unit.css'; // Stil für die Box
import { useLanguage } from '../LanguageContext';
import Attributes from './Attributes'; // Importieren Sie die Box-Komponente
import AttributesV from './AttributesV'; // Importieren Sie die Box-Komponente
import Properties from './Properties'; // Importieren Sie die Box-Komponente
import Corps from './Corps'; // Importieren Sie die Box-Komponente
import CorpsV from './CorpsV'; // Importieren Sie die Box-Komponente
import Range from './Range'; // Importieren Sie die Box-Komponente
import EntityCommonV from './EntityCommonV'; // Importieren Sie die Box-Komponente
import EntityCommon from './EntityCommon'; // Importieren Sie die Box-Komponente
import Image from './Image';
import configModule from '../config';
import Actionbar from './Actionbar'; // Importieren Sie die Box-Komponente

function mergeItems({ items, browserLanguage }) {
   const mergedNames = items
  .map((item) =>
      item.texts.find((text) => text.type === "NAME" && text.language === browserLanguage)?.text || "-"
    )
    .join(", ");

  return mergedNames;
}

function mapType( type, translate ) {
  let translatedType = type; // Standard: Der Typ wird nicht übersetzt
  //INFANTRY,COMMANDER,SHOOTER,FIGHTER,CAVALRY,ARTILLERY,SHOOTING_CAVALRY
  if (type === "INFANTRY") {
    translatedType = translate('unit.type.infantry');
  } else if (type === "COMMANDER") {
    translatedType = translate('unit.type.commander');
  } else if (type === "SHOOTER") {
    translatedType = translate('unit.type.shooter');
  } else if (type === "FIGHTER") {
     translatedType = translate('unit.type.fighter');
  } else if (type === "HERO") {
        translatedType = translate('unit.type.hero');
  }
  else translatedType = ""
  // Fügen Sie hier weitere Übersetzungen für verschiedene Typen hinzu

  return translatedType;
}

function listWeapons({ items, browserLanguage }) {
  const mergedWeapons = items.map((item, index) => {
    const itemName = item.texts.find((text) => text.type === "NAME" && text.language === browserLanguage)?.text || "-";

    return (
      <span key={item.id}>{itemName} {item.itemType === "MELEE_WEAPON" || item.itemType === "RANGE_WEAPON" ? <Range range={item.range} /> : null}
        {index < items.length - 1 ? ", " : ""}</span>
    );
  });

  return <>{mergedWeapons}</>;
}


function sumAttributes(itemSet, armor, attributes, properties) {
  const totalAttributes = {
    movement: 0,
    fighting: 0,
    shooting: 0,
    armor: 0,
    magic: 0,
    moral: 0,
    hitpoints: 0,
  };

  if (attributes) {
        Object.keys(totalAttributes).forEach((attribute) => {
          totalAttributes[attribute] += attributes[attribute] || 0;
        });
  }

  if (armor) {
          Object.keys(totalAttributes).forEach((attribute) => {
            totalAttributes[attribute] += armor.attributes[attribute] || 0;
          });
  }

  if (itemSet) {
            Object.keys(totalAttributes).forEach((attribute) => {
              totalAttributes[attribute] += itemSet.attributes[attribute] || 0;
            });
  }

  properties && properties.forEach((property) => {
    if (property.attributes) {
      Object.keys(totalAttributes).forEach((attribute) => {
        totalAttributes[attribute] += property.attributes[attribute] || 0;
      });
    }
  });


  itemSet && itemSet.items.forEach((item) => {
        if (item.attributes) {
          Object.keys(totalAttributes).forEach((attribute) => {
            totalAttributes[attribute] += item.attributes[attribute] || 0;
          });
        }
  });

  armor && armor.items.forEach((item) => {
      if (item.attributes) {
        Object.keys(totalAttributes).forEach((attribute) => {
          totalAttributes[attribute] += item.attributes[attribute] || 0;
        });
      }
    });

  return totalAttributes;
}

function extractUniqueProperties(entity) {
  const allProperties = new Set();

  // Extrahiere Properties aus der Hauptentität
  if (entity && entity.properties) {
    entity.properties.forEach((property) => {
      allProperties.add(property); // Annahme: "name" ist der Name der Property
    });
  }

  // Extrahiere Properties aus den Sets und deren Items
  if (entity && entity.sets) {
    entity.sets.forEach((set) => {
      if (set.properties) {
        set.properties.forEach((property) => {
          allProperties.add(property);
        });
      }
      if (set.items) {
        set.items.forEach((item) => {
          if (item.properties) {
            item.properties.forEach((property) => {
              allProperties.add(property);
            });
          }
        });
      }
    });
  }

  // Extrahiere Properties aus den Items
    if (entity && entity.items) {
      entity.items.forEach((item) => {
        if (item.properties) {
          item.properties.forEach((property) => {
            allProperties.add(property);
          });
        }
      });
    }

  // Extrahiere Properties aus der Armor
  if (entity && entity.armor && entity.armor.properties) {
    entity.armor.properties.forEach((property) => {
      allProperties.add(property);
    });
  }

  return Array.from(allProperties); // Konvertiere Set in ein Array
}

function renderSets(entity, selectedSetIndex, setSelectedSetIndex, browserLanguage, translate) {
  if (entity.sets && entity.sets.length > 0) {
    return (
      <div className='unitshort-body-element'>
        <div>{translate('unit.weapon')}</div>
        {entity.sets.map((set, index) => (
          <div
            key={set.uuid}
            style={{ fontWeight: selectedSetIndex === index ? 'bold' : 'normal' }}
            onClick={() => setSelectedSetIndex(index)}
          >
            {set.texts.find((text) => text.type === "NAME" && text.language === browserLanguage)?.text || "-"} (
            {set.items && set.items.length > 0 ? listWeapons({ items: set.items, browserLanguage }) : ""})
          </div>
        ))}
      </div>
    );
  }
  return null;
}

function renderItems(entity, browserLanguage, translate) {
  if (entity.items && entity.items.length > 0) {
    return (
      <div className='unitshort-body-element'>
        <div>{translate('unit.items')}</div>
        <div>{mergeItems({ items: entity.items, browserLanguage })}</div>
      </div>
    );
  }
  return null;
}

function renderArmor(entity, browserLanguage, translate) {
  if (entity.armor) {
    return (
      <div key={entity.armor.uuid} className='unitshort-body-element'>
        <div>{translate('unit.armor')}</div>
        <div>
          {entity.armor.texts.find((text) => text.type === "NAME" && text.language === browserLanguage)?.text || "-"} (
          {entity.armor.items && entity.armor.items.length > 0 ? mergeItems({ items: entity.armor.items, browserLanguage }) : ""}
          )
        </div>
      </div>
    );
  }
  return null;
}

function UnitShort({ entity, browserLanguage, style }) {
  const navigate = useNavigate();
  const [selectedSetIndex, setSelectedSetIndex] = useState(0);
  const [totalAttributes, setTotalAttributes] = useState(null); // Hier wird der Gesamtattribut-Zustand hinzugefügt
  const { translate } = useLanguage();

  // Deklaration der Variablen außerhalb der JSX-Klammern
  let fractionName = "Unbekannt";
  let entityGenericName = "Unbekannt";
  let entityName = "Unbekannt";
  let entityType = "";
  let styleToSet = "";
  let attributes = null;
  let properties = null;


  // infos für web calls
  const headers = new Headers({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'lang': browserLanguage,
      'Authorization': configModule.getAuthHeader(),
  });

  useEffect(() => {
    // Diese Funktion wird immer dann aufgerufen, wenn sich selectedSetIndex, entity, oder andere abhängige Werte ändern.
      const selectedSet = entity.sets[selectedSetIndex];
      properties = extractUniqueProperties(entity);
      const newTotalAttributes = sumAttributes(selectedSet, entity.armor, entity.attributes, properties);
      setTotalAttributes(newTotalAttributes); // Aktualisieren Sie den Zustand mit den neuen Attributen
  }, [selectedSetIndex, entity]); // Hier geben Sie die Abhängigkeiten an, die Änderungen auslösen sollen

  if (entity) {
      // Hier können Sie sicher auf selectedSetIndex und entity.sets zugreifen
      const selectedSet = entity.sets[selectedSetIndex];
      // Berechnung von entityName, wenn entity vorhanden ist
      entityGenericName = entity.texts.find((text) => text.type === "NAME" && text.language === browserLanguage)?.text || entityGenericName;
      fractionName = entity.fraction.texts.find((text) => text.type === "NAME" && text.language === browserLanguage)?.text || fractionName;
      entityName = entity.name ? entity.name : entityGenericName;
      entityType = mapType(entity.type, translate);
      properties = extractUniqueProperties(entity);
      attributes = totalAttributes;
      // Restlichen Code ausführen
  } else {
      // Hier können Sie eine geeignete Aktion ausführen, wenn die Bedingungen nicht erfüllt sind
  }


  const handleDelete = () => {
       let url = `${configModule.url()}/${entity.entityCommon.type}/${entity.uuid}`;
       const exists = async () => {
             const response = await fetch(url,
                {
                  method: 'HEAD', // Verwende POST oder PUT, je nach API-Anforderungen
                  headers: headers
                }
             );
            if (!response.ok) {
             if (response.status === 404) {
                return true
             }
             else if (response.status === 401) {
                     configModule.clearUserAuth();
                     window.location.href = process.env.PUBLIC_URL + "/overview";
                     console.error("Authentication not ok or lost.");
             }
             throw new Error('Fehler beim Laden der Daten: ' + response.status);
            }
            else{
                return true;
            }
       };

       Promise.all([exists()]).then(([exists]) => {
            if (exists)
            {
                console.log("entity existing, deleting: ",entity.uuid);

                const deleteEntity = async () => {
                     const response = await fetch(url,
                         {
                           method: 'DELETE', // Verwende POST oder PUT, je nach API-Anforderungen
                           headers: headers
                         }
                      );
                     if (!response.ok) {
                      if (response.status === 404) {
                         return true
                      }
                      else if (response.status === 401) {
                              configModule.clearUserAuth();
                              window.location.href = process.env.PUBLIC_URL + "/overview";
                              console.error("Authentication not ok or lost.");
                      }
                      throw new Error('Fehler beim Laden der Daten: ' + response.status);
                     }
                     else return true
                };

                Promise.all([deleteEntity()]).then(([deleteEntity]) => {
                   console.log("entity deleted: ",entity.uuid);
                       // load fraction view
                   if (entity.entityCommon.type==="CHARACTER") url = `/story`;
                   else if (entity.entityCommon.type==="UNIT") url = `/FRACTION/${entity.fraction.uuid}`;
                   else url = "/overview";
                   navigate(url);
              })
              .catch(error => {
                   console.error('Fehler beim Löschen:', error);
              });

            }
       })
       .catch(error => {
            console.error('Fehler beim Löschen:', error);
       });
  }

  const handleEdit = () => navigate(`/editor/edit/${entity.entityCommon.type}/base/${entity.entityCommon.type}/${entity.uuid}`);

  return (
      <div>
        {entity && (
          <div className={style}>
              <div className='unitshort-body'>
                <div className='unitshort-subtitle'>{entityType}</div>
                <div className='unitshort-title'>{entityName}</div>
                <div className='unitshort-subtitle'><Link to={`/FRACTION/${entity.fraction.uuid}`}>{fractionName}</Link></div>
              </div>
              <div className='fluff'>
                <p>{entity.texts.find((text) => text.type === "FLUFF" && text.language === browserLanguage)?.text}</p>
              </div>
              <div className='unitshort-body'>
                <AttributesV attributes={attributes} browserLanguage={browserLanguage} styleToSet=""/>
                 <div className='unit-body-sub'>
                    <Image
                         classNameIn={"miniature-image"}
                         type={entity.entityCommon.type}
                         id={entity.uuid}
                         size={400}
                         fallbackBildUrl={"/transparent.png"}
                         title={""}/>
                </div>
                 <EntityCommonV
                    modelCommon={entity.modelCommon}
                    browserLanguage={browserLanguage}
                    points={entity.points}
                    hp={entity.attributes.hitpoints}styleToSet=""/>
              </div>
              <div className='unitshort-body-sub unitshort-description'>
                {renderSets(entity, selectedSetIndex, setSelectedSetIndex, browserLanguage, translate)}
                {renderArmor(entity, browserLanguage, translate)}
                {renderItems(entity, browserLanguage, translate)}
                <Properties properties={properties} browserLanguage={browserLanguage} styleToSet=""/>
              </div>

              <Actionbar style="savebar" handleDelete={handleDelete} handleEdit={handleEdit} visibility={entity.metadata.visibility} owner={entity.metadata.owner}/>
          </div>
        )}
      </div>
    );
}

export default UnitShort;
