import './App.css';
import { useParams } from 'react-router-dom';
import React, { useState,useEffect } from "react";
import { useLanguage } from './LanguageContext';
import { Link } from 'react-router-dom'; // Importiere die Link-Komponente von react-router-dom
import configModule from './config';
import Unit from './components/Unit'; // Importieren Sie die Box-Komponente
import UnitShort from './components/UnitShort'; // Importieren Sie die Box-Komponente
import Wait from './components/Wait'; // Importieren Sie die Box-Komponente
import Gamelists from './components/Gamelists'; // Importieren Sie die Box-Komponente


function UnitDetail() {
  const { translate } = useLanguage();
  const { itemId } = useParams();
  const { type } = useParams();
  const [detailsSelected, setDetailsSelected] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [ entity, setEntity ] = useState(null);
  const url = configModule.url()+"/CHARACTER/"+itemId+"/true"
  const langCode = navigator.language.split('-')[0].toUpperCase();

  const handleUnitClick = () => {
        setDetailsSelected(!detailsSelected)
  };

  useEffect(() => {
    // Hier sollte der REST-Call stattfinden und die Daten in dataList gespeichert werden
    const fetchData = async () => {
      try {
            const headers = new Headers({
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'lang': langCode, // Füge den Sprachcode als Header hinzu
              'Authorization': configModule.getAuthHeader()
            });

        const response = await fetch(url, { headers });
        // console.log(response)
        if (!response.ok) {
          if (response.status===401) {
                configModule.clearUserAuth()
                window.location.href = process.env.PUBLIC_URL+"/overview"
                console.error("Authentication not ok or lost.")
          }
          throw new Error('Fehler beim Laden der Daten: '+response.status);
        }
        const data = await response.json();
        console.log('Fetched data:', data); // Konsolenausgabe der empfangenen Daten
        setEntity(data); // Daten im Zustand speichern
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [url]);



  return (
    <div className="Overview-Body">
    {isLoading ? (
        <Wait />
        ) : entity && (
          <>
            <div onClick={() => handleUnitClick()}>{translate('character.title')}</div>
            {detailsSelected ? (
              <Unit entity={entity} browserLanguage={langCode} />
            ) : (
              <UnitShort entity={entity} browserLanguage={langCode} />
            )}
            <Gamelists characterUUID={entity.uuid} browserLanguage={langCode}/>
            <div className="spacer-100"/>
          </>
    )}
    </div>
  );
}

export default UnitDetail;