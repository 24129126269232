import React from 'react';
import './BoxChapter.css'; // Stil für die Box

function Box({ icon, title, content }) {
  return (
    <div className="box-chapter">
      {icon && <div>{icon}</div>}
      <div className="box-chapter-title">{title}</div>
      <div className="box-chapter-content">{content}</div>
    </div>
  );
}

export default Box;