import './App.css';
import './components/GameList.css';
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate,useLocation  } from 'react-router-dom';
import configModule from './config';
import UnitFlat from './components/UnitFlat';
import GameList from './components/GameList';
import Wait from './components/Wait'; // Importieren Sie die Box-Komponente
import { useLanguage } from './LanguageContext';
import Actionbar from './components/Actionbar'; // Importieren Sie die Box-Komponente


function ListEditor() {
  const { id, leaderid } = useParams(); // Vereinfachte Nutzung von useParams()
  const { translate } = useLanguage();
  const navigate = useNavigate();
  const location = useLocation();
  const returnUrl = location.state?.returnUrl ?? "/overview ";
  const [isLoading, setIsLoading] = useState(true);
  const [fractions, setFractions] = useState([]); // Behält den initialen leeren Array
  const [availableUnits, setAvailableUnits] = useState([]); // Hier kommen die verfügbaren Einheiten rein
  const [displayedUnits, setDisplayedUnits] = useState([]);
  const browserLanguage = configModule.config.lang.toUpperCase()
  const [newList, setNewList] = useState(true)

  // infos für web calls
  const headers = new Headers({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'lang': browserLanguage,
      'Authorization': configModule.getAuthHeader(),
  });


  const createInitialList = () => ({
    metadata: { visibility: "PRIVATE",owner: configModule.getUserUUID() },
    gameTypes: [],
    settings: [],
    texts: [],
    leader: null,
    units: [],
    points: 0
  });

  const getInitialStateJSON = (key, defaultValue) => {
    const storedValue = localStorage.getItem(key);
    return storedValue !== null ? JSON.parse(storedValue) : defaultValue;
  };

  const getInitialStateString = (key, defaultValue) => {
      const storedValue = localStorage.getItem(key);
      return storedValue !== null ? storedValue : defaultValue;
  };

  const [alignmentFilter, setAlignmentFilter] = useState(getInitialStateString('END_GAMELIST_ALIGNMENT_FILTER', ''));
  const [selectedType, setSelectedType] = useState(getInitialStateString('END_GAMELIST_SELECTED_TYPE', 'ALL'));
  const [showNPCs, setShowNPCs] = useState(getInitialStateJSON('END_GAMELIST_SHOW_NPCS', false));
  const [selectedFaction, setSelectedFaction] = useState(getInitialStateString('END_GAMELIST_SELECTED_FRACTION', ''));
  const [filter, setFilter] = useState({ alignment: alignmentFilter, type: selectedType });


  const initializeEntity = () => {
    const storedList = loadStoredList();
    let ret = storedList || createInitialList();
    if (ret.metadata==null || ret.metadata==undefined || ret.metadata.owner==null||ret.metadata.owner==undefined)
    {
        ret = {...ret, metadata: { visibility: "PRIVATE",owner: configModule.getUserUUID() } }
    }
    return ret
  };

 const createNewEntity = (leaderIn) => {
     let ret = createInitialList();
     ret = {...ret, metadata: { visibility: "PRIVATE",owner: configModule.getUserUUID() }, leader:leaderIn, points: leaderIn.points }
     setEntity(ret);
     store();
 };

 const [entity, setEntity] = useState(initializeEntity());

 useEffect(() => {
    if (id!=null && id!=undefined)
    {
        let url = `${configModule.url()}/GAMELIST/${id}/true`;
        //console.log("loading gamelist data with url: " + url);
        setIsLoading(true);
        const fetchData = async () => {
          try {
            const response = await fetch(url, { headers });
            if (!response.ok) {
              if (response.status === 401) {
                configModule.clearUserAuth();
                window.location.href = process.env.PUBLIC_URL + "/overview";
                console.error("Authentication not ok or lost.");
              }
              throw new Error('Fehler beim Laden der Daten: ' + response.status);
            }
            else
            {
                const data = await response.json();
                //console.log('Fetched data:', data);
                setEntity(data);
                setNewList(false);
                setIsLoading(false);
            }
          } catch (error) {
            console.error('Error fetching data:', error);
            setIsLoading(false);
          }
        };
        fetchData();
    }
    else
    {
        setEntity(initializeEntity());
    }
  }, [id]); // Abhängigkeiten zu useEffect hinzugefügt

   useEffect(() => {
      if (leaderid!=null && leaderid!=undefined)
      {
          let url = `${configModule.url()}/CHARACTER/${leaderid}/true`;
          //console.log("loading leader data with url: " + url);
          setIsLoading(true);
          const fetchData = async () => {
            try {
              const response = await fetch(url, { headers });
              if (!response.ok) {
                if (response.status === 401) {
                  configModule.clearUserAuth();
                  window.location.href = process.env.PUBLIC_URL + "/overview";
                  console.error("Authentication not ok or lost.");
                }
                throw new Error('Fehler beim Laden der Daten: ' + response.status);
              }
              else
              {
                  const data = await response.json();
                  //console.log('Fetched data:', data);
                  createNewEntity(data);
                  setNewList(true);
                  setIsLoading(false);
              }
            } catch (error) {
              console.error('Error fetching data:', error);
              setIsLoading(false);
            }
          };
          fetchData();
      }
    }, [leaderid]); // Abhängigkeiten zu useEffect hinzugefügt


  function store() {
    localStorage.setItem('END_GAMELIST_FREE_LAST', JSON.stringify(entity));
    localStorage.setItem('END_GAMELIST_SELECTED_FRACTION', selectedFaction);
    localStorage.setItem('END_GAMELIST_ALIGNMENT_FILTER', alignmentFilter);
    localStorage.setItem('END_GAMELIST_SELECTED_TYPE', selectedType);
    localStorage.setItem('END_GAMELIST_SHOW_NPCS', JSON.stringify(showNPCs));
  }

  useEffect(() => {
    store();
  }, [alignmentFilter, selectedType, showNPCs, selectedFaction, entity]);

  function loadStoredList() {
    const stored = localStorage.getItem('END_GAMELIST_FREE_LAST');
    return stored ? JSON.parse(stored) : null;
  }

  function load() {
    const storedEntity = localStorage.getItem('END_GAMELIST_FREE_LAST');
    if (storedEntity) {
      setEntity(JSON.parse(storedEntity));
    }

    const storedFraction = localStorage.getItem('END_GAMELIST_SELECTED_FRACTION');
    if (storedFraction) {
      setSelectedFaction(storedFraction);
    }

    const storedAlignmentFilter = localStorage.getItem('END_GAMELIST_ALIGNMENT_FILTER');
    if (storedAlignmentFilter) {
      setAlignmentFilter(storedAlignmentFilter);
    }

    const storedSelectedType = localStorage.getItem('END_GAMELIST_SELECTED_TYPE');
    if (storedSelectedType) {
      setSelectedType(storedSelectedType);
    }

    const storedShowNPCs = localStorage.getItem('END_GAMELIST_SHOW_NPCS');
    if (storedShowNPCs) {
      setShowNPCs(JSON.parse(storedShowNPCs));
    }
  }

  useEffect(() => {
    if (id!=undefined || leaderid!=undefined)
    {
        //console.log("not loading stored content, since loaded list or leader given.");
    }
    else {
        //console.log("loading stored content, since loaded list or leader given. Reset?", id, leaderid);
        load();
    }
  }, []);

  const filteredAvailableUnits = availableUnits.filter(unit =>
    (filter.alignment === '' || unit.alignment === filter.alignment) &&
    (filter.type === '' || unit.type === filter.type)
  );

  const handleFactionChange = (event) => {
    setSelectedFaction(event.target.value);
    // Optional: Setze die Einheitenliste zurück, wenn die Fraktion geändert wird
    setAvailableUnits([]);
  };

  const handleTypeFilter = (event) => {
    setSelectedType(event.target.value);
    //console.log(event.target.value)
    // Optional: Setze die Einheitenliste zurück, wenn die Fraktion geändert wird
    //setAvailableUnits([]);
  };

  const handleAlignmentFilter = (filter) => {
     setAlignmentFilter(filter);
     //console.log(filter)
     // Optional: Setze die Einheitenliste zurück, wenn die Fraktion geändert wird
     //setAvailableUnits([]);
   };

  useEffect(() => {
      //console.log("loading fractions")
      setIsLoading(true);
      const url = configModule.url()+"/FRACTION"
      // Hier sollte der REST-Call stattfinden und die Daten in dataList gespeichert werden
      const fetchFractions = async () => {
        try {
          const langCode = navigator.language.split('-')[0].toUpperCase();
          const response = await fetch(url, { headers });
          // console.log(response)
          if (!response.ok) {
            if (response.status===401) {
                  configModule.clearUserAuth()
                  window.location.href = process.env.PUBLIC_URL+"/overview"
                  console.error("Authentication not ok or lost.")
            }
            throw new Error('Fehler beim Laden der Daten: '+response.status);
          }
          const data = await response.json();
          setFractions(data); // Daten im Zustand speichern
          //console.log('Fetched fractions:', data); // Konsolenausgabe der empfangenen Daten
          setIsLoading(false);
        } catch (error) {
          console.error('Error fetching fractions:', error);
          setIsLoading(false);
        }
      };

      fetchFractions();
  }, []);

  useEffect(() => {
    //console.log("selected fractions -> loading units")
    if (selectedFaction) {
        setIsLoading(true);
        //console.log(selectedFaction);
        const url = configModule.url()+`/UNIT/fraction/${selectedFaction}`;
        // Hier sollte der REST-Call stattfinden und die Daten in dataList gespeichert werden
        const fetchUnits = async () => {
          try {
            const langCode = navigator.language.split('-')[0].toUpperCase();
            const response = await fetch(url, { headers });
            // console.log(response)
            if (!response.ok) {
              if (response.status===401) {
                    configModule.clearUserAuth()
                    window.location.href = process.env.PUBLIC_URL+"/overview"
                    console.error("Authentication not ok or lost.")
              }
              throw new Error('Fehler beim Laden der Daten: '+response.status);
            }
            const data = await response.json();
            setAvailableUnits(data); // Daten im Zustand speichern
            //console.log('Fetched unit data:', data); // Konsolenausgabe der empfangenen Daten
            setIsLoading(false);
          } catch (error) {
            console.error('Error fetching unit data:', error);
            setIsLoading(false);
          }
        };

      if (selectedFaction) {
        fetchUnits();
      }
    }
  }, [selectedFaction]);

 useEffect(() => {
    //console.log("apply filter")
    const filteredUnits = availableUnits.filter(unit => {
      // Filtere basierend auf dem Alignment-Filter
      const alignmentMatch = alignmentFilter === 'good' ? unit.modelCommon.alignment >= 0 :
                             alignmentFilter === 'evil' ? unit.modelCommon.alignment <= 0 : true;

      // Überprüfe, ob die Einheit als NPC markiert ist
      var npcMatch = true
      if (unit.npc === true)
      {
        if (!showNPCs) npcMatch = false
      }

      var typeMatch = true
      if (selectedType != "ALL") typeMatch = (unit.type === selectedType ? true : false)

      return alignmentMatch && npcMatch && typeMatch;
    });

    setDisplayedUnits(filteredUnits);
  }, [alignmentFilter, availableUnits, showNPCs, selectedType ]);

    const getFractionName = (fraction, language) => {
      const nameObject = fraction.texts.find(text => text.type === "NAME" && text.language === language);
      return nameObject ? nameObject.text : "???";
    };

  const handleTitleChange = (texts) => {
     //console.log("updating texts with: ",texts)
        setEntity(entity => ({
          ...entity,
          texts: texts
        }));
  }


  const handleUnitDeletion = (unitIn) => {
      //console.log("deletion");
      let newUnits = entity.units.map(u =>
        u.unit === unitIn ? { ...u, count: u.count - 1 } : u
      ).filter(u => u.count > 0); // Entferne Einheiten mit Anzahl 0

      // Aktualisiere die Punktzahl
      const newPoints = calculateTotalPoints(newUnits, entity.leader);

      // Aktualisiere den State (oder die übergeordnete Verwaltung von `entity`)
      setEntity(prevEntity => ({
        ...prevEntity,
        units: newUnits,
        points: newPoints
      }));
  };

  const handleUnitSelection = (unitIn) => {
    //console.log("adding unit")
    const existingUnit = entity.units.find(u => u.unit === unitIn);

    let newUnits;
    if (existingUnit) {
      // Erhöhe die Anzahl und berechne neue Punktzahl
      newUnits = entity.units.map(u =>
        u.unit === unitIn? { ...u, count: u.count + 1 } : u
      );
    } else {
      // Füge neue Einheit hinzu und berechne neue Punktzahl
      newUnits = [...entity.units, { count: 1, unit: unitIn }];
    }
    //console.log(newUnits)
    const newPoints = calculateTotalPoints(newUnits, entity.leader);
      // Aktualisiere den Zustand von `entity` mit den neuen Units und Points
      setEntity(prevEntity => ({
        ...prevEntity,
        units: newUnits,
        points: newPoints
      }));
    };

  const handleLeaderSelection = (leaderId) => {
      setEntity(prevSetup => ({
        ...prevSetup,
        leader: leaderId
      }));
  };

  const calculateTotalPoints = (units, leader) => {
      let totalPoints = units.reduce((sum, unit) => sum + (unit.count * unit.unit.points), 0);

      // Füge Punkte des Anführers hinzu, falls vorhanden
      if (leader) {
        totalPoints += leader.points;
      }

      return totalPoints;
  };


  const handleSave = () => {

      const objectToSend = {
        visibility: entity.metadata.visibility,
        gameTypes: entity.gameTypes,
        settings: entity.settings,
        texts: entity.texts,
        leader: entity.leader?entity.leader.uuid:null,
        units: [],
      };

      // add units
      objectToSend.units = entity.units.map(listEntry => ({
          unit: listEntry.unit.uuid,
          count: listEntry.count,
      }));

      const body = JSON.stringify(objectToSend);

      setIsLoading(true);
      const saveEntity = async () => {
            try {
               let url = `${configModule.url()}/GAMELIST`;
               //console.log("sending new: ",objectToSend)
               //console.log("sending body: ",body)
               const response = await fetch(url,
                  {
                    method: 'PUT',
                    headers: headers,
                    body: body, // Füge den Body hier ein
                  }
               );
               if (!response.ok) {
                   if (response.status === 401) {
                     configModule.clearUserAuth();
                     window.location.href = process.env.PUBLIC_URL + "/overview";
                     console.error("Authentication not ok or lost.");
                   }
                   throw new Error('Fehler beim Speichern der Daten: ' + response.status);
               }

               const data = await response.json();
               //console.log("got added entity: ",data)
               setEntity(
                  {...entity, uuid: data.uuid, metadata: data.metadata}
               )
               setIsLoading(false);
               return true;

            } catch (error) {
                console.error('Error saving entity:', error);
                setIsLoading(false);
            }
      };

      const updateEntity = async () => {
              try {
                 let url = `${configModule.url()}/GAMELIST/${entity.uuid}`;
                 //console.log("sending update: ",objectToSend)
                 //console.log("sending body: ",body)
                 const response = await fetch(url,
                    {
                      method: 'POST',
                                  headers: headers,
                      body: body, // Füge den Body hier ein
                    }
                 );
                 if (!response.ok) {
                     if (response.status === 401) {
                       configModule.clearUserAuth();
                       window.location.href = process.env.PUBLIC_URL + "/overview";
                       console.error("Authentication not ok or lost.");
                     }
                     throw new Error('Fehler beim Speichern der Daten: ' + response.status);
                 }

                 const data = await response.json();
                 //console.log("got changed entity: ",data)
                 setEntity(
                    {...entity, uuid: data.uuid}
                 )
                 setIsLoading(false);
                 return true;

              } catch (error) {
                  console.error('Error saving entity:', error);
                  setIsLoading(false);
              }
        };
      if (newList)
      {
        //console.log("saving: ",entity);
        saveEntity();
      }
      else
      {
        //console.log("updating: ",entity);
        updateEntity();
      }
  }

  const handleDeleteList = () => {
       if (entity.uuid!=null && entity.uuid!=undefined)
       {
           setIsLoading(true);
            let url = `${configModule.url()}/GAMELIST/${entity.uuid}`;
              const exists = async () => {
                    const response = await fetch(url,
                       {
                         method: 'HEAD', // Verwende POST oder PUT, je nach API-Anforderungen
                         headers: headers
                       }
                    );
                   if (!response.ok) {
                    if (response.status === 404) {
                       setIsLoading(false);
                       return true
                    }
                    else if (response.status === 401) {
                            configModule.clearUserAuth();
                            window.location.href = process.env.PUBLIC_URL + "/overview";
                            console.error("Authentication not ok or lost.");
                    }
                    setIsLoading(false);
                    throw new Error('Fehler beim Laden der Daten: ' + response.status);
                   }
                   else{
                       setIsLoading(false);
                       return true;
                   }
              };

              Promise.all([exists()]).then(([exists]) => {
                   if (exists)
                   {
                       //console.log("entity existing, deleting: ",entity.uuid);
                       const deleteEntity = async () => {
                            const response = await fetch(url,
                                {
                                  method: 'DELETE', // Verwende POST oder PUT, je nach API-Anforderungen
                                  headers: headers
                                }
                             );
                            if (!response.ok) {
                             if (response.status === 404) {
                                setIsLoading(false);
                                return true
                             }
                             else if (response.status === 401) {
                                     configModule.clearUserAuth();
                                     window.location.href = process.env.PUBLIC_URL + "/overview";
                                     console.error("Authentication not ok or lost.");
                             }
                             setIsLoading(false);
                             throw new Error('Fehler beim Laden der Daten: ' + response.status);
                            }

                            setIsLoading(false);
                      };
                      deleteEntity();
                   }
              })
              .catch(error => {
                   setIsLoading(false);
                   console.error('Fehler beim Löschen:', error);
              });
       }
  }

   const handleClear = () => {
       let ret = createInitialList();
       ret = {...ret, metadata: { visibility: "PRIVATE",owner: configModule.getUserUUID() }, leader:null, points:0 }
       setEntity(ret);
       store();
    }

  const handleBack = () => {
       //console.log("going back to: /overview");
       navigate(returnUrl);
  }

  return (
    <div className="gamelistcreator-body">
           <div className="gamelistfilter no-print">
                <div className="gamelist-fraction">
                   <select className="endDropdown" id="faction-select" value={selectedFaction} onChange={handleFactionChange}>
                           <option value="">{translate('gamelist.select')}</option>
                           {fractions && fractions.map(fraction => (
                                   <option key={fraction.uuid} value={fraction.uuid}>
                                     {getFractionName(fraction, browserLanguage)}
                                   </option>
                           ))}
                    </select>
                </div>
                <div className="gamelist-alignment">
                    <label>
                      <input
                        type="radio"
                        name="alignment"
                        value="gut"
                        checked={alignmentFilter === 'good'}
                        onChange={() => handleAlignmentFilter('good')}
                      />
                      {translate('gamelist.select.alignment.good')}
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="alignment"
                        value="böse"
                        checked={alignmentFilter === 'evil'}
                        onChange={() => handleAlignmentFilter('evil')}
                      />
                      {translate('gamelist.select.alignment.evil')}
                    </label>
                     <label>
                      <input
                        type="radio"
                        name="alignment"
                        value="all"
                        checked={alignmentFilter === 'all'}
                        onChange={() => handleAlignmentFilter('all')}
                      />
                      {translate('gamelist.select.alignment.all')}
                    </label>

                    <label>
                          <input
                            type="checkbox"
                            checked={showNPCs}
                        onChange={(e) => setShowNPCs(e.target.checked)}
                      />
                      {translate('gamelist.select.npcs.show')}
                    </label>
                </div>
                <div className="gamelist-type">
                    <select className="endDropdown"  id="type-select" value={selectedType} onChange={handleTypeFilter}>
                           <option value="ALL">{translate('gamelist.select.type.all')}</option>
                           <option value="COMMANDER">{translate('gamelist.select.type.commander')}</option>
                           <option value="CHAMPION">{translate('gamelist.select.type.champion')}</option>
                           <option value="INFANTRY">{translate('gamelist.select.type.infantry')}</option>
                           <option value="SHOOTER">{translate('gamelist.select.type.shooter')}</option>
                    </select>
                </div>
           </div>
           <div className="gamelistselection">
               <div className="gamelistunits no-print">
                    <h3>{translate(`gamelist.title.units`)}</h3>
                    {isLoading ? (
                       <>
                          <Wait />
                       </>
                    ) : (
                      <>
                        { selectedFaction && (
                            <div className="gamelistunits-list">
                                {displayedUnits.map(unit => (
                                 <div key={unit.id} className="gamelistunits-listitem">
                                 <div >
                                    <img
                                    className="button-add"
                                    src={`${process.env.PUBLIC_URL}/icons/generic_icons/add.png`}
                                    alt="add"
                                    onClick={() => handleUnitSelection(unit)}
                                    />
                                 </div>
                                 <div className="glu-listitem-text">
                                    { unit.texts.find((text) => text.type === "NAME" && text.language === browserLanguage)?.text || "" }
                                 </div>
                                </div>
                                ))}
                            </div>
                        )}
                      </>
                    )}
                </div>
                <div className="gamelistgl">
                  <GameList entityIn={entity} browserLanguage={browserLanguage} edit={true} handleUnitDeletion={handleUnitDeletion} handleTitleChange={handleTitleChange}/>
                </div>
           </div>
           {entity && entity.metadata && (
             <>
                <Actionbar style="savebar no-print" handleSave={handleSave} handleBack={handleBack} handleDelete={id && handleDeleteList} handleClear={handleClear} visibility={entity.metadata.visibility} owner={entity.metadata.owner}/>
                <div className="spacer-100"/>
             </>
           )}
    </div>
  );
}

export default ListEditor;