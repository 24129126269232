// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `li {
  max-width: 100%; /* Bild passt zur Breite der Box */
  margin: 5px;
  text-align: left;
}

.property-description {
  font-size: 16px; /* Ändern Sie die Schriftgröße nach Bedarf */
  line-height: 1.2; /* Ändern Sie den Zeilenabstand nach Bedarf */
  text-align:left;
  padding:0px;
}

.property-rules {
  font-size: 16px; /* Ändern Sie die Schriftgröße nach Bedarf */
  line-height: 1.2; /* Ändern Sie den Zeilenabstand nach Bedarf */
  text-align:left;
  padding:0px;
}

.property-title {
  font-size: 16px; /* Ändern Sie die Schriftgröße nach Bedarf */
  line-height: 1.2; /* Ändern Sie den Zeilenabstand nach Bedarf */
  text-align:center;
  padding:00px;
}

.miniature-image{
}

.property {
  border-style:solid;
  border-color:gray;
  margin:5px;
  padding:5px;
}

.property-body {
   display: flex;
   justify-content: left;
   align-items: center;
   flex-flow: row wrap;
   align-self: left;
   margin:10px;
}

.property-body-sub {
   display: flex;
   justify-content: center;
   max-width: 250px;
   align-items: center;
   flex-flow: column wrap;
   align-self: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Property.css"],"names":[],"mappings":"AAAA;EACE,eAAe,EAAE,kCAAkC;EACnD,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,eAAe,EAAE,4CAA4C;EAC7D,gBAAgB,EAAE,6CAA6C;EAC/D,eAAe;EACf,WAAW;AACb;;AAEA;EACE,eAAe,EAAE,4CAA4C;EAC7D,gBAAgB,EAAE,6CAA6C;EAC/D,eAAe;EACf,WAAW;AACb;;AAEA;EACE,eAAe,EAAE,4CAA4C;EAC7D,gBAAgB,EAAE,6CAA6C;EAC/D,iBAAiB;EACjB,YAAY;AACd;;AAEA;AACA;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,UAAU;EACV,WAAW;AACb;;AAEA;GACG,aAAa;GACb,qBAAqB;GACrB,mBAAmB;GACnB,mBAAmB;GACnB,gBAAgB;GAChB,WAAW;AACd;;AAEA;GACG,aAAa;GACb,uBAAuB;GACvB,gBAAgB;GAChB,mBAAmB;GACnB,sBAAsB;GACtB,kBAAkB;AACrB","sourcesContent":["li {\n  max-width: 100%; /* Bild passt zur Breite der Box */\n  margin: 5px;\n  text-align: left;\n}\n\n.property-description {\n  font-size: 16px; /* Ändern Sie die Schriftgröße nach Bedarf */\n  line-height: 1.2; /* Ändern Sie den Zeilenabstand nach Bedarf */\n  text-align:left;\n  padding:0px;\n}\n\n.property-rules {\n  font-size: 16px; /* Ändern Sie die Schriftgröße nach Bedarf */\n  line-height: 1.2; /* Ändern Sie den Zeilenabstand nach Bedarf */\n  text-align:left;\n  padding:0px;\n}\n\n.property-title {\n  font-size: 16px; /* Ändern Sie die Schriftgröße nach Bedarf */\n  line-height: 1.2; /* Ändern Sie den Zeilenabstand nach Bedarf */\n  text-align:center;\n  padding:00px;\n}\n\n.miniature-image{\n}\n\n.property {\n  border-style:solid;\n  border-color:gray;\n  margin:5px;\n  padding:5px;\n}\n\n.property-body {\n   display: flex;\n   justify-content: left;\n   align-items: center;\n   flex-flow: row wrap;\n   align-self: left;\n   margin:10px;\n}\n\n.property-body-sub {\n   display: flex;\n   justify-content: center;\n   max-width: 250px;\n   align-items: center;\n   flex-flow: column wrap;\n   align-self: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
