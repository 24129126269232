import React, { useState } from "react";
import './UnitEditor.css'; // Stil für die Box
import { useLanguage } from '../LanguageContext';
import ItemSet from './ItemSet'; // Importieren Sie die Box-Komponente

function EditorArmor({ currentEntity, sets, langCode, handleChange, styleToSet }) {
  const { translate } = useLanguage();
  const armors = sets && sets.filter(item => item.type === "ARMOR");

  return (
     <div className={styleToSet}>
       <div className='unit-body'>
           <div>{translate('edit.armor')}:&nbsp;</div>
           <select
               className="endDropdown"
               value={ (currentEntity && currentEntity.armor)?currentEntity.armor.uuid:''}
               onChange={handleChange}
           >
               <option value="">{translate('edit.armor.select')}</option>
               {armors && armors.map((item) => {
                  const itemName = item.texts.find(text => text.language === langCode && text.type === 'NAME')?.text || '';
                  return <option key={item.uuid} value={item.uuid}>{itemName}</option>;
               })}
           </select>
       </div>
       <ItemSet entity={currentEntity.armor} browserLanguage={langCode} styleToSet=""/>
     </div>
    );
}

export default EditorArmor;
