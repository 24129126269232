// WelcomePage.js

import '../App.css';
import React, { useState } from "react";
import configModule from '../config';
import { useLanguage } from '../LanguageContext';
import { Link } from 'react-router-dom'; // Importiere die Link-Komponente von react-router-dom

function Login() {
  const url = configModule.baseUrl()+"/v1/login"
  const urlIntrospect = configModule.baseUrl()+"/v1/user/introspect"
  const { translate } = useLanguage();
  const [code, setCode] = useState('');
  const handleInputChange = (event) => { setCode(event.target.value); };

  const introspect = async () => {
      try {
        const headers = new Headers({
              'Accept': 'application/json',
              'Authorization': configModule.getAuthHeader()
        });

        const response = await fetch(urlIntrospect, {
              method: 'GET',
              headers: headers
            });

        // console.log(response)
        if (!response.ok) {
          // Hier wird ein Fehler für 400er Statuscodes abgefangen
          configModule.clearUserAuth()
          throw new Error('Fehler beim Laden der Daten: '+response.status);
        }
        const data = await response.json();
        console.log('Fetched user data:', data); // Konsolenausgabe der empfangenen Daten
        // speichere in config
        configModule.config.userIntrospect = data
        configModule.config.loggedIn = true
        configModule.storeSettings()
      } catch (error) {
        console.error('Error fetching data:', error);
        configModule.clearUserAuth()
      }
       window.location.href = process.env.PUBLIC_URL+"/overview"
    };

  const fetchData = async () => {
        try {
          const headers = new Headers({
                'Accept': 'application/json',
                'code': code,
                'Authorization': configModule.getAnonymousAuthHeader()
          });

          const response = await fetch(url, {
                method: 'POST',
                headers: headers
              });

          // console.log(response)
          if (!response.ok) {
            // Hier wird ein Fehler für 400er Statuscodes abgefangen
            configModule.clearUserAuth()
            throw new Error('Fehler beim Laden der Daten: '+response.status);
          }
          const data = await response.json();
          console.log('Fetched data:', data); // Konsolenausgabe der empfangenen Daten
          // speichere die Daten als login in die config
          const id = data.uuid;
          const passwort = data.token;

          // Kombiniere sie mit einem Doppelpunkt
          const kombiniert = `${id}:${passwort}`;

          // Kodiere in Base64
          const encoded = btoa(kombiniert);

          // speichere in config
          configModule.config.userAuth = `otp ${encoded}`

          // rufe introspect auf
          introspect()
        } catch (error) {
          console.error('Error fetching data:', error);
          configModule.clearUserAuth()
        }
      };


  return (
    <div>
        <div>
          <p>{translate('login.description')}</p>
        </div>

       <input
           type="text"
           value={code}
           className="Code-Textfield"
           onChange={handleInputChange}
           placeholder={translate('welcome.placeholdercode')}
         />

        <button className="endButton"  onClick={fetchData}>{translate('news.enter')}</button>
   </div>
  );
}

export default Login;