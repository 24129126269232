import React, { useState, useEffect } from "react";
import './Filterbar.css'; // Stil für die Box
import configModule from '../config';
import { useLanguage } from '../LanguageContext';

function Filterbar({ fractions, handleFilterChange, filterStorage, enableNPC, enableOwn, enableAlignment, enableUnitType }) {
  const { translate } = useLanguage();
  const browserLanguage = configModule.config.lang.toUpperCase()

  const getInitialStateJSON = (key, defaultValue) => {
    const storedValue = localStorage.getItem(key);
    return storedValue !== null ? JSON.parse(storedValue) : defaultValue;
  };

  const [filter, setFilter] = useState(getInitialStateJSON(filterStorage,
        {
          alignment: '',
          showNPCs: true,
          showOwn: false,
          type: 'ALL',
          selectedFaction: null
        }));

  const [alignmentFilter, setAlignmentFilter] = useState('');
  const [selectedType, setSelectedType] = useState('ALL');
  const [selectedFaction, setSelectedFaction] = useState('');
  const [showNPCs, setShowNPCs] = useState(false);
  const [showOwn, setShowOwn] = useState(false);

  function store() {
     localStorage.setItem(filterStorage, JSON.stringify(filter));
  }

  useEffect(() => {
      setFilter(
         {
          alignment: alignmentFilter,
          showNPCs: showNPCs,
          showOwn: showOwn,
          type: selectedType,
          fraction: selectedFaction
        }
      );
      store();
  }, [alignmentFilter, selectedType, showNPCs, showOwn, selectedFaction]);

  useEffect(() => {
      handleFilterChange(filter);
  }, [filter]);

  const getName = (entityIn, language) => {
     const nameObject = entityIn && entityIn.text && entityIn.texts.find(text => text.type === "NAME" && text.language === language);
     return nameObject ? nameObject.text : "???";
  };

  return (
      <>
        <div className='filterbar'>
          <div className="filterbar-body">
              {fractions && (
                <div className="gamelist-fraction">
                    <select className="endDropdown" id="faction-select" value={selectedFaction} onChange={(e) => setSelectedFaction(e.target.value)}>
                         <option value="">{translate('gamelist.select')}</option>
                         {fractions && fractions.map(fraction => (
                                 <option key={fraction.uuid} value={fraction.uuid}>
                                   {getName(fraction, browserLanguage)}
                                 </option>
                         ))}
                    </select>
                </div>
                )
              }
              {enableAlignment && (
                 <div className="filterbar-alignment">
                <label>
                  <input
                    type="radio"
                    name="alignment"
                    value="good"
                    checked={alignmentFilter === 'good'}
                    onChange={() => setAlignmentFilter('good')}
                  />
                  {translate('filter.select.alignment.good')}
                </label>
                <label>
                  <input
                    type="radio"
                    name="alignment"
                    value="evil"
                    checked={alignmentFilter === 'evil'}
                    onChange={() => setAlignmentFilter('evil')}
                  />
                  {translate('filter.select.alignment.evil')}
                </label>
                 <label>
                  <input
                    type="radio"
                    name="alignment"
                    value="all"
                    checked={alignmentFilter === 'all'}
                    onChange={() => setAlignmentFilter('all')}
                  />
                  {translate('filter.select.alignment.all')}
                </label>
              </div>
              )}
              {enableNPC && (
                  <label className="filterbar-checkbox">
                    <input
                      type="checkbox"
                      checked={showNPCs}
                      onChange={(e) => setShowNPCs(e.target.checked) }
                    />
                    {translate('filter.select.npcs')}
                  </label>
              )}
              {enableOwn && (
                  <label className="filterbar-checkbox">
                     <input
                       type="checkbox"
                       checked={showOwn}
                       onChange={(e) => setShowOwn(e.target.checked) }
                     />
                     {translate('filter.select.own')}
                  </label>
              )}
              {enableUnitType && (
                  <div className="filterbar-type">
                    <select className="endDropdown"  id="type-select" value={selectedType} onChange={(e) => setSelectedType(e.target.value) }>
                        <option value="ALL">{translate('filter.select.type.all')}</option>
                        <option value="COMMANDER">{translate('filter.select.type.commander')}</option>
                        <option value="CHAMPION">{translate('filter.select.type.champion')}</option>
                        <option value="INFANTRY">{translate('filter.select.type.infantry')}</option>
                        <option value="SHOOTER">{translate('filter.select.type.shooter')}</option>
                        <option value="MONSTER">{translate('filter.select.type.monster')}</option>
                        <option value="VEHICLE">{translate('filter.select.type.vehicle')}</option>
                        <option value="BIKE">{translate('filter.select.type.bike')}</option>
                        <option value="SHIP">{translate('filter.select.type.ship')}</option>
                        <option value="SPACE_SHIP">{translate('filter.select.type.spaceship')}</option>
                    </select>
                  </div>
              )}
          </div>
      </div>
      </>
    );
}

export default Filterbar;
