// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Boxen */
.help {
  display: flex;
  max-width: 45em;
  align-items: start;
  flex-flow: column wrap;
  align-self: normal;
  padding-left:50px;
  padding-right:50px;
}

.help_icontable{
    text-align:left;
    margin:10px;
}

.help_tablecell{
    padding:5px;
}

.help_range_desc{
  text-align:left;
}

.searchFieldFAQ{
   text-align:left;
   background-color: #888888;
   border: none;
   padding: 15px 32px;
   text-decoration: none;
   display: inline-block;
   font-size: 18px;
   margin: 4px 2px;
   cursor: pointer;
   border-radius: 8px; /* Abgerundete Ecken */
   width:90%;
}
`, "",{"version":3,"sources":["webpack://./src/Help.css"],"names":[],"mappings":"AAAA,UAAU;AACV;EACE,aAAa;EACb,eAAe;EACf,kBAAkB;EAClB,sBAAsB;EACtB,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;IACI,eAAe;IACf,WAAW;AACf;;AAEA;IACI,WAAW;AACf;;AAEA;EACE,eAAe;AACjB;;AAEA;GACG,eAAe;GACf,yBAAyB;GACzB,YAAY;GACZ,kBAAkB;GAClB,qBAAqB;GACrB,qBAAqB;GACrB,eAAe;GACf,eAAe;GACf,eAAe;GACf,kBAAkB,EAAE,sBAAsB;GAC1C,SAAS;AACZ","sourcesContent":["/* Boxen */\n.help {\n  display: flex;\n  max-width: 45em;\n  align-items: start;\n  flex-flow: column wrap;\n  align-self: normal;\n  padding-left:50px;\n  padding-right:50px;\n}\n\n.help_icontable{\n    text-align:left;\n    margin:10px;\n}\n\n.help_tablecell{\n    padding:5px;\n}\n\n.help_range_desc{\n  text-align:left;\n}\n\n.searchFieldFAQ{\n   text-align:left;\n   background-color: #888888;\n   border: none;\n   padding: 15px 32px;\n   text-decoration: none;\n   display: inline-block;\n   font-size: 18px;\n   margin: 4px 2px;\n   cursor: pointer;\n   border-radius: 8px; /* Abgerundete Ecken */\n   width:90%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
