import React, { useState, useEffect } from 'react';
import configModule from '../config';

function Image({ classNameIn, type, id, size, fallbackUrl, title }) {
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    const fetchImage = async () => {
      const url = `${configModule.imageUrl()}/${type}/${id}/size/${size}`;

      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: new Headers({
            'Authorization': configModule.getAuthHeader(),
          })
        });

        if (response.ok) {
          // Wenn der Response-Status im Bereich 200-299 liegt
          const imageBlob = await response.blob();
          const imageObjectURL = URL.createObjectURL(imageBlob);
          setImageUrl(imageObjectURL);
        } else if (response.status === 404) {
          // Spezielle Behandlung für 404
          setImageUrl(fallbackUrl ? fallbackUrl : `${process.env.PUBLIC_URL}/transparent.png`);
        } else {
          // Für alle anderen Fehler
          throw new Error(`Network response was not ok (${response.status}).`);
        }

      } catch (error) {
        // Allgemeine Fehlerbehandlung
        console.error('Error loading image:', error);
        setImageUrl(fallbackUrl ? fallbackUrl : `${process.env.PUBLIC_URL}/transparent.png`);
      }
    };

    fetchImage();
  }, [type, id, size, fallbackUrl]);

  return (
    <img
      className={classNameIn}
      src={imageUrl}
      alt={title}
      onError={() => setImageUrl(fallbackUrl ? fallbackUrl : `${process.env.PUBLIC_URL}/transparent.png`)}
    />
  );
}

export default Image;
