import React from "react";
import './EntityCommon.css';
import { useLanguage } from '../LanguageContext';
import { Link } from 'react-router-dom';

function EntityCommonVEdit({ attributes, modelCommon, points, browserLanguage, styleToSet, onUpdateAttributes, onUpdateModelCommon }) {
  const { translate } = useLanguage();

  const handleHPChange = (e) => {
    const newHP = Number(e.target.value);
    onUpdateAttributes({...attributes, hitpoints: newHP}); // Annahme: onUpdate aktualisiert die gesamten entitycommon-Daten in der übergeordneten Komponente
  };

  const handleMaxEquipmentChange = (e) => {
    const newMaxEquipment = Number(e.target.value);
    onUpdateModelCommon({...modelCommon, maxEquipment: newMaxEquipment});
  };

  const handleAlignmentChange = (e) => {
    const newAlignment = Number(e.target.value)
    onUpdateModelCommon({...modelCommon, alignment: Math.max(-0.5, Math.min(0.5, parseFloat(newAlignment)))}); // Begrenzt den Wert zwischen -0.5 und 0.5
  };

  // Erstellt Optionen für Dropdown-Menüs
  const createOptions = (maxValue) => {
    let options = [];
    for(let i = 0; i <= maxValue; i++) {
      options.push(<option key={i} value={i}>{i}</option>);
    }
    return options;
  };

  return (
    <div className={styleToSet}>
      {modelCommon && (
        <table className='ec-table'>
          <tbody>
            <tr><td>
                <Link to={`/help`}><img className="ec-image" src={`${process.env.PUBLIC_URL}/icons/generic_icons/info.png`} alt="info" /></Link>
            </td></tr>
            <tr><td>
                <img className="ec-image" src={`${process.env.PUBLIC_URL}/icons/attributes/size.png`} alt="size" />
                <div className="ec-value attribute-color-size">{modelCommon.size}</div>
            </td></tr>
            <tr><td>
                <img className="ec-image" src={`${process.env.PUBLIC_URL}/icons/attributes/gold_cost.png`} alt="gold cost" />
                <div className="ec-value attribute-color-gold">{points}</div>
            </td></tr>


            <tr><td>
              <img className="ec-image" src={`${process.env.PUBLIC_URL}/icons/attributes/HP.png`} alt="hit points" />
              <select
                className="ec-edit attribute-color-hp"
                value={attributes.hitpoints}
                onChange={handleHPChange}
              >
                {createOptions(10)}
              </select>
            </td></tr>

           <tr><td>
             <img className="ec-image" src={`${process.env.PUBLIC_URL}/icons/attributes/alignment.png`} alt="alignment" />
             <input
               type="number"
               className="ec-edit attribute-color-alignment"
               value={modelCommon.alignment}
               step="0.1" // Erlaubt feinere Anpassungen
               min={modelCommon.alignment - 0.5}
               max={modelCommon.alignment + 0.5}
               onChange={handleAlignmentChange}
             />
           </td></tr>

            <tr><td>
                <img className="ec-image" src={`${process.env.PUBLIC_URL}/icons/action_icons/items.png`} alt="max. equipment" />
                <div className="ec-value attribute-color-items">{modelCommon.maxEquipment}</div>
            </td></tr>
          </tbody>
        </table>
      )}
      {!modelCommon && (
        <div> ??? </div>
      )}
    </div>
  );
}

export default EntityCommonVEdit;
