// Imprint.js
import React, { useState, useEffect } from 'react';
import { useLanguage } from './LanguageContext';
import { Link } from 'react-router-dom'; // Importiere die Link-Komponente von react-router-dom
import Imprint from './Imprint';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfUse from './TermsOfUse';
import Credits from './Credits';
import CookieUsage from './CookieUsage';

function CreditsImprint() {

  return (
    <div>
      <Credits />
      <TermsOfUse />
      <PrivacyPolicy />
      <CookieUsage />
      <Imprint />
    </div>
  );
}

export default CreditsImprint;