import './App.css';
import { useLanguage } from './LanguageContext';
import About from './About';
function ComingSoonPage() { // Die Funktion onTermsAccept sollte hier aus den Props extrahiert werden
  const { translate } = useLanguage();

  return (

    <div className="Welcome-Body">
         <div className="header-content">
            <img
                src={`${process.env.PUBLIC_URL}/end_logo_trans_medium.png`}
                className="App-logo-small"
                alt="logo"
            />
          </div>
           <div className="header-content">
          <img
              src={`${process.env.PUBLIC_URL}/phase_1_faded.png`}
              className="App-logo-small"
              alt="logo"
          />
        </div>
        <h2>{translate('rules.title.chapter.1')}</h2>
        <p>{translate('coming.soon')}</p>
        <a href='https://www.hamburger-tactica.de/' target='_blank'><p>{translate('coming.soon.tactica')}</p></a>

        <About />
    </div>
  );
}

export default ComingSoonPage;
