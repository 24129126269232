import React, { useState } from "react";
import './EntityCommon.css'; // Stil für die Box
import { useLanguage } from '../LanguageContext';

function EntityCommonV({ entitycommon, points, hp, browserLanguage, styleToSet }) {
  const { translate } = useLanguage();

  return (
      <div className={styleToSet}>
        {entitycommon && (
          <table className='ec-table'>
             <thead>
                 <th><img className="ec-image" src={`${process.env.PUBLIC_URL}/icons/attributes/gold_cost.png`} alt="gold cost" /></th>
                 <th><img className="ec-image" src={`${process.env.PUBLIC_URL}/icons/attributes/HP.png`} alt="hit points" /></th>
                 <th><img className="ec-image" src={`${process.env.PUBLIC_URL}/icons/attributes/size.png`} alt="size" /></th>
                 <th><img className="ec-image" src={`${process.env.PUBLIC_URL}/icons/attributes/alignment.png`} alt="alignment" /></th>
                 <th><img className="ec-image" src={`${process.env.PUBLIC_URL}/icons/action_icons/items.png`} alt="max. equipment" /></th>
              </thead>
            <tbody>
                <td className="ec-value attribute-color-gold">{points}</td>
                <td className="ec-value attribute-color-hp">{hp}</td>
                <td className="ec-value attribute-color-size">{entitycommon.size}</td>
                <td className="ec-value attribute-color-alignment">{entitycommon.alignment}</td>
                <td className="ec-value attribute-color-items">{entitycommon.maxEquipment}</td>
             </tbody>
          </table>
        )}
        {!entitycommon && (
            <div> ??? </div>
        )}
      </div>
    );
}

export default EntityCommonV;
