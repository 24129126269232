// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `li {
  max-width: 100%; /* Bild passt zur Breite der Box */
  margin: 5px;
  text-align: left;
}

.mission-good {
  padding:10px;
  border-style:solid;
  margin:20px;
  border-color:#4890a6;
}

.mission-evil {
  padding:10px;
  border-style:solid;
  margin:20px;
  border-color:#AA0000;
}

.mission-description {
  font-size: 16px; /* Ändern Sie die Schriftgröße nach Bedarf */
  line-height: 1.2; /* Ändern Sie den Zeilenabstand nach Bedarf */
  text-align:left;
  padding:0px;
}

.mission-title {
  font-size: 20px; /* Ändern Sie die Schriftgröße nach Bedarf */
  line-height: 1.2; /* Ändern Sie den Zeilenabstand nach Bedarf */
  text-align:center;
  padding:00px;
}

.fluff {
  font-size: 16px; /* Ändern Sie die Schriftgröße nach Bedarf */
  line-height: 1.2; /* Ändern Sie den Zeilenabstand nach Bedarf */
  text-align:left;
  padding-top:10px;
  padding-bottom:10px;
  font-style: italic;
}

.teaser-image{
    margin: auto;
    width: 90%;
}`, "",{"version":3,"sources":["webpack://./src/components/Mission.css"],"names":[],"mappings":"AAAA;EACE,eAAe,EAAE,kCAAkC;EACnD,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,WAAW;EACX,oBAAoB;AACtB;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,WAAW;EACX,oBAAoB;AACtB;;AAEA;EACE,eAAe,EAAE,4CAA4C;EAC7D,gBAAgB,EAAE,6CAA6C;EAC/D,eAAe;EACf,WAAW;AACb;;AAEA;EACE,eAAe,EAAE,4CAA4C;EAC7D,gBAAgB,EAAE,6CAA6C;EAC/D,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,eAAe,EAAE,4CAA4C;EAC7D,gBAAgB,EAAE,6CAA6C;EAC/D,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;IACI,YAAY;IACZ,UAAU;AACd","sourcesContent":["li {\n  max-width: 100%; /* Bild passt zur Breite der Box */\n  margin: 5px;\n  text-align: left;\n}\n\n.mission-good {\n  padding:10px;\n  border-style:solid;\n  margin:20px;\n  border-color:#4890a6;\n}\n\n.mission-evil {\n  padding:10px;\n  border-style:solid;\n  margin:20px;\n  border-color:#AA0000;\n}\n\n.mission-description {\n  font-size: 16px; /* Ändern Sie die Schriftgröße nach Bedarf */\n  line-height: 1.2; /* Ändern Sie den Zeilenabstand nach Bedarf */\n  text-align:left;\n  padding:0px;\n}\n\n.mission-title {\n  font-size: 20px; /* Ändern Sie die Schriftgröße nach Bedarf */\n  line-height: 1.2; /* Ändern Sie den Zeilenabstand nach Bedarf */\n  text-align:center;\n  padding:00px;\n}\n\n.fluff {\n  font-size: 16px; /* Ändern Sie die Schriftgröße nach Bedarf */\n  line-height: 1.2; /* Ändern Sie den Zeilenabstand nach Bedarf */\n  text-align:left;\n  padding-top:10px;\n  padding-bottom:10px;\n  font-style: italic;\n}\n\n.teaser-image{\n    margin: auto;\n    width: 90%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
