import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import './Gamelists.css'; // Stil für die Box
import { useLanguage } from '../LanguageContext';
import configModule from '../config';
import { Link } from 'react-router-dom'; // Importiere die Link-Komponente von react-router-dom
import Wait from './Wait'; // Importieren Sie die Box-Komponente
import UnitFlat from './UnitFlat'; // Importieren Sie die Box-Komponente
import Actionbar from './Actionbar'; // Importieren Sie die Box-Komponente
import Filterbar from './Filterbar'; // Importieren Sie die Box-Komponente

function Gamelists ({ characterUUID, browserLanguage, style }) {
  console.log("language: "+browserLanguage);
  const navigate = useNavigate();
  const { translate } = useLanguage();
  const urlByCharacter = configModule.url()+"/GAMELIST/leader/"+characterUUID+""
  const urlAll = configModule.url()+"/GAMELIST"

  const [ filter, setFilter ] = useState(null);
  const [ isLoading, setIsLoading ] = useState(true);
  const [ lists, setLists ] = useState(null);
  const [ displayedLists, setDisplayedLists ] = useState([]);

  const [ url, setUrl ] = useState(characterUUID?urlByCharacter:urlAll);

  useEffect(() => {
    // Hier sollte der REST-Call stattfinden und die Daten in dataList gespeichert werden
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const headers = new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        'Authorization': configModule.getAuthHeader()
        });

        const response = await fetch(url, { headers });
        // console.log(response)
        if (!response.ok) {
          if (response.status===401) {
                configModule.clearUserAuth()
                window.location.href = process.env.PUBLIC_URL+"/overview"
                console.error("Authentication not ok or lost.")
          }
          throw new Error('Fehler beim Laden der Daten: '+response.status);
        }
        const data = await response.json();
        console.log('Fetched gamelist data:', data); // Konsolenausgabe der empfangenen Daten
        setLists(data); // Daten im Zustand speichern
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [url]);

  useEffect(() => {
      if (filter)
      {
            const filtered = lists && lists.filter(listEntry => {
            // Filtere basierend auf dem Alignment-Filter
            const alignmentMatch = filter.alignment === 'good' ? listEntry.leader.modelCommon.alignment >= 0 :
                                   filter.alignment === 'evil' ? listEntry.leader.modelCommon.alignment <= 0 : true;

            return alignmentMatch;
          });

          filtered && filtered.sort((a, b) => {
            const dateA = new Date(a.metadata.changed);
            const dateB = new Date(b.metadata.changed);

            return dateB - dateA;
          });

          setDisplayedLists(filtered);
      }
      else
      {
        setDisplayedLists(lists);
      }
  }, [lists, filter]);

  const handleFilterChange = (filterData) => {
     console.log("got filter change: ",filterData)
     setFilter(filterData)
  };

  const handleAdd = (event) => {
     navigate("/listeditor/leader/"+characterUUID, {
      state: { returnUrl: `/CHARACTER/${characterUUID}` }
     });
  };

  const getName = (list) => {
      // Berechnung von entityName, wenn entity vorhanden ist
      return list.texts.find((text) => text.type === "NAME")?.text || ""    ;
  }

  return (
      <div>
          <div className='fraction-units-title'>{translate('gamelists.title')}</div>
          <div className='bars'>
             <Filterbar handleFilterChange={handleFilterChange} enableAlignment="true"/>
             <Actionbar style="actionbar" handleAdd={handleAdd}/>
          </div>
          { isLoading ? (
            <Wait />
            ) : (
               displayedLists && displayedLists.length>0 && (
                 <>
                  <div className='body'>
                    <table className="table entries">
                       <thead>
                         <tr className='header'>
                           <th className='th name header'>{translate('fu.fraction.units.name')}</th>
                           <th className='header'><img className="attribute-image-th" src={`${process.env.PUBLIC_URL}/icons/attributes/gold_cost.png`} alt="Attribute points" /></th>
                         </tr>
                         <tr><td className='line' colSpan="10"></td></tr>
                       </thead>
                       <tbody>
                           {displayedLists.map((list) => (
                               // Beachte die umschließenden Klammern um das Fragment
                               <React.Fragment key={list.id}> {/* Stelle sicher, dass du ein 'key' Prop hinzufügst */}
                                 <tr><td className='line' colSpan="10"></td></tr>
                                 <tr> <td className='td-header'> <Link to={`/GAMELIST/${list.uuid}`}> {getName(list)} </Link></td>
                                 <td className='td-attributes'>{list.points}</td> </tr>
                               </React.Fragment>
                             ))}
                       </tbody>
                    </table>
                  </div>
                 </>
                 )
              )
          }
      </div>
    );
}

export default Gamelists;
