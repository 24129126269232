import React, { useState, useEffect } from "react";
import './UnitEditor.css'; // Stil für die Box
import { useLanguage } from '../LanguageContext';
import ItemSet from './ItemSet'; // Importieren Sie die Box-Komponente

function EditorWeaponSet({ title, sets, position, langCode, handleChange }) {
  const { translate } = useLanguage();

  const [selectedMeleeWeaponSet, setSelectedMeleeWeaponSet] = useState('');
  const [selectedRangeWeaponSet, setSelectedRangeWeaponSet] = useState('');
  const [selectedWeapon, setSelectedWeapon] = useState();

  useEffect(() => {
    // Wenn der ausgewählte Nahkampf- oder Fernkampfwaffensatz geändert wird,
    // aktualisiere den ausgewählten Waffensatz entsprechend
    const selectedSetId = selectedMeleeWeaponSet || selectedRangeWeaponSet;
    const selectedSet = sets.find(item => item.uuid === selectedSetId);
    setSelectedWeapon(selectedSet);
    // Stelle sicher, dass handleChange aufgerufen wird, nachdem der Zustand aktualisiert wurde
    handleChange(selectedSet, position);

  }, [selectedMeleeWeaponSet, selectedRangeWeaponSet ]);

  const rangeWeapons = sets && sets.filter(item => item.type === "RANGE");
  const closeWeapons = sets && sets.filter(item => item.type === "MELEE");

  const handleMeleeSetSelectionChange = (e) => {
    setSelectedMeleeWeaponSet(e.target.value);
    setSelectedRangeWeaponSet(''); // Setze den Fernkampfwaffensatz zurück, wenn ein Nahkampfwaffensatz ausgewählt wird
  };

  const handleRangeSetSelectionChange = (e) => {
    setSelectedRangeWeaponSet(e.target.value);
    setSelectedMeleeWeaponSet(''); // Setze den Nahkampfwaffensatz zurück, wenn ein Fernkampfwaffensatz ausgewählt wird
  };

  return (
    <div className='editor-weaponset'>
      <div className='editor-weaponset-header'>
        <div>{title}:&nbsp;</div>
        <select
          className="endDropdown"
          value={selectedMeleeWeaponSet}
          onChange={handleMeleeSetSelectionChange}
        >
          <option value="">{translate('edit.weaponset.select.close')}</option>
          {closeWeapons.map((item) => {
            const itemName = item.texts.find(text => text.language === langCode && text.type === 'NAME')?.text || '';
            return <option key={item.uuid} value={item.uuid}>{itemName}</option>;
          })}
        </select>
        <div>&nbsp;{translate('edit.weaponset.select.or')}&nbsp;</div>
        <select
          className="endDropdown"
          value={selectedRangeWeaponSet}
          onChange={handleRangeSetSelectionChange}
        >
          <option value="">{translate('edit.weaponset.select.range')}</option>
          {rangeWeapons.map((item) => {
            const itemName = item.texts.find(text => text.language === langCode && text.type === 'NAME')?.text || '';
            return <option key={item.uuid} value={item.uuid}>{itemName}</option>;
          })}
        </select>
      </div>
      <ItemSet entity={selectedWeapon} browserLanguage={langCode} style="editor-weaponset-item"/>
    </div>
  );
}

export default EditorWeaponSet;
