// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scenario-container {
  display: flex;
  width:100%;
  max-width: 45em;
  align-items: center;
  justify-content: center;
  flex-flow: row wrap;
}

.scenario-value {
  font-size: 18px; /* Ändern Sie die Schriftgröße nach Bedarf */
  line-height: 1.2; /* Ändern Sie den Zeilenabstand nach Bedarf */
  text-align:center;
  padding:20px;
}

.scenario-label {
  font-size: 18px; /* Ändern Sie die Schriftgröße nach Bedarf */
  line-height: 1.2; /* Ändern Sie den Zeilenabstand nach Bedarf */
  text-align:left;
  padding:20px;
  font-style: italic;
}
.setup-image{
    margin: auto;
    width: 90%;
}
.teaser-image{
    margin: auto;
    width: 90%;
}`, "",{"version":3,"sources":["webpack://./src/components/ScenarioDetails.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,UAAU;EACV,eAAe;EACf,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,eAAe,EAAE,4CAA4C;EAC7D,gBAAgB,EAAE,6CAA6C;EAC/D,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,eAAe,EAAE,4CAA4C;EAC7D,gBAAgB,EAAE,6CAA6C;EAC/D,eAAe;EACf,YAAY;EACZ,kBAAkB;AACpB;AACA;IACI,YAAY;IACZ,UAAU;AACd;AACA;IACI,YAAY;IACZ,UAAU;AACd","sourcesContent":[".scenario-container {\n  display: flex;\n  width:100%;\n  max-width: 45em;\n  align-items: center;\n  justify-content: center;\n  flex-flow: row wrap;\n}\n\n.scenario-value {\n  font-size: 18px; /* Ändern Sie die Schriftgröße nach Bedarf */\n  line-height: 1.2; /* Ändern Sie den Zeilenabstand nach Bedarf */\n  text-align:center;\n  padding:20px;\n}\n\n.scenario-label {\n  font-size: 18px; /* Ändern Sie die Schriftgröße nach Bedarf */\n  line-height: 1.2; /* Ändern Sie den Zeilenabstand nach Bedarf */\n  text-align:left;\n  padding:20px;\n  font-style: italic;\n}\n.setup-image{\n    margin: auto;\n    width: 90%;\n}\n.teaser-image{\n    margin: auto;\n    width: 90%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
