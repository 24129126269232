import './App.css';
import React, { useState } from "react";
import { useLanguage } from './LanguageContext';
import BoxChapter from './components/BoxChapter'; // Importieren Sie die Box-Komponente
import Rules from './components/Rules'; // Importieren Sie die Box-Komponente
import { Link } from 'react-router-dom'; // Importiere die Link-Komponente von react-router-dom

// Overview.js
function RulesPage() {
  const { translate } = useLanguage();

  return (
    <div className="Overview-Body">
       <div className="box-container">
           <BoxChapter icon={<img src="phase_1_faded.png" alt="Phase 1 Image" />} title={translate('rules.title.chapter.1')}
           content={<Rules link_pdf={translate('rules.download.pdf.chapter.1')} link_short={translate('rules.download.short.chapter.1')}/>} />
         </div>
    </div>
  );
}

export default RulesPage;