import './App.css';
import React, { useState } from "react";
import { useLanguage } from './LanguageContext';
import BoxList from './components/BoxList'; // Importieren Sie die Box-Komponente
import { useNavigate, Link } from 'react-router-dom'; // Importiere die Link-Komponente von react-router-dom

// Overview.js
function Story() {
  const { translate } = useLanguage();
  const navigate = useNavigate();

  const handleAddCharacter = (event) => {
     navigate("/editor/add/CHARACTER/base/FRACTION", {
        state: { returnUrl: "/story" }
      });
  };

  const handleAddGamelist = (event) => {
       navigate("/listeditor", {
         state: { returnUrl: "/story" }
       });
  };

  return (
    <div className="Overview-Body">
       <div className="box-container">
           <BoxList
               icon={<img src={`${process.env.PUBLIC_URL}/icons/action_icons/character.png`} alt="Character Image" />}
               title={translate('characters.title') }
               typeToLoad="CHARACTER"
               imageType="CHARACTER"
               handleAdd={handleAddCharacter}
                />
            <BoxList
               icon={<img src={`${process.env.PUBLIC_URL}/icons/action_icons/scenario.png`} alt="Game lists Image" />}
               title={translate('gamelist.title') }
               typeToLoad="GAMELIST"
               imageType="GAMELIST"
               handleAdd={handleAddGamelist}
               />
         </div>
    </div>
  );
}

export default Story;