import React, { useState } from "react";
import './Attributes.css'; // Stil für die Box
import { useLanguage } from '../LanguageContext';

function Attributes({ attributes, browserLanguage, styleToSet }) {
  const { translate } = useLanguage();

  return (
      <div className={styleToSet}>
        {attributes && (
          <table className='attribute-table'>
            <tbody>
                <tr>
                    {attributes.movement>0 && (
                        <td>
                            <img className="attribute-image" src={`${process.env.PUBLIC_URL}/icons/attributes/movement.png`} alt="Attribute movement" />
                            <div className="attribute-value-embedded attribute-color-movement">{attributes.movement}</div>
                         </td>
                    )}
                    {attributes.armor>0 && (
                        <td>
                            <img className="attribute-image" src={`${process.env.PUBLIC_URL}/icons/attributes/armor.png`} alt="Attribute armor" />
                            <div className="attribute-value-embedded attribute-color-armor">{attributes.armor}</div>
                         </td>
                    )}
                    {attributes.fighting>0 && (
                        <td>
                            <img className="attribute-image" src={`${process.env.PUBLIC_URL}/icons/attributes/fighting.png`} alt="Attribute fighting" />
                            <div className="attribute-value-embedded attribute-color-fighting">{attributes.fighting}</div>
                         </td>
                    )}
                    {attributes.shooting>0 && (
                        <td>
                            <img className="attribute-image" src={`${process.env.PUBLIC_URL}/icons/attributes/shooting.png`} alt="Attribute shooting" />
                            <div className="attribute-value-embedded attribute-color-shooting">{attributes.shooting}</div>
                         </td>
                    )}
                    {attributes.magic>0 && (
                        <td>
                            <img className="attribute-image" src={`${process.env.PUBLIC_URL}/icons/attributes/magic.png`} alt="Attribute magic" />
                            <div className="attribute-value-embedded attribute-color-magic">{attributes.magic}</div>
                         </td>
                    )}
                    {attributes.moral>0 && (
                        <td>
                            <img className="attribute-image" src={`${process.env.PUBLIC_URL}/icons/attributes/magic.png`} alt="Attribute moral" />
                            <div className="attribute-value-embedded attribute-color-moral">{attributes.moral}</div>
                         </td>
                    )}
                     {attributes.hitpoints>0 && (
                        <td>
                            <img className="attribute-image" src={`${process.env.PUBLIC_URL}/icons/attributes/HP.png`} alt="Attribute hitpoints" />
                            <div className="attribute-value-embedded attribute-color-hp">{attributes.hitpoints}</div>
                         </td>
                    )}
                </tr>
            </tbody>
          </table>
        )}
        {!attributes && (
            <div> ??? </div>
        )}
      </div>
    );
}

export default Attributes;
