// ErrorBoundary.js
import React, { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorMessage: '',
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, errorMessage: error.message };
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <h1>Error: {this.state.errorMessage}</h1>
          <p>Bitte überprüfe deine Internetverbindung oder versuche es später erneut.</p>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
