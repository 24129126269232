import './App.css';
import React, { useState } from "react";
import { useLanguage } from './LanguageContext';
import BoxList from './components/BoxList'; // Importieren Sie die Box-Komponente
import { Link } from 'react-router-dom'; // Importiere die Link-Komponente von react-router-dom

// Overview.js
function CSM() {
  const { translate } = useLanguage();

  return (
    <div className="Overview-Body">
       <div className="box-container">
           <BoxList
               icon={<img src={`${process.env.PUBLIC_URL}/icons/generic_icons/campaign.png`} alt="Campaign Image" />}
               title={translate('csm.campaign.title') }
               typeToLoad="CAMPAIGN"
               linkType="CAMPAIGN"
               imageType="CAMPAIGN"
               />
           <BoxList
                icon={<img src={`${process.env.PUBLIC_URL}/icons/generic_icons/scenario.png`} alt="Scenario Image" />}
                title={translate('csm.scenario.title') }
                typeToLoad="SCENARIO"
                linkType="SCENARIO"
                imageType="SCENARIO"
               />
           <BoxList
               icon={<img src={`${process.env.PUBLIC_URL}/icons/generic_icons/missions.png`} alt="Missions Image" />}
               title={translate('csm.mission.title') }
               typeToLoad="MISSION"
               linkType="MISSION"
               imageType="MISSION"
               />
         </div>
    </div>
  );
}

export default CSM;