// WelcomePage.js
import './App.css';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Importiere die Link-Komponente von react-router-dom

function Header() {
  const navigate = useNavigate();

  const handleLogoClick = () => {
    console.log("navigate called");
    navigate('//');
  };

  return (
    <div className="header-content" onClick={handleLogoClick}>
      <img
        src={`${process.env.PUBLIC_URL}/end_logo_trans_medium.png`}
        className="App-logo"
        alt="logo"
      />
    </div>
  );
}

export default Header;