import React, { useState, useEffect, useRef }  from 'react';
import './AdminLoginModal.css';
import configModule from '../config';
import { useLanguage } from '../LanguageContext';

function AdminLoginModal({ isVisible, onClose }) {
      const tokenRef = useRef(null);  // useRef für das Token-Eingabefeld

      const urlIntrospect = configModule.baseUrl()+"/v1/admin/introspect"
      const { translate } = useLanguage();
      const [code, setCode] = useState('');
      const handleInputChange = (event) => { setCode(event.target.value); };

      const introspect = async () => {
          try {
            const headers = new Headers({
                  'Accept': 'application/json',
                  'Authorization': configModule.getAuthHeader()
            });

            const response = await fetch(urlIntrospect, {
                  method: 'GET',
                  headers: headers
                });

            // console.log(response)
            if (!response.ok) {
              // Hier wird ein Fehler für 400er Statuscodes abgefangen
              configModule.clearUserAuth()
              throw new Error('Fehler beim Laden der Daten: '+response.status);
            }
            const data = await response.json();
            console.log('Fetched user data:', data); // Konsolenausgabe der empfangenen Daten
            // speichere in config
            configModule.config.userIntrospect = data
            configModule.config.loggedIn = true
            configModule.storeSettings()
            window.location.href = process.env.PUBLIC_URL+"/overview"
          } catch (error) {
            console.error('Error fetching data:', error);
            configModule.clearUserAuth()
          }
        };

      const doLogin = async () => {
          const token = tokenRef.current.value;  // Zugriff auf den Token-Wert

          // speichere in config
          configModule.config.userAuth = `admin ${token}`;

          // rufe introspect auf
          introspect();
    };


    if (!isVisible) return null;

    return (
        <div className="modal">
            <div className="modal-content">
                <h2>Admin Login</h2>
                <form id="adminLoginForm" onSubmit={(e) => { e.preventDefault(); doLogin(); }}>
                    <label htmlFor="token">Token:</label>
                    <input type="password" id="token" name="token" ref={tokenRef} required />

                    <button type="submit">Login</button>
                </form>
                <button className="close-button" onClick={onClose}>Schließen</button>
            </div>
        </div>
    );
}

export default AdminLoginModal;