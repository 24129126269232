import React from "react";
import './GameSetup.css'; // Stil für die Box
import { useLanguage } from '../LanguageContext';

function SetupTableRow({ marker, browserLanguage }) {
  const { translate } = useLanguage();

  const findText = (type) => marker.texts.find(
    (text) => text.type === type && text.language === browserLanguage
  )?.text || "";

  return (
    <tr key={marker.uuid}>
      <td className='setup-title'>
        <div>{findText("NAME")}</div>
        {marker.marker && <div className='setup-marker'>({translate('setup.marker')} {marker.marker})</div>}
      </td>
      <td className='setup-description'>
        <div>{findText("DESCRIPTION")}</div>
        <p>{findText("RULES")}</p>
      </td>
    </tr>
  );
}

export default SetupTableRow;