import React from "react";
import './GameSetup.css'; // Stil für die Box
import SetupTableRow from './SetupTableRow'; // Stil für die Box
import { useLanguage } from '../LanguageContext';

function GameSetup({ id, setupMarker, browserLanguage }) {
  const { translate } = useLanguage();

  // Filtere SetupMarker mit setupZone=true
  const setupZoneMarkers = setupMarker.filter(marker => marker.setupZone === true);

  // Filtere SetupMarker mit exitZone=true
  const exitZoneMarkers = setupMarker.filter(marker => marker.exitZone === true);

  // Filtere SetupMarker mit exitZone=true
  const startConditionDefinition = setupMarker.filter(marker => marker.startCondition === true);

  // Filtere SetupMarker mit exitZone=true
  const endConditionDefinition = setupMarker.filter(marker => marker.endCondition === true);

  // Filtere SetupMarker, die weder setupZone noch exitZone haben
  const otherMarkers = setupMarker.filter(marker => !marker.setupZone && !marker.exitZone && !marker.startCondition && !marker.endCondition);

 function sortSetupMarkers(markers) {
   return [...markers].sort((a, b) => {
     // Angenommen, setupMarker ist ein numerischer Wert.
     // Wenn es sich um einen String handelt, musst du die Logik entsprechend anpassen.
     if (a.order == null) return 1;
     if (b.order == null) return -1;
     return a.order - b.order;
   });
 }

  const renderTable = (markers, titleKey) => (
     <>
       <h5>{translate(titleKey)}</h5>
       <table>
         <tbody>
           {markers && sortSetupMarkers(markers).map((m) => (
             <SetupTableRow marker={m} browserLanguage={browserLanguage} />
           ))}
         </tbody>
       </table>
     </>
   );

   return (
     <div className='setup'>
       <h4>{translate('setup.title')}</h4>
       {renderTable(otherMarkers, 'setup.otherTitle')}
       {renderTable(setupZoneMarkers, 'setup.setupZoneTitle')}
       {renderTable(exitZoneMarkers, 'setup.exitZoneTitle')}
       {renderTable(startConditionDefinition, 'setup.startTitle')}
       {renderTable(endConditionDefinition, 'setup.endTitle')}
     </div>
   );
}

export default GameSetup;