import React, { useState } from "react";
import { useParams, useNavigate,Link } from 'react-router-dom';
import './Unit.css'; // Stil für die Box
import { useLanguage } from '../LanguageContext';
import Attributes from './Attributes'; // Importieren Sie die Box-Komponente
import AttributesV from './AttributesV'; // Importieren Sie die Box-Komponente
import Properties from './Properties'; // Importieren Sie die Box-Komponente
import Corps from './Corps'; // Importieren Sie die Box-Komponente
import CorpsV from './CorpsV'; // Importieren Sie die Box-Komponente
import EntityCommonV from './EntityCommonV'; // Importieren Sie die Box-Komponente
import EntityCommon from './EntityCommon'; // Importieren Sie die Box-Komponente
import ItemSet from './ItemSet'; // Importieren Sie die Box-Komponente
import Image from './Image';
import configModule from '../config';
import Actionbar from './Actionbar'; // Importieren Sie die Box-Komponente

function mapType( type, translate ) {
  console.log("type: "+type);
  let translatedType = type; // Standard: Der Typ wird nicht übersetzt
  //INFANTRY,COMMANDER,SHOOTER,FIGHTER,CAVALRY,ARTILLERY,SHOOTING_CAVALRY
  if (type === "INFANTRY") {
    translatedType = translate('unit.type.infantry');
  } else if (type === "COMMANDER") {
    translatedType = translate('unit.type.commander');
  } else if (type === "SHOOTER") {
    translatedType = translate('unit.type.shooter');
  } else if (type === "FIGHTER") {
     translatedType = translate('unit.type.fighter');
  } else if (type === "HERO") {
     translatedType = translate('unit.type.hero');
  }
  else translatedType = ""
  // Fügen Sie hier weitere Übersetzungen für verschiedene Typen hinzu

  return translatedType;
}

function UnitRO({ entity, browserLanguage, style }) {
  console.log("language: "+browserLanguage);
  const navigate = useNavigate();
  const { translate } = useLanguage();

  // Deklaration der Variablen außerhalb der JSX-Klammern
  let fractionName = "";
  let entityGenericName = "???";
  let entityName = "";
  let entityType = "";
  let styleToSet = "";

  console.log(entity)

  // infos für web calls
  const headers = new Headers({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'lang': browserLanguage,
      'Authorization': configModule.getAuthHeader(),
  });

  if (entity) {
    // Berechnung von entityName, wenn entity vorhanden ist
    entityGenericName = entity.texts.find((text) => text.type === "NAME" && text.language === browserLanguage)?.text || entityGenericName;
    fractionName = entity.fraction.texts.find((text) => text.type === "NAME" && text.language === browserLanguage)?.text || fractionName;
    entityName = entity.name ? entity.name : entityGenericName;
    entityType = mapType(entity.type, translate);

    /*if (entity.name!=null && entityGenericName!=null)
    {
        entityType = entityType + " ("+entityGenericName+")"
    }*/

    // Weitere Stilanpassungen können hier vorgenommen werden
    styleToSet = style ? style : "entity-style"; // Beispiel für einen Stil
  }

  const handleDelete = () => {
         let url = `${configModule.url()}/${entity.entityCommon.type}/${entity.uuid}`;

         const exists = async () => {
               const response = await fetch(url,
                  {
                    method: 'HEAD', // Verwende POST oder PUT, je nach API-Anforderungen
                    headers: headers
                  }
               );
              if (!response.ok) {
               if (response.status === 404) {
                  return true
               }
               else if (response.status === 401) {
                       configModule.clearUserAuth();
                       window.location.href = process.env.PUBLIC_URL + "/overview";
                       console.error("Authentication not ok or lost.");
               }
               throw new Error('Fehler beim Laden der Daten: ' + response.status);
              }
              else{
                  return true;
              }
         };

         Promise.all([exists()]).then(([exists]) => {
              if (exists)
              {
                  console.log("entity existing, deleting: ",entity.uuid);

                  const deleteEntity = async () => {
                       const response = await fetch(url,
                           {
                             method: 'DELETE', // Verwende POST oder PUT, je nach API-Anforderungen
                             headers: headers
                           }
                        );
                       if (!response.ok) {
                        if (response.status === 404) {
                           return true
                        }
                        else if (response.status === 401) {
                                configModule.clearUserAuth();
                                window.location.href = process.env.PUBLIC_URL + "/overview";
                                console.error("Authentication not ok or lost.");
                        }
                        throw new Error('Fehler beim Laden der Daten: ' + response.status);
                       }
                       else return true
                  };

                  Promise.all([deleteEntity()]).then(([deleteEntity]) => {
                     console.log("entity deleted: ",entity.uuid);
                         // load fraction view
                     let url;
                     if (entity.entityCommon.type==="CHARACTER") url = `/story`;
                     else if (entity.entityCommon.type==="UNIT") url = `/FRACTION/${entity.fraction.uuid}`;
                     else url = "/overview";
                     navigate(url);
                })
                .catch(error => {
                     console.error('Fehler beim Löschen:', error);
                });

              }
         })
         .catch(error => {
              console.error('Fehler beim Löschen:', error);
         });
  }

  const handleEdit = () => navigate(`/editor/edit/${entity.entityCommon.type}/${entity.uuid}`);

  return (
      <div>
        {entity && (
          <div className={style}>
              <div className='unit-body'>
                <div className='unitshort-subtitle'>{entityType}</div>
                <div className='unitshort-title'>{entityName}</div>
                <div className='unitshort-subtitle'><Link to={`/FRACTION/${entity.fraction.uuid}`}>{fractionName}</Link></div>
              </div>
              <div className='fluff'>
                <p>{entity.texts.find((text) => text.type === "FLUFF" && text.language === browserLanguage)?.text}</p>
              </div>
              <div className='unit-body'>
                <AttributesV attributes={entity.attributes} browserLanguage={browserLanguage} styleToSet=""/>
                 <div className='unit-body-sub'>
                    <Image
                            classNameIn={"miniature-image"}
                            type={entity.entityCommon.type}
                            id={entity.uuid}
                            size={400}
                            title={""}/>
                </div>
                 <EntityCommonV
                    modelCommon={entity.modelCommon}
                    browserLanguage={browserLanguage}
                    points={entity.points}
                    hp={entity.attributes.hitpoints}styleToSet=""/>
              </div>
              <div className='unit-body'>
                {entity.armor && (
                    <ItemSet entity={entity.armor} browserLanguage={browserLanguage} styleToSet=""/>
                )}
                {entity.sets && entity.sets.length>0 && entity.sets.map( set => (
                    <ItemSet entity={set} browserLanguage={browserLanguage} styleToSet=""/>
                 ))}
                <Properties properties={entity.properties} browserLanguage={browserLanguage} styleToSet=""/>
              </div>

              <Actionbar style="savebar" handleDelete={handleDelete} handleEdit={handleEdit} visibility={entity.metadata.visibility} owner={entity.metadata.owner}/>
          </div>
        )
        }
      </div>
    );
}

export default UnitRO;
