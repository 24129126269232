// WelcomePage.js

import './App.css';
import React, { useState, useEffect  } from "react";
import { useLanguage } from './LanguageContext';
import config from './config'; // Passe den Pfad zur Konfigurationsdatei an
import { Link } from 'react-router-dom'; // Importiere die Link-Komponente von react-router-dom


function Footer() {
  const { translate } = useLanguage();
  const appVersion = require('../package.json').version;

  const replacePlaceholders = (text, replacements) => {
    return text.replace(/\{\{(\w+)\}\}/g, (_, key) => replacements[key] || '');
  };

  const currentYear = new Date().getFullYear();
  const [version, setVersion] = useState(null); // State für die Versionsnummer
  const [error, setError] = useState(null);

    useEffect(() => {
      // Führe die Versionsabfrage beim Laden der Komponente aus
      fetch(`${config.bff}/base/info`) // Passe den Endpunkt an, um die Version vom BFF abzurufen
        .then((response) => {
            if (!response.ok) {
              throw new Error( translate('error.noconnection') );
            }
            return response.json();
          })
        .then((data) => setVersion(data)
        )
        .catch((error) => {
            setError( translate('error.noconnection') )
            return
        }

        ); // Fehler im Zustand speichern
    }, []);

  return (
      <footer className="footer no-print">
        <div className="footer-content">
            <Link to="/credit-imprint-privacy">{translate('footer.creditimprintprivacy')}</Link>
            <span className="separator">•</span>
            <span><Link to={`/help`}>{translate('help.title')}</Link></span>
            <span className="separator">•</span>
            <span><Link to={`https:\/\/www.patreon.com\/user\/membership?u=73419598`}>{translate('footer.patreon')}</Link></span>
            <span className="separator">•</span>
            <span><Link to={"https://discord.gg/rXHt6cSp6z"}>{translate('discord.link')}</Link></span>
          </div>
          <div className="footer-content">
              <span>
                 <span>{replacePlaceholders(translate('footer.copyright'), { year: currentYear })}</span>&nbsp;•&nbsp;
                 <span>{replacePlaceholders(translate('footer.version'), { version: appVersion })}</span>
               </span>
            </div>
      </footer>
  );
}

export default Footer;