import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import './GameList.css'; // Stil für die Box
import { useLanguage } from '../LanguageContext';
import configModule from '../config';
import Image from './Image'; // Importieren Sie die Box-Komponente
import Attributes from './Attributes'; // Importieren Sie die Box-Komponente
import AttributesV from './AttributesV'; // Importieren Sie die Box-Komponente
import Properties from './Properties'; // Importieren Sie die Box-Komponente
import Corps from './Corps'; // Importieren Sie die Box-Komponente
import CorpsV from './CorpsV'; // Importieren Sie die Box-Komponente
import EntityCommonV from './EntityCommonV'; // Importieren Sie die Box-Komponente
import EntityCommon from './EntityCommon'; // Importieren Sie die Box-Komponente
import UnitFlat from './UnitFlat'; // Importieren Sie die Box-Komponente
import CharacterFlat from './CharacterFlat'; // Importieren Sie die Box-Komponente
import { Link } from 'react-router-dom'; // Importiere die Link-Komponente von react-router-dom
import UnitHeader from './UnitHeader'; // Importieren Sie die Box-Komponente
import Actionbar from './Actionbar'; // Importieren Sie die Box-Komponente


function GameList({ entityIn, browserLanguage, style, edit, handleUnitDeletion, showTitle, handleTitleChange }) {
  const { translate } = useLanguage();
  const navigate = useNavigate();
  const [entity, setEntity] = useState(entityIn);
  const [isLoading, setIsLoading] = useState(true);
  const [ units, setUnits ] = useState(null);
  const [name, setName] = useState('');

  // infos für web calls
  const headers = new Headers({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'lang': browserLanguage,
      'Authorization': configModule.getAuthHeader(),
  });

  // Deklaration der Variablen außerhalb der JSX-Klammern
  let entityName = "";
  let styleToSet = "";

  useEffect(() => {
      //console.log('Neue Liste erhalten in GameList:', entityIn);
      setEntity(entityIn);
  }, [entityIn]);


  if (entity && entity.texts) {
    // Berechnung von entityName, wenn entity vorhanden ist
    entityName = entity.texts.find((text) => text.type === "NAME")?.text || entityName;
    // Weitere Stilanpassungen können hier vorgenommen werden
    styleToSet = style ? style : "entity-style"; // Beispiel für einen Stil
  }

    const updateEntityName = (newName, language) => {
      let nameFound = false;
      const updatedTexts = entity.texts.map(text => {
        if (text.type === "NAME" && text.language === language) {
          nameFound = true;
          return { ...text, text: newName };
        }
        return text;
      });

      // Wenn kein Eintrag mit Typ "NAME" und der Sprache gefunden wurde, füge einen neuen Eintrag hinzu
      if (!nameFound) {
        updatedTexts.push({ type: "NAME", language: language, text: newName });
      }

      console.log("updating entity name with: ", updatedTexts);
      if (handleTitleChange) handleTitleChange(updatedTexts);

      return { ...entity, texts: updatedTexts };
    };



  function extractUniqueProperties(entity) {
      const allProperties = new Set();

      if (entity && entity.properties) {
        entity.properties.forEach((property) => {
          allProperties.add(property); // Annahme: "name" ist der Name der Property
        });
      }

      if (entity && entity.sets) {
        entity.sets.forEach((set) => {
          if (set.properties) {
            set.properties.forEach((property) => {
              allProperties.add(property);
            });
          }
          if (set.items) {
            set.items.forEach((item) => {
              if (item.properties) {
                item.properties.forEach((property) => {
                  allProperties.add(property);
                });
              }
            });
          }

        });
      }

      if (entity && entity.items) {
        entity.items.forEach((item) => {
          if (item.properties) {
            item.properties.forEach((property) => {
              allProperties.add(property);
            });
          }
        });
      }

      if (entity && entity.armor && entity.armor.properties) {
        entity.armor.properties.forEach((property) => {
          allProperties.add(property);
        });
      }

      return Array.from(allProperties); // Konvertiere Set in ein Array
  }


function extractAllProperties(entity) {
    const allProperties = new Set();
    const uniqueUuids = new Set(); // Ein Set, um eindeutige UUIDs zu speichern

    // Funktion zur Extraktion und Hinzufügung einzigartiger Eigenschaften und UUID-Prüfung
    const addPropertiesAndCheckUuid = (item) => {
        const properties = extractUniqueProperties(item);
        properties.forEach(prop => {
            // Annahme: prop ist ein Objekt { uuid: '...', ... }
            if (!uniqueUuids.has(prop.uuid)) {
                uniqueUuids.add(prop.uuid); // Füge die UUID zum Set der eindeutigen UUIDs hinzu
                allProperties.add(prop); // Füge die Eigenschaft nur hinzu, wenn die UUID einzigartig ist
            }
        });
    };

    // Prüfen und Extrahieren von Eigenschaften des Anführers, falls vorhanden
    if (entity.leader) {
        addPropertiesAndCheckUuid(entity.leader);
    }

    // Prüfen und Extrahieren von Eigenschaften der Einheiten, falls vorhanden
    if (entity.units) {
        entity.units.forEach(unit => {
            addPropertiesAndCheckUuid(unit.unit);
        });
    }

    return Array.from(allProperties); // Konvertiere das Set zurück in ein Array
}

const handleDelete = () => {
       setIsLoading(true);
       let url = `${configModule.url()}/GAMELIST/${entity.uuid}`;
       const exists = async () => {
             const response = await fetch(url,
                {
                  method: 'HEAD', // Verwende POST oder PUT, je nach API-Anforderungen
                  headers: headers
                }
             );
            if (!response.ok) {
             if (response.status === 404) {
                setIsLoading(false);
                return true
             }
             else if (response.status === 401) {
                     configModule.clearUserAuth();
                     window.location.href = process.env.PUBLIC_URL + "/overview";
                     console.error("Authentication not ok or lost.");
             }
             setIsLoading(false);
             throw new Error('Fehler beim Laden der Daten: ' + response.status);
            }
            else{
                setIsLoading(false);
                return true;
            }
       };

       Promise.all([exists()]).then(([exists]) => {
            if (exists)
            {
                console.log("entity existing, deleting: ",entity.uuid);

                const deleteEntity = async () => {
                     const response = await fetch(url,
                         {
                           method: 'DELETE', // Verwende POST oder PUT, je nach API-Anforderungen
                           headers: headers
                         }
                      );
                     if (!response.ok) {
                      if (response.status === 404) {
                         setIsLoading(false);
                         return true
                      }
                      else if (response.status === 401) {
                              configModule.clearUserAuth();
                              window.location.href = process.env.PUBLIC_URL + "/overview";
                              console.error("Authentication not ok or lost.");
                      }
                      setIsLoading(false);
                      throw new Error('Fehler beim Laden der Daten: ' + response.status);
                     }
                     setIsLoading(false);
                     navigate(`/story`);
               };
               deleteEntity();
            }
       })
       .catch(error => {
            setIsLoading(false);
            console.error('Fehler beim Löschen:', error);
       });
  }

  const handleEdit = () => {
      navigate(`/listeditor/${entity.uuid}`, {
        state: { returnUrl: `/GAMELIST/${entity.uuid}` }
      });
  }

  const handleAbort = () => {
     console.log("going back to: /story");
     navigate(`/story`);
  }


  return (
      <div>
        {entity && (
          <div className={style}>
             <div>
                { entity.uuid && (
                   <Image
                       classNameIn={"title-image"}
                       initialUrl={`${process.env.PUBLIC_URL}/images/GAMELIST/${entity.uuid}.png`}
                       title={""}/>
                )}
             </div>
             <div className='entity-body'>
                  <div className='entity-title'>
                  { edit ? (
                         <>
                             <input
                               type="text"
                               className='endText'
                               value={entityName}
                               onChange={(e) => {
                                 setName(e.target.value);
                                 updateEntityName(e.target.value, browserLanguage);
                               }}
                               placeholder={translate('gamelist.noname')}
                             />
                         </>
                      ) : (
                         <>
                            {entityName}
                         </>
                      )
                  }
                  </div>
                  <div className='points'>
                    <p>{translate('gamelist.points')} {entity.points}</p>
                  </div>
              </div>
              {entity.leader && (
                  <>
                      <div className='entity-subtitle'>{translate('gamelist.leader')}</div>
                      <div className='entity-body'>
                          <div>
                             <Image
                                classNameIn={"leader-image"}
                                initialUrl={`${process.env.PUBLIC_URL}/images/CHARACTER/${entity.leader.uuid}.png`}
                                title={""}/>
                          </div>
                          <div>
                              <table className="character-table">
                                <UnitHeader browserLanguage={browserLanguage} />
                                <CharacterFlat unit={entity.leader} browserLanguage={browserLanguage} />
                              </table>
                          </div>
                      </div>
                  </>
              )}

              <div className='entity-subtitle'>{translate('gamelist.units')}</div>
              { entity.units && entity.units.length>0 && (
                <div className='entity-body'>
                     <table className="units-table">
                        <UnitHeader withCnt={true} browserLanguage={browserLanguage} />
                        <tbody>
                            { entity.units.map((unitEntry) => (
                                <React.Fragment key={unitEntry.unit.uuid}>
                                  <tr><td className='line' colSpan="10"></td></tr>
                                  <UnitFlat count={unitEntry.count} unit={unitEntry.unit} browserLanguage={browserLanguage} edit={edit} handleUnitDeletion={handleUnitDeletion}/>
                                </React.Fragment>
                            ))}
                        </tbody>
                     </table>
                </div>
              )}

              <Properties properties={ extractAllProperties(entity) } browserLanguage={browserLanguage}/>

             { !edit && (
                    <Actionbar style="savebar" handleBack={handleAbort} handleEdit={handleEdit} handleDelete={handleDelete}
               visibility={entity.metadata.visibility} owner={entity.metadata.owner}/> )}
            </div>
        )}
      </div>
    );
}

export default GameList;
