import './App.css';
import { useParams } from 'react-router-dom';
import React, { useState,useEffect } from "react";
import { useLanguage } from './LanguageContext';
import { Link } from 'react-router-dom'; // Importiere die Link-Komponente von react-router-dom
import configModule from './config';
import Unit from './components/Unit'; // Importieren Sie die Box-Komponente
import UnitShort from './components/UnitShort'; // Importieren Sie die Box-Komponente
import Wait from './components/Wait'; // Importieren Sie die Box-Komponente


function UnitDetail() {
  const { itemId } = useParams();
  const langCode = navigator.language.split('-')[0].toUpperCase();

  return (
    <div className="Overview-Body">
     <Unit entityUUID={itemId} browserLanguage={langCode} />
    </div>
  );
}

export default UnitDetail;