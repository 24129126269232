import React from 'react';
import './Box.css'; // Stil für die Box
import { Link } from 'react-router-dom'; // Importiere die Link-Komponente von react-router-dom

function Box({ icon, title, content, linkto }) {
  return (
    <div className="box">
      <div className="box-header">
           {icon && linkto && <div><Link to={linkto}>{icon}</Link></div>}
           {icon && !linkto && <div>{icon}</div>}
           <div className="box-title">{title}</div>
      </div>
      <div className="box-content">
        {linkto && <div><Link to={linkto}>{content}</Link></div>}
        {!linkto && <div>{content}</div>}
      </div>
    </div>
  );
}

export default Box;