// WelcomePage.js

import '../App.css';
import React, { useState } from "react";
import { useLanguage } from '../LanguageContext';

function Rules({ link_pdf, link_short }) {
  const { translate } = useLanguage();

  return (
    <div>
        <div>
          <p>{translate('rules.download.description')}</p>
          <p><a href={link_short} target="_blank">{translate('rules.download.short')}</a></p>
        </div>

   </div>
  );
}

export default Rules;