import React, { useState } from "react";
import '../App.css'; // Stil für die Box
import './Credit.css'; // Stil für die Box
import { useLanguage } from '../LanguageContext';
import Textfield from './Textfield'; // Importieren Sie die Box-Komponente
import Image from './Image';

function Credit({ credit, browserLanguage }) {
  const { translate } = useLanguage();

  // Deklaration der Variablen außerhalb der JSX-Klammern
  let title = "";
  let description = "";
  let link = "";

  if (credit) {
    // Berechnung von missionName, wenn mission vorhanden ist
    title = credit.texts.find((text) => text.type === "NAME" && text.language === browserLanguage)?.text  || "";
    description = credit.texts.find((text) => text.type === "DESCRIPTION" && text.language === browserLanguage)?.text  || "";
    link = credit.texts.find((text) => text.type === "LINK" && text.language === browserLanguage)?.text  || "";
  }

  return (
      <>
        {credit && (
            <div className='credit-item'>
              <div className='credit-title'><a href={credit.link} target='_blank' rel="noopener noreferrer">{title}</a></div>
              <div className='credit-text'>{description}</div>
              {
                link && credit.link && (
                    <a href={credit.link} target='_blank' rel="noopener noreferrer"><p>{link}</p></a>
                )
              }
            </div>
          )}

      </>
    );
}

export default Credit;
