import React, { useState, useEffect } from "react";
import './EntityList.css'; // Stil für die Box
import { useLanguage } from '../LanguageContext';
import configModule from '../config';
import Property from './Property'; // Importieren Sie die Box-Komponente
import { Link } from 'react-router-dom'; // Importiere die Link-Komponente von react-router-dom

function PropertyList({ entityIn, browserLanguage, style, edit, handlePropertyDeletion }) {
  //console.log('Neue Liste erhalten in PropertyList:', entityIn);
  const { translate } = useLanguage();
  const [entity, setEntity] = useState(entityIn);
  const [isLoading, setIsLoading] = useState(true);

  // Deklaration der Variablen außerhalb der JSX-Klammern
  let styleToSet = "";

  useEffect(() => {
      setEntity(entityIn);
  }, [entityIn]);

  return (
      <div>
        {entity ? (
          <div>
             <h4>{translate(`propertylist.title`)}</h4>
             <div className='entity-body'>
              {
                entity && entity.length > 0 ? (
                  <div>
                    <table className="entity-table">
                      <tbody>
                        <tr><td className='line' colSpan="10"></td></tr>
                        {entity.map((itemEntry) => (
                          <React.Fragment key={itemEntry.uuid}>
                            <tr><td className='line' colSpan="10"></td></tr>
                            <tr><td>
                              <div>
                                <img
                                  className="button-add"
                                  src={`${process.env.PUBLIC_URL}/icons/generic_icons/delete.png`}
                                  alt="delete"
                                  onClick={() => handlePropertyDeletion(itemEntry)}
                                />
                              </div>
                              <Property property={itemEntry} browserLanguage={browserLanguage}/>
                            </td></tr>
                          </React.Fragment>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="entity-noitem-text">{translate(`propertylist.noitem`)}</div>
                )
              }
            </div>
          </div>
        ) : (
          <div className="entity-noitem-text">{translate(`propertylist.noitem`)}</div>
        )
        }
      </div>
    );
}

export default PropertyList;
