import './App.css';
import React, { useState, useEffect } from "react";
import { useLanguage } from './LanguageContext';
import configModule from './config';
import { useNavigate, Link } from 'react-router-dom';
import Wait from './components/Wait'; // Importieren Sie die Box-Komponente

function NewsPage() {
  const navigate = useNavigate(); // Hier verwenden wir useNavigate
  const { translate } = useLanguage();

  const [isLoading, setIsLoading] = useState(true);
  const [entity, setEntity] = useState(null);
  const url = configModule.url()+"/NEWS/latest";
  const langCode = navigator.language.split('-')[0].toUpperCase();

  useEffect(() => {
      if (!isLoading && entity === null) {
        navigate('/overview');
      }
    }, [isLoading, entity, navigate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const headers = new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'lang': langCode,
          'Authorization': configModule.getAuthHeader()
        });

        const response = await fetch(url, { headers });
        if (!response.ok) {
          if (response.status === 401) {
            configModule.clearUserAuth();
            window.location.href = process.env.PUBLIC_URL+"/overview";
            console.error("Authentication not ok or lost.");
          }
          throw new Error('Fehler beim Laden der Daten: ' + response.status);
        }
        const data = await response.json();
        console.log('Fetched data:', data);
        setEntity(data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [url]);

  // Definiere Titel, Beschreibung und LinkText außerhalb der JSX-Rückgabe
  let title = entity?.texts.find(text => text.type === "NAME" && text.language === langCode)?.text || "";
  let description = entity?.texts.find(text => text.type === "DESCRIPTION" && text.language === langCode)?.text || "";
  let linkText = entity?.texts.find(text => text.type === "LINK" && text.language === langCode)?.text || "";

  return (
    <div className="Welcome-Body">
      <Link to="/overview">
        <div className="header-content">
          <img src={`${process.env.PUBLIC_URL}/phase_1_faded.png`} className="App-logo-small" alt="logo" />
        </div>
      </Link>

      {isLoading ? (
        <Wait />
      ) : (
        <>
          {entity && (
            <>
              <h2>{title}</h2>
              <p>{description}</p>
            </>
          )}
          {entity && entity.link && (
            <a href={entity.link} target='_blank' rel="noopener noreferrer"><p>{linkText}</p></a>
          )}
        </>
      )}
      <Link to="/overview"> <button className="endButton">{translate('news.enter')}</button></Link>
    </div>
  );
}

export default NewsPage;
